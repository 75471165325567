import React, { useState } from 'react';

import './styles.less';

import { useDispatch, useSelector } from 'react-redux';
import { push } from 'connected-react-router';

import { Space, Typography, Skeleton } from 'antd';

import { LeftOutlined } from '@ant-design/icons';

import Page from '@webapp/components/layout/page';
import BarGraph from '@webapp/components/bar-graph';
import Statistics from '@webapp/components/statistics';
import { useParams } from 'react-router';
import ConversionRate from '@webapp/components/conversion-rate';
import ForecastEditorModal from '@webapp/components/modal/forecast-editor';

import AppState from '../../../store/state';
import { Forecast } from '../../../../../backend/services/forecast/types/entities/forecast';
import { GetForecast } from '../../../store/forecast/action-creators';
import { GET_FORECAST } from '../../../store/forecast/actions';
import { selectCampaigns } from '../../../store/campaign/selectors';
import { selectFunnelTypes } from '../../../store/funnel-type/selectors';

export default function ForecastDetailsPage(): JSX.Element {
  const dispatch = useDispatch();

  const { id, clientId } = useParams<{ clientId: string; id: string }>();

  const [isModalVisible, setIsModalVisible] = useState(false);

  const funnelTypes = useSelector(selectFunnelTypes);
  const campaigns = useSelector(selectCampaigns);

  const forecast: Forecast | undefined = useSelector((state: AppState) => state.forecast.active);

  const isLoading = !!useSelector((state: AppState) => !!state.ui.loading[GET_FORECAST]);

  React.useEffect(() => {
    dispatch(new GetForecast(clientId, id));
  }, [id, clientId]);

  const handleEdit = () => {
    setIsModalVisible(true);
  };

  const handleBack = () => {
    dispatch(push('/forecasts'));
  };

  return (
    <Page className="forecast-details">
      <div className="forecast-details__back" onClick={handleBack}>
        <LeftOutlined className="forecast-details__back__icon" />
        <Typography.Link className="forecast-details__back__link" strong>
          Forecasts
        </Typography.Link>
      </div>
      {forecast ? (
        <>
          <Space
            align="start"
            style={{ justifyContent: 'space-between', width: '100%' }}
            className="forecast-details__header"
          >
            <Typography.Title level={3} className="forecast-details__header__title">
              {forecast.name}
            </Typography.Title>
            <Space align="end" size="large">
              <Typography.Link className="forecast-details__header__link" strong onClick={handleEdit}>
                Edit
              </Typography.Link>
            </Space>
          </Space>
          <Space
            className="forecast-details__wrapper"
            align="start"
            style={{ justifyContent: 'space-between', width: '100%' }}
            size="large"
          >
            <BarGraph
              stages={forecast.stages}
              costStage={forecast.costStage}
              valueStage={forecast.valueStage}
              budget={forecast.budget}
              revenue={forecast.revenue}
              itemClassName="alt-color"
            />
            <ConversionRate rates={forecast.stages.slice(1).map((s) => s.conversionRate || 0)} />
          </Space>
          <Statistics
            className="forecast-details__statistics"
            budget={forecast.budget}
            revenue={forecast.revenue}
            ROI={forecast.ROI}
          />
        </>
      ) : null}
      {isLoading ? <Skeleton /> : null}
      {isModalVisible ? (
        <ForecastEditorModal
          isModalVisible={isModalVisible}
          handleClose={() => setIsModalVisible(false)}
          campaigns={campaigns}
          funnelTypes={funnelTypes}
          selectedCampaignId={forecast?.campaignId}
          forecast={forecast}
        />
      ) : null}
    </Page>
  );
}
