import React from 'react';
import './styles.less';

import ClientCard from './client-card';
import { Spin, Button, Empty, Space, Typography } from 'antd';
import { Client } from '../../../../backend/services/client/types/entities/client';
import { useSelector } from 'react-redux';
import { getClientLabel } from '@webapp/store/auth/selectors';

interface ClientListProps {
  className?: string;
  clients: Client[];
  loading: boolean;
  loadingCreate: boolean;
  isSales?: boolean;
  onNew: () => void;
  onEdit: (client: Client) => void;
}

export default function ClientsList({
  className,
  clients,
  loading,
  loadingCreate,
  isSales,
  onNew,
  onEdit,
}: ClientListProps): JSX.Element {
  const hasClients = clients.length > 0;

  const clientLabel = useSelector(getClientLabel);

  return (
    <Spin spinning={loading}>
      <div className={`${className || ''} client`}>
        {loading ? null : hasClients ? (
          <Space size="middle" wrap>
            {clients.map((client) => (
              <ClientCard
                className="client__card"
                key={`${client.id}`}
                client={client}
                onEdit={onEdit}
                isSales={isSales}
              />
            ))}
          </Space>
        ) : (
          <Space className="client__empty" direction="vertical" align="center">
            <Empty description={<Typography.Text>No {`${clientLabel.toLowerCase()}s`} yet</Typography.Text>}>
              <Button type="primary" size="large" onClick={onNew} loading={loadingCreate}>
                New {clientLabel}
              </Button>
            </Empty>
          </Space>
        )}
      </div>
    </Spin>
  );
}
