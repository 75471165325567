import { LoginUserSuccess } from './../../auth/action-creators';
import { Observable } from 'rxjs';
import { ofType } from 'redux-observable';
import { map } from 'rxjs/operators';
import { Actions } from '../../actions';
import { ListFunnelTemplate } from '../action-creators';
import { LOGIN_USER_SUCCESS } from '@webapp/store/auth/actions';

export const listFunnelTemplateAfterActiveEpic = (action$: Observable<Actions>): Observable<Actions> =>
  action$.pipe(
    ofType<Actions, LoginUserSuccess>(LOGIN_USER_SUCCESS),
    map(() => new ListFunnelTemplate())
  );
