export const LIST_CAMPAIGN = '[Campaign] List campaign';
export const LIST_CAMPAIGN_SUCCESS = '[Campaign] List campaign Success';
export const LIST_CAMPAIGN_ERROR = '[Campaign] List campaign Error';

export const CREATE_CAMPAIGN = '[Campaign] Create campaign';
export const CREATE_CAMPAIGN_SUCCESS = '[Campaign] Create campaign Success';
export const CREATE_CAMPAIGN_ERROR = '[Campaign] Create campaign Error';

export const GET_CAMPAIGN = '[Campaign] Get campaign';
export const GET_CAMPAIGN_SUCCESS = '[Campaign] Get campaign Success';
export const GET_CAMPAIGN_ERROR = '[Campaign] Get campaign Error';

export const UPDATE_CAMPAIGN = '[Campaign] Update campaign';
export const UPDATE_CAMPAIGN_SUCCESS = '[Campaign] Update campaign Success';
export const UPDATE_CAMPAIGN_ERROR = '[Campaign] Update campaign Error';

export const DELETE_CAMPAIGN = '[Campaign] Delete campaign';
export const DELETE_CAMPAIGN_SUCCESS = '[Campaign] Delete campaign Success';
export const DELETE_CAMPAIGN_ERROR = '[Campaign] Delete campaign Error';
