import React from 'react';
import './styles.less';

import { Space, Typography } from 'antd';

import { ContactsOutlined, TeamOutlined } from '@ant-design/icons';

import Page from '@webapp/components/layout/page';
import SettingsCard from '@webapp/components/settings-card';
import FunnelIcon from '@webapp/components/icons/funnel';

export default function SettingsPage(): JSX.Element {
  const SETTINGS_ITEMS = [
    {
      title: 'Subscription',
      icon: <ContactsOutlined />,
      to: '/settings/subscription',
    },
    {
      title: 'Team',
      icon: <TeamOutlined />,
      to: '/team',
    },
    {
      title: 'Funnel Templates',
      icon: <FunnelIcon />,
      to: '/funnel-templates',
    },
  ];

  return (
    <Page className="settings">
      <Space align="start" style={{ justifyContent: 'space-between', width: '100%' }}>
        <Typography.Title level={3} className="settings__title">
          Settings
        </Typography.Title>
      </Space>
      <Space size="large" wrap>
        {SETTINGS_ITEMS.map((item, i) => (
          <SettingsCard key={i} title={item.title} icon={item.icon} to={item.to} />
        ))}
      </Space>
    </Page>
  );
}
