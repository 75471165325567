import { Scenario } from '../../../../backend/services/scenario/types/entities/scenario';
import AppState from '../state';

export const selectScenarios = (state: AppState): Scenario[] => state.scenario.list.items;

export const groupScenariosByFunnelType = (state: AppState): Record<string, Scenario[]> =>
  selectScenarios(state).reduce((memo: Record<string, Scenario[]>, scenario) => {
    if (!(scenario.funnelTypeId in memo)) {
      memo[scenario.funnelTypeId] = [];
    }
    memo[scenario.funnelTypeId].push(scenario);

    return memo;
  }, {});
