import React from 'react';

import { Row, Space } from 'antd';

import PageBackground from '@webapp/components/layout/page-background';
import RegistrationForm from '@webapp/components/auth/registration-form';

export default function RegistrationPage(): JSX.Element {
  return (
    <PageBackground>
      <Row justify="center" align="middle" style={{ minHeight: '100vh' }}>
        <Space direction="vertical" align="center">
          <RegistrationForm />
        </Space>
      </Row>
    </PageBackground>
  );
}
