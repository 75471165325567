import { InvoiceState } from './state';
import { invoiceInitialState } from './initial-state';
import { InvoiceActions } from './action-creators';
import * as actions from './actions';

export const invoiceReducer = (state: InvoiceState = invoiceInitialState, action: InvoiceActions): InvoiceState => {
  switch (action.type) {
    case actions.LIST_INVOICE_SUCCESS: {
      return {
        ...state,
        list: {
          items: action.results.items || [],
          nextToken: action.results.nextToken,
        },
      };
    }

    default: {
      return { ...state };
    }
  }
};
