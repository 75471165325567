import { Observable } from 'rxjs';
import { Action } from 'redux';
import { StateObservable } from 'redux-observable';
import { push } from 'connected-react-router';
import { map, filter, withLatestFrom } from 'rxjs/operators';
import { isSubscriptionCancelled } from '../../subscriptions/selectors';
import { Actions } from '../../actions';
import AppState from '../../state';
import { SUBSCRIPTION_ROUTES } from '../../../routes/utils';

export const validateSubscriptionEpic = (
  action$: Observable<Actions>,
  state$: StateObservable<AppState>
): Observable<Action> =>
  action$.pipe(
    withLatestFrom(state$),
    filter((values) => {
      const state = values[1];

      if (SUBSCRIPTION_ROUTES.includes(state.router.location.pathname)) {
        return false;
      }

      return isSubscriptionCancelled(state);
    }),
    map(() => push('/plans'))
  );
