import React from 'react';
import './styles.less';

import { Row, Space } from 'antd';

import PlanWrapper from '@webapp/components/plans';
import IconLogo from '@webapp/components/icons/logo';
import PageBackground from '@webapp/components/layout/page-background';

export default function PlansPage(): JSX.Element {
  return (
    <PageBackground>
      <Row className="subscription" justify="center" align="middle" style={{ minHeight: '100vh' }}>
        <Space direction="vertical" align="center" size="large">
          <IconLogo className="subscription__logo" />
          <PlanWrapper />
        </Space>
      </Row>
    </PageBackground>
  );
}
