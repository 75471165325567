import React from 'react';

export default function FunnelIcon({ className, fill }: { className?: string; fill?: string }): JSX.Element {
  return (
    <svg x="0" y="0" width="16" height="16" viewBox="0 0 16 16" className={className}>
      <path
        fill={fill ? fill : '#ffffff'}
        d="M15.4 0H0.6c-0.5 0-0.8 0.5-0.5 0.9l4.7 7.9v4.5c0 0.4 0.3 0.6 0.6 0.6h5.3c0.4 0 0.6-0.3 0.6-0.6V8.9l4.7-7.9C16.2 0.5 15.9 0 15.4 0zM9.8 12.6H6.2V9.7h3.7L9.8 12.6 9.8 12.6zM10 8.2L9.9 8.5H6.2L6 8.2 4.5 5.6h7.1L10 8.2zM12.3 4.3H3.7l-1.7-2.9h12L12.3 4.3z"
      />
    </svg>
  );
}
