import React, { useState } from 'react';
import { Button, Space, Typography, Skeleton, Form } from 'antd';
import Page from '../../components/layout/page';
import ClientsList from '@webapp/components/clients-list';
import ClientEditorModal from '@webapp/components/modal/client-editor';
import { useSelector } from 'react-redux';
import AppState from '../../store/state';
import { Client } from '../../../../backend/services/client/types/entities/client';
import { CREATE_CLIENT, DELETE_CLIENT, LIST_CLIENT, UPDATE_CLIENT } from '../../store/client/actions';
import { getClientLabel, isSalesCompany } from '@webapp/store/auth/selectors';

export default function Clients(): JSX.Element {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [activeClient, setActiveClient] = useState<Client>();

  const clientList: Client[] = useSelector((state: AppState) => state.client.list.items);

  const loading: boolean = useSelector((state: AppState) => !!state.ui.loading[LIST_CLIENT]);
  const isSales = useSelector(isSalesCompany);

  const loadingCreate: boolean = useSelector(
    (state: AppState) =>
      !!(state.ui.loading[CREATE_CLIENT] || state.ui.loading[UPDATE_CLIENT] || state.ui.loading[DELETE_CLIENT])
  );

  const clientLabel = useSelector(getClientLabel);

  const showModal = () => {
    setIsModalVisible(true);
  };

  const [form] = Form.useForm();

  const handleNew = () => {
    setActiveClient(undefined);
    showModal();
  };

  const handleEdit = (client: Client) => {
    setActiveClient(client);
    showModal();
  };

  const handleClose = () => {
    setIsModalVisible(false);
  };

  return (
    <Page>
      <Space align="start" style={{ justifyContent: 'space-between', width: '100%' }}>
        <Typography.Title level={3}>{`${clientLabel}s`}</Typography.Title>
        <Button type="primary" size="large" onClick={handleNew} loading={loadingCreate}>
          New {clientLabel}
        </Button>
      </Space>
      {loading ? (
        <Skeleton />
      ) : (
        <ClientsList
          clients={clientList}
          onNew={handleNew}
          onEdit={handleEdit}
          isSales={isSales}
          loading={loading}
          loadingCreate={loadingCreate}
        />
      )}
      <ClientEditorModal
        isModalVisible={isModalVisible}
        isSales={isSales}
        handleClose={handleClose}
        client={activeClient}
        form={form}
      />
    </Page>
  );
}
