import { Observable } from 'rxjs';
import { ofType } from 'redux-observable';
import { mergeMap } from 'rxjs/operators';
import * as funnelTemplates from '../../../sdk/funnel-templates';
import { Actions } from '../../actions';
import { DeleteFunnelTemplate, DeleteFunnelTemplateSuccess, DeleteFunnelTemplateError } from '../action-creators';
import { DELETE_FUNNEL_TEMPLATE } from '../actions';
import ApiError from '../../../../../backend/commons/utils/api/api-error';

export const deleteFunnelTemplateEpic = (action$: Observable<Actions>): Observable<Actions> =>
  action$.pipe(
    ofType<Actions, DeleteFunnelTemplate>(DELETE_FUNNEL_TEMPLATE),
    mergeMap(async (action) => {
      try {
        const result = await funnelTemplates.remove(action.funnelTemplateId);

        return new DeleteFunnelTemplateSuccess(action.funnelTemplateId, result);
      } catch (err) {
        return new DeleteFunnelTemplateError((err as ApiError).message, (err as ApiError).description || '');
      }
    })
  );
