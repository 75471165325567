import React from 'react';
import './styles.less';

import { Button, Input, Space, Typography, Modal, Select } from 'antd';
import { CloseCircleOutlined } from '@ant-design/icons';

const { Option } = Select;

import Form from 'antd/lib/form/Form';
import FormItem from 'antd/lib/form/FormItem';
import CampaignsModalTable from '../table/campaign-modal-table';
import { Campaign } from '../../../../backend/services/campaign/types/entities/campaign';
import { Forecast, ForecastType } from '../../../../backend/services/forecast/types/entities/forecast';
import { useDispatch } from 'react-redux';
import { CreateForecast, UpdateForecast } from '../../store/forecast/action-creators';
import { CreateForecastBodyRequest } from '../../../../backend/services/forecast/functions/create-forecast/interface';
import { FunnelType } from '../../../../backend/services/funnel-type/types/entities/funnel-type';
import { push } from 'connected-react-router';

interface ForecastEditorModalProps {
  className?: string;
  isModalVisible: boolean;
  funnelTypes: FunnelType[];
  campaigns: Campaign[];
  selectedCampaignId?: string;
  forecast?: Forecast;
  handleClose: () => void;
}

const getNewForecast = (
  name: string,
  campaign: Campaign,
  forecastType: ForecastType,
  value: number | undefined,
  conversionRate: Record<number, number>
): CreateForecastBodyRequest => {
  let valueField: keyof CreateForecastBodyRequest | undefined;

  if (forecastType === 'Budget') {
    valueField = 'budget';
  }

  if (forecastType === 'Outcome') {
    valueField = 'outcome';
  }

  if (forecastType === 'Revenue') {
    valueField = 'revenue';
  }

  return {
    name,
    campaignId: campaign.id,
    funnelTypeId: campaign.funnelTypeId,
    stages: campaign.stages.map((stage, i) => {
      if (forecastType === 'ConversionRate') {
        return {
          ...stage,
          conversionRate: conversionRate[i] / 100,
          value: i === 0 ? stage.value : campaign.stages[i - 1].value * (conversionRate[i] / 100),
        };
      }
      return stage;
    }),
    forecastType,
    costStage: {
      ...campaign.costStage,
      value: forecastType === 'Cost' ? (value as number) : campaign.costStage.value,
    },
    valueStage: {
      ...campaign.valueStage,
      value: forecastType === 'Value' ? (value as number) : campaign.valueStage.value,
    },
    ...(valueField ? { [valueField]: value } : {}),
  };
};

export default function ForecastEditorModal({
  className,
  isModalVisible,
  campaigns,
  funnelTypes,
  forecast,
  selectedCampaignId,
  handleClose,
}: ForecastEditorModalProps): JSX.Element {
  const [name, setName] = React.useState(forecast?.name);
  const [type, setType] = React.useState(forecast?.forecastType);
  const [campaignId, setCampaignId] = React.useState(forecast?.campaignId || selectedCampaignId);
  const [conversionRate, setConversionRate] = React.useState<Record<number, number>>({});

  const campaign = campaigns.find((c) => c.id === campaignId);
  const funnelType = funnelTypes.find((f) => f.id === campaign?.funnelTypeId);

  const dispatch = useDispatch();

  const getInitialValue = (type?: ForecastType): number | undefined => {
    switch (type) {
      case 'Budget':
        return forecast?.budget;
      case 'Revenue':
        return forecast?.revenue;
      case 'Value':
        return forecast?.valueStage.value;
      case 'Outcome':
        return forecast?.outcome;
      case 'Cost':
        return forecast?.costStage.value;
      default:
        return forecast?.budget;
    }
  };

  const [value, setValue] = React.useState(getInitialValue(forecast?.forecastType));

  const onFinish = () => {
    const campaign = campaigns.find((c) => c.id === campaignId);
    if (name?.length && campaign && type) {
      const forecastUpdate = getNewForecast(name, campaign, type, value, conversionRate);

      if (forecast?.id) {
        dispatch(new UpdateForecast(forecast.id, forecastUpdate));
      } else {
        dispatch(new CreateForecast(forecastUpdate));
      }
    }
    handleClose();
    if (!forecast?.id) {
      dispatch(push('/forecasts'));
    }
  };

  const hasPrefix = (type?: ForecastType): boolean => {
    switch (type) {
      case 'Budget':
        return true;
      case 'Cost':
        return true;
      case 'Revenue':
        return true;
      case 'Value':
        return true;
      default:
        return false;
    }
  };

  const getLabel = (type?: ForecastType) => {
    if (type) {
      if (campaign) {
        if (type === 'Cost') {
          return `${type} (${campaign?.costStage.name})`;
        }
        if (type === 'Value') {
          return `${type} (${campaign?.valueStage.name})`;
        }
      }
      return type;
    }
    return 'Value';
  };

  const getMin = (type?: ForecastType): number => {
    if (type === 'Budget') {
      return 0.01;
    }

    if (type === 'Cost') {
      return 0.01;
    }

    if (type === 'Value') {
      return 0.01;
    }

    return 0;
  };

  return (
    <div className={`${className || ''} modal`}>
      <Modal
        className="modal__wrapper"
        visible={isModalVisible}
        maskClosable
        centered
        footer={null}
        width={1000}
        closeIcon={<CloseCircleOutlined onClick={handleClose} className="modal__close" />}
      >
        <Typography.Title className="modal__wrapper__title" level={3}>
          {forecast?.id ? 'Edit' : 'New'} Forecast
        </Typography.Title>

        <Form name="newForecast" layout="vertical" initialValues={{ remember: false }} onFinish={onFinish}>
          <Space size="large" className="modal__wrapper__form__space growth">
            <FormItem label="Select a Forecast Type" initialValue={type}>
              <Select
                className="modal__wrapper__form__item__select"
                placeholder="Forecast Type"
                size="large"
                value={type}
                onChange={(ev) => setType(ev)}
              >
                <Option value="Budget">Budget</Option>
                <Option value="Cost">Cost</Option>
                <Option value="Outcome">Outcome</Option>
                <Option value="Revenue">Revenue</Option>
                <Option value="Value">Value</Option>
                <Option value="ConversionRate">Conversion Rate</Option>
              </Select>
            </FormItem>
            <FormItem label="Select a Campaign" initialValue={campaignId}>
              <Select
                className="modal__wrapper__form__item__select"
                placeholder="Campaigns"
                size="large"
                value={campaignId}
                onChange={(ev) => setCampaignId(ev)}
              >
                {campaigns.map((campaign) => (
                  <Option key={campaign.id} value={campaign.id}>
                    {campaign.name}
                  </Option>
                ))}
              </Select>
            </FormItem>
          </Space>
          <div className="modal__wrapper__form__space-custom">
            <FormItem
              name="name"
              label="Forecast Name"
              initialValue={name}
              className="modal__wrapper__form__space-custom__item"
              rules={[{ required: true, message: 'Input the Forecast Name' }]}
            >
              <Input
                className="modal__wrapper__form__space-custom__item__input"
                placeholder="Forecast Name"
                size="large"
                value={name}
                onChange={(ev) => setName(ev.target.value)}
              />
            </FormItem>
            <div className="modal__wrapper__form__input-group">
              {type === 'ConversionRate' ? (
                <CampaignsModalTable
                  className="modal__wrapper__new-scenario__table"
                  funnelType={funnelType}
                  campaign={forecast}
                  onChange={setConversionRate}
                  valueTitle="Conversion Rate"
                  isRatio
                />
              ) : (
                <FormItem
                  name="Value"
                  label={getLabel(type)}
                  initialValue={value}
                  className="modal__wrapper__form__input-group__item"
                  rules={[{ required: true, message: `Input the ${getLabel(type)}` }]}
                >
                  <Input
                    className="modal__wrapper__form__input-group__item__input"
                    placeholder={type || 'Value'}
                    addonBefore={type || 'Value'}
                    size="large"
                    type="number"
                    min={getMin(type)}
                    step={getMin(type)}
                    prefix={hasPrefix(type) ? '$' : ''}
                    value={value}
                    onChange={(ev) => setValue(Number(ev.target.value))}
                  />
                </FormItem>
              )}
            </div>
          </div>
          <Space size="large" className="modal__wrapper__buttongroup">
            <Button onClick={handleClose} htmlType="reset" size="large" className="modal__wrapper__button">
              Cancel
            </Button>
            <Button type="primary" htmlType="submit" size="large" className="modal__wrapper__button">
              Save
            </Button>
          </Space>
        </Form>
      </Modal>
    </div>
  );
}
