import React, { useEffect } from 'react';
import './styles.less';

import { useHistory } from 'react-router';

import Separator from '@webapp/components/separator';

import { Form, Button, Input, Typography, Space, Card } from 'antd';
import { LeftCircleTwoTone } from '@ant-design/icons';

import { CardCvcElement, CardExpiryElement, CardNumberElement, useStripe, useElements } from '@stripe/react-stripe-js';

import {
  StripeCardElementOptions,
  StripeCardNumberElementChangeEvent,
  StripeCardCvcElementChangeEvent,
  StripeCardExpiryElementChangeEvent,
} from '@stripe/stripe-js';
import { useDispatch, useSelector } from 'react-redux';
import AppState from '../../store/state';
import { UpdateSubscription } from '../../store/subscriptions/action-creators';
import { Price } from '../../../../backend/services/product/types/entities/price';
import { formatCurrency } from '../../utils/format-currency';
import PromoCodeModal from '../modal/promo-code';
import { ResetPromoCode } from '../../store/promo-code/action-creators';

interface CheckoutWrapperProps {
  className?: string;
}

interface CardBuilderExtraProps {
  showIcon?: boolean;
}

const buildCardOptions = (_placeholder: string, extra: CardBuilderExtraProps = {}): StripeCardElementOptions => ({
  style: {
    base: {
      fontSize: '16px',
      lineHeight: '16px',
      fontWeight: '400',
      fontFamily: '"DM Sans", sans-serif',
      color: 'rgba(0, 0, 0, .85)',
      '::placeholder': {
        color: '#BFBFBF',
        fontWeight: '400',
      },
    },
  },
  // placeholder,
  ...extra,
});

export default function CheckoutWrapper({ className }: CheckoutWrapperProps): JSX.Element {
  const [loading, setLoading] = React.useState(false);
  const [complete, setComplete] = React.useState<string[]>([]);
  const [errors, setErrors] = React.useState<string[]>([]);
  const [paymentError, setPaymentError] = React.useState<string>();
  const [fullName, setFullName] = React.useState('');
  // const [promoCodeDiscount] = React.useState<number | undefined>(50);

  const [isPromoCodeModalVisible, setIsPromoCodeModalVisible] = React.useState(false);

  const history = useHistory();
  const dispatch = useDispatch();

  const goBack = () => {
    history.goBack();
  };

  const resetPromoCode = () => {
    dispatch(new ResetPromoCode());
  };

  const stripe = useStripe();
  const elements = useElements();

  const selected = useSelector((state: AppState) => state.subscriptions.selected);

  const promoCode = useSelector((state: AppState) => state.promoCode?.promoCode);

  useEffect(() => {
    setIsPromoCodeModalVisible(false);
  }, [promoCode]);

  useEffect(() => {
    resetPromoCode();
  }, []);

  if (!selected) {
    return <></>;
  }

  const { product, frequency } = selected;

  if (!product || !frequency) {
    return <></>;
  }

  const getPromoCodeValue = (original: number): number => {
    if (promoCode?.amount_off) {
      return promoCode.amount_off;
    }

    if (promoCode?.percent_off) {
      return original * (promoCode.percent_off / 100);
    }

    return 0;
  };

  const getPromoCodeFrequency = (): React.ReactNode => {
    if (promoCode?.duration_in_months) {
      const f = promoCode.duration_in_months;

      return (
        <>
          <br /> for {f} month{f > 1 ? 's' : ''}
        </>
      );
    }

    if (promoCode?.duration) {
      return promoCode?.duration;
    }

    return '';
  };

  const getPrice = (): Price | undefined => product.prices.find((p) => p.frequency === frequency);

  const getPlanPrice = (): string => {
    const price = getPrice();
    if (price) {
      return formatCurrency(price.flatValue / 100);
    }

    return '$-';
  };

  const getFinalPrice = (): string => {
    const price = getPrice();
    const promoCode = getPromoCodeValue(price?.flatValue || 0);

    if (price && promoCode) {
      const total = price.flatValue - promoCode;
      if (total < 0) {
        return formatCurrency(0);
      }
      return formatCurrency(total / 100);
    }

    if (price) {
      return formatCurrency(price.flatValue / 100);
    }

    return '$-';
  };

  const getFrequency = (): string => (frequency === 'month' ? 'Monthly' : 'Yearly');

  const handleChange = (
    key: string,
    event: StripeCardNumberElementChangeEvent | StripeCardCvcElementChangeEvent | StripeCardExpiryElementChangeEvent
  ) => {
    if (event.error) {
      setErrors(errors.concat([key]));
      return;
    }

    setErrors(errors.filter((e) => e !== key));

    if (event.complete) {
      setComplete(complete.concat([key]));
    } else {
      setComplete(complete.filter((e) => e !== key));
    }
  };

  const handleSubmit = async () => {
    const price = getPrice();
    if (!stripe || !elements || !price || !product) {
      return;
    }

    const cardElement = elements.getElement(CardNumberElement);

    if (!cardElement) {
      return;
    }

    setLoading(true);

    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: 'card',
      card: cardElement,
      billing_details: {
        name: fullName,
      },
    });

    if (error) {
      setPaymentError(error.message || error.type);
      setLoading(false);
      return false;
    }

    if (paymentMethod) {
      dispatch(
        new UpdateSubscription({
          product,
          price,
          paymentMethodId: paymentMethod?.id,
          coupon: promoCode?.id,
        })
      );
    }

    return false;
  };

  const openCuponModal = () => {
    setIsPromoCodeModalVisible(true);
  };

  const handleCuponModalClose = () => {
    setIsPromoCodeModalVisible(false);
  };

  return (
    <>
      <Card className={`${className || ''} checkout__wrapper`}>
        <div className="checkout__wrapper__header">
          <LeftCircleTwoTone
            twoToneColor={'#1890ff'}
            className="checkout__wrapper__header__icon"
            onClick={() => goBack()}
          />
          <Typography.Title level={4} className="checkout__wrapper__header__title">
            Check Out
          </Typography.Title>
        </div>

        {paymentError ? <Typography.Text type="danger">{paymentError}</Typography.Text> : null}

        <Form className="checkout__wrapper__form" name="basic" layout="vertical" initialValues={{ remember: true }}>
          <Form.Item
            className="checkout__wrapper__form__item"
            name="card-number"
            rules={[{ required: true, message: 'Please input your Card Number!' }]}
          >
            <CardNumberElement
              className="checkout__wrapper__form__item__number"
              options={buildCardOptions('Card Number', { showIcon: true })}
              onChange={(event) => handleChange('cardNumber', event)}
            />
          </Form.Item>
          <Form.Item
            className="checkout__wrapper__form__item"
            name="card-name"
            rules={[
              {
                required: true,
                message: 'Please input your Cardholders Name!',
              },
            ]}
          >
            <Input
              size="large"
              placeholder="Cardholder's Name"
              value={fullName}
              onChange={(ev) => setFullName(ev.target.value)}
            />
          </Form.Item>
          <Space className="checkout__wrapper__form__row" align="center">
            <Form.Item
              className="checkout__wrapper__form__item"
              name="card-ccv"
              rules={[{ required: true, message: 'Please input your Card CCV!' }]}
            >
              <CardCvcElement
                className="checkout__wrapper__form__item__ccv"
                options={buildCardOptions('CVV', { showIcon: false })}
                onChange={(event) => handleChange('cardCVV', event)}
              />
            </Form.Item>
            <Form.Item
              className="checkout__wrapper__form__item"
              name="card-expiry"
              rules={[
                {
                  required: true,
                  message: 'Please input your Card Expiry Date (MM/YY)!',
                },
              ]}
            >
              <CardExpiryElement
                className="checkout__wrapper__form__item__expiry"
                options={buildCardOptions('Expiry Date (MM/YY)', {
                  showIcon: false,
                })}
                onChange={(event) => handleChange('cardExpiry', event)}
              />
            </Form.Item>
          </Space>
        </Form>

        <div>
          <Button className="checkout__wrapper__cupon-btn" type="dashed" size="large" onClick={openCuponModal}>
            Add promo code
          </Button>
          <Separator />
          <div className="checkout__wrapper__item">
            <Typography.Text strong>{getFrequency()} Subscription</Typography.Text>
            <Typography.Text>{getPlanPrice()}</Typography.Text>
          </div>
          {promoCode ? (
            <>
              <Separator />
              <div className="checkout__wrapper__item">
                <Typography.Text strong ellipsis className="checkout__wrapper__item__promo">
                  Code: <b>{promoCode.id.toUpperCase()}</b>
                </Typography.Text>
                <Typography.Text>
                  {formatCurrency((getPromoCodeValue(getPrice()?.flatValue || 0) / 100) * -1)} {getPromoCodeFrequency()}
                </Typography.Text>
              </div>
            </>
          ) : null}
          <Separator />
          <div className="checkout__wrapper__item">
            <Typography.Text strong>Total</Typography.Text>
            <Typography.Text>{getFinalPrice()}</Typography.Text>
          </div>
        </div>
        <Button
          className="checkout__wrapper__button"
          type="primary"
          size="large"
          loading={loading}
          onClick={() => handleSubmit()}
        >
          Finish Payment
        </Button>
      </Card>
      <PromoCodeModal isModalVisible={isPromoCodeModalVisible} handleClose={handleCuponModalClose} />
    </>
  );
}
