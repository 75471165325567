import { Observable } from 'rxjs';
import { ofType, StateObservable } from 'redux-observable';
import { mergeMap, withLatestFrom } from 'rxjs/operators';
import * as scenarios from '../../../sdk/scenarios';
import { Actions } from '../../actions';
import AppState from '../../state';
import { CreateScenario, CreateScenarioSuccess, CreateScenarioError } from '../action-creators';
import { CREATE_SCENARIO } from '../actions';
import { NoOp } from '../../ui/action-creators';
import ApiError from '../../../../../backend/commons/utils/api/api-error';

export const createScenarioEpic = (
  action$: Observable<Actions>,
  state$: StateObservable<AppState>
): Observable<Actions> =>
  action$.pipe(
    ofType<Actions, CreateScenario>(CREATE_SCENARIO),
    withLatestFrom(state$),
    mergeMap(async ([action, state]) => {
      if (!state.client.active?.id) {
        return new NoOp();
      }

      try {
        const scenario = await scenarios.create(state.client.active.id, action.newScenario);

        return new CreateScenarioSuccess(scenario);
      } catch (err) {
        return new CreateScenarioError((err as ApiError).message, (err as ApiError).description || '');
      }
    })
  );
