import React, { useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { push } from 'connected-react-router';

import { Button, Empty, Skeleton, Space, Typography } from 'antd';

import Page from '@webapp/components/layout/page';
import ActionsTable from '@webapp/components/table/actions-table';

import CustomTextModal from '@webapp/components/modal/custom-text';
import ScenarioEditorModal from '@webapp/components/modal/scenario-editor';

import { groupScenariosByFunnelType } from '@webapp/store/scenario/selectors';
import { selectFunnelTypes } from '@webapp/store/funnel-type/selectors';
import { Scenario } from '../../../../backend/services/scenario/types/entities/scenario';
import { ColumnsType, ColumnType } from 'antd/lib/table';
import { DeleteScenario } from '../../store/scenario/action-creators';
import AppState from '../../store/state';
import { CREATE_SCENARIO, LIST_SCENARIO } from '../../store/scenario/actions';
import { formatCurrency } from '../../utils/format-currency';
import FunnelIcon from '@webapp/components/icons/funnel';

export default function ScenariosPage(): JSX.Element {
  const dispatch = useDispatch();

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false);

  const [selectedScenario, setSelectedScenario] = useState<Partial<Scenario>>();

  const groupedScenarios = useSelector(groupScenariosByFunnelType);
  const funnelTypes = useSelector(selectFunnelTypes);
  const clientId = useSelector((state: AppState) => state.client.active?.id);
  const isLoadingList = useSelector((state: AppState) => state.ui.loading[LIST_SCENARIO]);
  const isLoadingNew = useSelector((state: AppState) => !!state.ui.loading[CREATE_SCENARIO]);

  const handleNew = () => {
    setSelectedScenario(undefined);
    setIsModalVisible(true);
  };

  const handleClose = () => {
    setIsModalVisible(false);
  };

  const handleView = (scenario: Scenario) => {
    dispatch(push(`/scenarios/${clientId}/${scenario.id}`));
  };

  const handleEdit = (scenario: Scenario) => {
    setSelectedScenario(scenario);
    setIsModalVisible(true);
  };

  const handleClone = (scenario: Scenario) => {
    setSelectedScenario({
      ...scenario,
      name: `${scenario.name} Clone`,
      id: undefined,
      stages: scenario.stages.map((stage) => ({ ...stage })),
      costStage: { ...scenario.costStage },
      valueStage: { ...scenario.valueStage },
    });
    setIsModalVisible(true);
  };

  const openDeleteModal = (scenario: Scenario) => {
    setSelectedScenario(scenario);
    setIsDeleteModalVisible(true);
  };

  const handleDelete = () => {
    if (selectedScenario?.id) {
      dispatch(new DeleteScenario(selectedScenario.id));
    }
    handleCloseDelete();
  };

  const handleCloseDelete = () => {
    setIsDeleteModalVisible(false);
  };

  const getFunnelTypeName = (funnelTypeId: string): string => {
    const funnelType = funnelTypes.find((f) => f.id === funnelTypeId);

    return funnelType?.name || '?';
  };

  const getColumns = (funnelTypeId: string): ColumnsType<Scenario> => {
    const funnelType = funnelTypes.find((f) => f.id === funnelTypeId);

    if (funnelType) {
      return [
        {
          title: 'Name',
          dataIndex: 'name',
          key: `${funnelTypeId}-name`,
        } as ColumnType<Scenario>,
      ]
        .concat(
          funnelType.stages.slice(-10).map(
            (stage, i) =>
              ({
                title: stage,
                dataIndex: `stages.${i}.value`,
                render: (_, scenario) => {
                  if (scenario?.stages[i]?.value !== undefined) {
                    return `${scenario?.stages[i]?.value.toLocaleString()}`;
                  }
                  return '-';
                },
                key: `${funnelTypeId}-${i}`,
              } as ColumnType<Scenario>)
          )
        )
        .concat([
          {
            title: 'Budget',
            dataIndex: 'budget',
            key: `${funnelTypeId}-budget`,
            render: (_, scenario) => {
              return `${formatCurrency(scenario?.budget)}`;
            },
          } as ColumnType<Scenario>,
          {
            title: 'Revenue',
            dataIndex: 'revenue',
            key: `${funnelTypeId}-revenue`,
            render: (_, scenario) => {
              return `${formatCurrency(scenario?.revenue)}`;
            },
          } as ColumnType<Scenario>,
        ]);
    }

    return [];
  };

  return (
    <Page>
      <Space align="start" style={{ justifyContent: 'space-between', width: '100%' }}>
        <Typography.Title level={3}>Scenarios</Typography.Title>
        <Button type="primary" size="large" onClick={handleNew} loading={isLoadingNew}>
          New Scenario
        </Button>
      </Space>
      {isLoadingList ? (
        <Skeleton />
      ) : Object.keys(groupedScenarios).length ? (
        Object.keys(groupedScenarios).map((key) => (
          <ActionsTable<Scenario>
            key={key}
            title={getFunnelTypeName(key)}
            data={groupedScenarios[key]}
            keyMapper={(scenario) => scenario.id}
            dataColumns={getColumns(key)}
            handleView={handleView}
            handleEdit={handleEdit}
            handleDelete={openDeleteModal}
            handleClone={handleClone}
            icon={<FunnelIcon />}
          />
        ))
      ) : (
        <Space
          className="scenario__empty"
          direction="vertical"
          align="center"
          style={{
            height: 'calc(100% - 80px - 88px)',
            width: '100%',
            marginTop: 80,
          }}
        >
          <Empty description={<Typography.Text>No Scenarios yet</Typography.Text>}>
            <Button type="primary" size="large" onClick={handleNew} loading={isLoadingNew}>
              New Scenario
            </Button>
          </Empty>
        </Space>
      )}
      {isModalVisible ? (
        <ScenarioEditorModal
          funnelTypes={funnelTypes}
          scenario={selectedScenario as Scenario}
          isModalVisible={isModalVisible}
          handleClose={handleClose}
        />
      ) : null}
      <CustomTextModal
        title="Are you sure you want to delete this Scenario?"
        text="You will not be able to undo this action"
        button={
          <Button danger onClick={handleDelete}>
            Delete
          </Button>
        }
        isModalVisible={isDeleteModalVisible}
        handleClose={handleCloseDelete}
      />
    </Page>
  );
}
