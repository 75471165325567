import React, { useState } from 'react';
import './styles.less';

import { Button, Input, Space, Typography, Modal, Select, Tabs } from 'antd';
import { CloseCircleOutlined } from '@ant-design/icons';

const { Option } = Select;

import Form from 'antd/lib/form/Form';
import FormItem from 'antd/lib/form/FormItem';
import CampaignsModalTable from '../table/campaign-modal-table';
import { FunnelType } from '../../../../backend/services/funnel-type/types/entities/funnel-type';
import { CreateCampaign, UpdateCampaign } from '../../store/campaign/action-creators';
import { useDispatch } from 'react-redux';
import { push } from 'connected-react-router';
// import { CreateCampaignBodyRequest } from '@backend/services/campaign/functions/create-campaign/interface';
// import { UpdateCampaignBodyRequest } from '@backend/services/campaign/functions/update-campaign/interface';
import { Campaign } from '@backend/services/campaign/types/entities/campaign';

interface CampaignEditorModalProps {
  className?: string;
  isModalVisible: boolean;
  funnelTypes: FunnelType[];
  campaign?: Partial<Campaign>;
  handleClose: () => void;
}

// const getInitialValue = (
//   campaign?: Campaign,
//   stage?: string,
//   aggregatedValue = 0
// ): number | undefined => {
//   if (campaign && stage) {
//     return (
//       aggregatedValue /
//       (campaign?.stages.find((s) => s.name === stage)?.value || 0)
//     );
//   }
//   return undefined;
// };

const { TabPane } = Tabs;

export default function CampaignEditorModal({
  className,
  isModalVisible,
  campaign,
  funnelTypes,
  handleClose,
}: CampaignEditorModalProps): JSX.Element {
  const [funnelType, setFunnelType] = useState<FunnelType | undefined>(
    funnelTypes.find((f) => f.id === campaign?.funnelTypeId)
  );

  const [stageValues, setStageValues] = useState<Record<number, number>>(
    campaign?.stages?.reduce((memo: Record<number, number>, stage, i) => {
      memo[i] = stage.value || 0;
      return memo;
    }, {}) || {}
  );
  const [name, setName] = useState<string>(campaign?.name || '');

  const [cost, setCost] = useState<number | undefined>(campaign?.costStage?.value);
  const [value, setValue] = useState<number | undefined>(campaign?.valueStage?.value);

  const [budget, setBudget] = useState<number | undefined>(campaign?.budget);
  const [revenue, setRevenue] = useState<number | undefined>(campaign?.revenue);

  const [activeTab, setActiveTab] = useState('total');

  const dispatch = useDispatch();

  const handleValuesChange = (values: Record<number, number>) => {
    setStageValues(values);
  };

  const isValid = () => {
    const validUnit = activeTab !== 'unit' || (cost !== undefined && value !== undefined && cost >= 0 && value >= 0);
    const validTotal =
      activeTab !== 'total' || (budget !== undefined && revenue !== undefined && budget >= 0 && revenue >= 0);
    return (
      name.length &&
      funnelType?.id &&
      stageValues &&
      Object.keys(stageValues || {}).length === funnelType?.stages.length &&
      validUnit &&
      validTotal
    );
  };

  const onFinish = () => {
    if (isValid() && funnelType) {
      if (campaign?.id) {
        dispatch(
          new UpdateCampaign(campaign.id, {
            name,
            budget,
            revenue,
            stages: Object.keys(stageValues || {}).map((i) => ({
              name: funnelType.stages[Number(i)],
              value: stageValues[Number(i)],
            })),
            costStage:
              cost !== undefined
                ? {
                    name: funnelType.costStage,
                    value: cost,
                  }
                : {
                    name: funnelType.costStage,
                  },
            valueStage:
              value !== undefined
                ? {
                    name: funnelType.valueStage,
                    value,
                  }
                : {
                    name: funnelType.valueStage,
                  },
          })
        );
      } else {
        dispatch(
          new CreateCampaign({
            name,
            budget,
            revenue,
            funnelTypeId: funnelType.id,
            stages: Object.keys(stageValues || {}).map((i) => ({
              name: funnelType.stages[Number(i)],
              value: stageValues[Number(i)],
            })),
            costStage:
              cost !== undefined
                ? {
                    name: funnelType.costStage,
                    value: cost,
                  }
                : {
                    name: funnelType.costStage,
                  },
            valueStage:
              value !== undefined
                ? {
                    name: funnelType.valueStage,
                    value,
                  }
                : {
                    name: funnelType.valueStage,
                  },
          })
        );
      }
    }
    handleClose();
    if (!campaign?.id) {
      dispatch(push('/campaigns'));
    }
  };

  const isEditing = !!campaign?.id;

  return (
    <div className={`${className || ''} modal`}>
      <Modal
        className="modal__wrapper"
        visible={isModalVisible}
        maskClosable
        centered
        footer={null}
        width={1000}
        closeIcon={<CloseCircleOutlined onClick={handleClose} className="modal__close" />}
      >
        <Typography.Title className="modal__wrapper__title" level={3}>
          {isEditing ? 'Edit' : 'New'} Campaign
        </Typography.Title>

        <Form name="newCampaign" layout="vertical" initialValues={{ remember: false }} onFinish={onFinish}>
          <Space size="large" className="modal__wrapper__form__space growth">
            <FormItem
              name="Name"
              className="modal__wrapper__form__item"
              label="Campaign name"
              initialValue={name}
              rules={[{ required: true, message: 'Add a Campaign name' }]}
            >
              <Input
                className="modal__wrapper__form__item__input"
                placeholder="Campaign name"
                size="large"
                value={name}
                onChange={(ev) => setName(ev.target.value)}
              />
            </FormItem>
            <FormItem
              label="Select a Funnel"
              className="modal__wrapper__form__item__select"
              initialValue={funnelType?.id}
            >
              <Select
                placeholder="Funnel"
                size="large"
                value={funnelType?.id}
                onChange={(value) => setFunnelType(funnelTypes.find((f) => f.id === value))}
              >
                {funnelTypes.map((funnelType) => (
                  <Option key={funnelType.id} value={funnelType.id}>
                    {funnelType.name}
                  </Option>
                ))}
              </Select>
            </FormItem>
          </Space>
          <Space size="large" className="modal__wrapper__form__space growth" align="start">
            <CampaignsModalTable
              className="modal__wrapper__new-campaign__table"
              funnelType={funnelType}
              campaign={campaign}
              onChange={handleValuesChange}
            />
            <Tabs onChange={setActiveTab} activeKey={activeTab}>
              <TabPane tab="Total" key="total">
                <Space direction="vertical">
                  <FormItem
                    name="budget"
                    label={`Budget`}
                    className="modal__wrapper__form__item budget"
                    initialValue={budget}
                    rules={[{ required: activeTab === 'total', message: 'Enter the budget' }]}
                  >
                    <Input
                      className="modal__wrapper__form__item__input"
                      placeholder="0"
                      min={0}
                      step={0.01}
                      addonBefore="Budget"
                      size="large"
                      type="number"
                      prefix="$"
                      value={budget}
                      onChange={(ev) => setBudget(Number(ev.target.value))}
                    />
                  </FormItem>
                  <FormItem
                    name="revenue"
                    label={`Revenue`}
                    className="modal__wrapper__form__item revenue"
                    initialValue={revenue}
                    rules={[{ required: activeTab === 'total', message: 'Enter the revenue' }]}
                  >
                    <Input
                      className="modal__wrapper__form__item__input"
                      placeholder="0"
                      min={0}
                      step={0.01}
                      addonBefore="Revenue"
                      type="number"
                      size="large"
                      prefix="$"
                      value={revenue}
                      onChange={(ev) => setRevenue(Number(ev.target.value))}
                    />
                  </FormItem>
                </Space>
              </TabPane>
              <TabPane tab="Unit" key="unit">
                <Space direction="vertical">
                  <FormItem
                    name="cost"
                    label={`Cost${funnelType?.costStage ? ` (${funnelType?.costStage})` : ''}`}
                    className="modal__wrapper__form__item cost"
                    initialValue={cost}
                    rules={[{ required: activeTab === 'unit', message: 'Enter the cost' }]}
                  >
                    <Input
                      className="modal__wrapper__form__item__input"
                      placeholder="0"
                      min={0}
                      step={0.01}
                      addonBefore="Cost"
                      size="large"
                      type="number"
                      prefix="$"
                      value={cost}
                      onChange={(ev) => setCost(Number(ev.target.value))}
                    />
                  </FormItem>
                  <FormItem
                    name="value"
                    label={`Value${funnelType?.valueStage ? ` (${funnelType?.valueStage})` : ''}`}
                    className="modal__wrapper__form__item value"
                    initialValue={value}
                    rules={[{ required: activeTab === 'unit', message: 'Enter the value' }]}
                  >
                    <Input
                      className="modal__wrapper__form__item__input"
                      placeholder="0"
                      min={0}
                      step={0.01}
                      addonBefore="Value"
                      type="number"
                      size="large"
                      prefix="$"
                      value={value}
                      onChange={(ev) => setValue(Number(ev.target.value))}
                    />
                  </FormItem>
                </Space>
              </TabPane>
            </Tabs>
          </Space>
          <Space size="large" className="modal__wrapper__buttongroup">
            <Button onClick={handleClose} htmlType="reset" size="large" className="modal__wrapper__button">
              Cancel
            </Button>
            <Button type="primary" htmlType="submit" size="large" className="modal__wrapper__button">
              Save
            </Button>
          </Space>
        </Form>
      </Modal>
    </div>
  );
}
