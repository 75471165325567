import { GetCouponResponse } from '../../../backend/services/stripe-connector/functions/get-coupon/interface';
import { CallApi, handleJSONResponse } from './common';

const callAPI = CallApi('payment');

export const getCoupon = async (couponId: string): Promise<GetCouponResponse> => {
  const response = await callAPI(`/coupon/${couponId}`, 'GET');

  return await handleJSONResponse(response);
};
