import { Observable } from 'rxjs';
import { ofType } from 'redux-observable';
import { mergeMap } from 'rxjs/operators';
import * as templatetypes from '../../../sdk/funnel-templates';
import { Actions } from '../../actions';
import { UpdateFunnelTemplate, UpdateFunnelTemplateSuccess, UpdateFunnelTemplateError } from '../action-creators';
import { UPDATE_FUNNEL_TEMPLATE } from '../actions';
import ApiError from '../../../../../backend/commons/utils/api/api-error';

export const updateFunnelTemplateEpic = (action$: Observable<Actions>): Observable<Actions> =>
  action$.pipe(
    ofType<Actions, UpdateFunnelTemplate>(UPDATE_FUNNEL_TEMPLATE),
    mergeMap(async (action) => {
      try {
        const funnelTemplate = await templatetypes.update(action.funnelTemplateId, action.funnelTemplate);

        return new UpdateFunnelTemplateSuccess(funnelTemplate);
      } catch (err) {
        return new UpdateFunnelTemplateError((err as ApiError).message, (err as ApiError).description || '');
      }
    })
  );
