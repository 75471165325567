import React, { useState } from 'react';

import { Button } from 'antd';
import CustomTextModal from '../modal/custom-text';
import { useDispatch, useSelector } from 'react-redux';
import AppState from '@webapp/store/state';

import { selectIsOwner } from '@webapp/store/auth/selectors';
import { CancelSubscription } from '../../store/subscriptions/action-creators';
import { CANCEL_SUBSCRIPTION } from '../../store/subscriptions/actions';

interface CancelSuscriptionProps {
  className?: string;
}

export default function CancelSuscription({ className }: CancelSuscriptionProps): JSX.Element {
  const [isModalVisible, setIsModalVisible] = useState(false);

  const dispatch = useDispatch();

  const handleClick = () => {
    setIsModalVisible(true);
  };

  const handleClose = () => {
    setIsModalVisible(false);
  };

  const handleDelete = () => {
    dispatch(new CancelSubscription());
    handleClose();
  };

  const loading = useSelector((state: AppState) => !!state.ui.loading[CANCEL_SUBSCRIPTION]);

  const isOwner = useSelector(selectIsOwner);

  if (!isOwner) {
    return <></>;
  }

  return (
    <div className={`${className} delete-account`}>
      <Button danger size="large" onClick={handleClick} loading={loading}>
        Cancel Subscription
      </Button>
      <CustomTextModal
        isModalVisible={isModalVisible}
        handleClose={handleClose}
        title="Cancel Subscription"
        text="Are you sure you want to cancel your subscription?"
        button={
          <Button danger size="large" onClick={handleDelete}>
            Cancel Subscription
          </Button>
        }
      />
    </div>
  );
}
