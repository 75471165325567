import { CallApi, handleJSONResponse } from './common';
import { ListInviteResponse } from '../../../backend/services/invite/functions/list-invite/interface';
import {
  CreateInviteBodyRequest,
  CreateInviteResponse,
} from '../../../backend/services/invite/functions/create-invite/interface';
import { GetInviteResponse } from '../../../backend/services/invite/functions/get-invite/interface';
import { DeleteInviteResponse } from '../../../backend/services/invite/functions/delete-invite/interface';

const callAPI = CallApi('invite');

export const list = async (): Promise<ListInviteResponse> => {
  const data = {
    limit: 500,
  };
  const response = await callAPI(`/`, 'GET', data);

  return await handleJSONResponse(response);
};

export const create = async (invite: CreateInviteBodyRequest): Promise<CreateInviteResponse> => {
  const response = await callAPI(`/`, 'POST', invite);

  return await handleJSONResponse(response);
};

export const get = async (inviteId: string): Promise<GetInviteResponse> => {
  const response = await callAPI(`/${inviteId}`, 'GET', undefined, true);

  return await handleJSONResponse(response);
};

export const resend = async (inviteId: string): Promise<GetInviteResponse> => {
  const response = await callAPI(`/${inviteId}`, 'PUT');

  return await handleJSONResponse(response);
};

export const remove = async (inviteId: string): Promise<DeleteInviteResponse> => {
  const response = await callAPI(`/${inviteId}`, 'DELETE');

  return await handleJSONResponse(response);
};
