import * as actions from './actions';
import { LoadingAction } from '../common/loading-action';
import { ErrorAction } from '../common/error-action';
import { FunnelTemplate } from '../../../../backend/services/funnel-template/types/entities/funnel-template';
import { ListFunnelTemplateResponse } from '../../../../backend/services/funnel-template/functions/list-funnel-template/interface';
import { CreateFunnelTemplateBodyRequest } from '../../../../backend/services/funnel-template/functions/create-funnel-template/interface';
import { UpdateFunnelTemplateBodyRequest } from '../../../../backend/services/funnel-template/functions/update-funnel-template/interface';
import { DeleteFunnelTemplateResponse } from '../../../../backend/services/funnel-template/functions/delete-funnel-template/interface';

export class ListFunnelTemplate implements LoadingAction {
  readonly type = actions.LIST_FUNNEL_TEMPLATE;
  readonly isLoading = true;
  readonly mainLoader = true;
}

export class ListFunnelTemplateSuccess implements LoadingAction {
  readonly type = actions.LIST_FUNNEL_TEMPLATE_SUCCESS;
  readonly isLoading = false;

  constructor(public results: ListFunnelTemplateResponse) {}
}

export class ListFunnelTemplateError implements ErrorAction {
  readonly type = actions.LIST_FUNNEL_TEMPLATE_ERROR;
  readonly isError = true;

  constructor(public code: string, public description: string | string[]) {}
}

export class CreateFunnelTemplate implements LoadingAction {
  readonly type = actions.CREATE_FUNNEL_TEMPLATE;
  readonly isLoading = true;
  readonly mainLoader = true;

  constructor(public newFunnelTemplate: CreateFunnelTemplateBodyRequest) {}
}

export class CreateFunnelTemplateSuccess implements LoadingAction {
  readonly type = actions.CREATE_FUNNEL_TEMPLATE_SUCCESS;
  readonly isLoading = false;

  constructor(public funnelTemplate: FunnelTemplate) {}
}

export class CreateFunnelTemplateError implements ErrorAction {
  readonly type = actions.CREATE_FUNNEL_TEMPLATE_ERROR;
  readonly isError = true;

  constructor(public code: string, public description: string | string[]) {}
}

export class UpdateFunnelTemplate implements LoadingAction {
  readonly type = actions.UPDATE_FUNNEL_TEMPLATE;
  readonly isLoading = true;
  readonly mainLoader = true;

  constructor(public funnelTemplateId: string, public funnelTemplate: UpdateFunnelTemplateBodyRequest) {}
}

export class UpdateFunnelTemplateSuccess implements LoadingAction {
  readonly type = actions.UPDATE_FUNNEL_TEMPLATE_SUCCESS;
  readonly isLoading = false;

  constructor(public funnelTemplate: FunnelTemplate) {}
}

export class UpdateFunnelTemplateError implements ErrorAction {
  readonly type = actions.UPDATE_FUNNEL_TEMPLATE_ERROR;
  readonly isError = true;

  constructor(public code: string, public description: string | string[]) {}
}

export class DeleteFunnelTemplate implements LoadingAction {
  readonly type = actions.DELETE_FUNNEL_TEMPLATE;
  readonly isLoading = true;
  readonly mainLoader = true;

  constructor(public funnelTemplateId: string) {}
}

export class DeleteFunnelTemplateSuccess implements LoadingAction {
  readonly type = actions.DELETE_FUNNEL_TEMPLATE_SUCCESS;
  readonly isLoading = false;

  constructor(public funnelTemplateId: string, public result: DeleteFunnelTemplateResponse) {}
}

export class DeleteFunnelTemplateError implements ErrorAction {
  readonly type = actions.DELETE_FUNNEL_TEMPLATE_ERROR;
  readonly isError = true;

  constructor(public code: string, public description: string | string[]) {}
}

export type FunnelTemplateActions =
  | ListFunnelTemplate
  | ListFunnelTemplateSuccess
  | ListFunnelTemplateError
  | CreateFunnelTemplate
  | CreateFunnelTemplateSuccess
  | CreateFunnelTemplateError
  | UpdateFunnelTemplate
  | UpdateFunnelTemplateSuccess
  | UpdateFunnelTemplateError
  | DeleteFunnelTemplate
  | DeleteFunnelTemplateSuccess
  | DeleteFunnelTemplateError;
