import React, { useState } from 'react';
import moment from 'moment';

import { Space, Table, Tooltip } from 'antd';
import { EditOutlined, DeleteOutlined, LoadingOutlined, NotificationOutlined, CopyOutlined } from '@ant-design/icons';
import ScenarioIcon from '../icons/scenario';

import './styles.less';
import { FunnelType } from '../../../../backend/services/funnel-type/types/entities/funnel-type';
import CampaignEditorModal from '../modal/campaign-editor';
import { Scenario } from '../../../../backend/services/scenario/types/entities/scenario';
import ScenarioEditorModal from '../modal/scenario-editor';
import { CreateCampaignBodyRequest } from '@backend/services/campaign/functions/create-campaign/interface';
import { Campaign } from '@backend/services/campaign/types/entities/campaign';

interface FunnelTypesTableProps {
  className?: string;
  isDeleting?: boolean;
  isFunnelTemplate?: boolean;
  funnelTypes: FunnelType[];
  onEdit: (funnelType: FunnelType) => void;
  onDelete: (funnelType: FunnelType) => void;
  onClone: (funnelType: FunnelType) => void;
}

export default function FunnelTypesTable({
  className,
  isDeleting,
  isFunnelTemplate,
  funnelTypes,
  onEdit,
  onDelete,
  onClone,
}: FunnelTypesTableProps): JSX.Element {
  const ActionsCell = (props: {
    funnelType: FunnelType;
    onEdit: () => void;
    onDelete: () => void;
    onClone: () => void;
  }): JSX.Element => (
    <Space size="large">
      {!isFunnelTemplate ? (
        <>
          <Tooltip title="New Campaign">
            <NotificationOutlined
              className="table__funneltypes__icon gray"
              onClick={() => newCampaign(props.funnelType.id)}
            />
          </Tooltip>
          <Tooltip title="New Scenario">
            <span role="img" aria-label="scenario" className="table__funneltypes__icon gray">
              <ScenarioIcon onClick={() => newScenario(props.funnelType.id)} />
            </span>
          </Tooltip>
        </>
      ) : null}
      <Tooltip title="Edit">
        <EditOutlined className="table__funneltypes__icon gray" onClick={() => props.onEdit()} />
      </Tooltip>
      <Tooltip title="Clone">
        <CopyOutlined className="table__funneltypes__icon gray" onClick={() => props.onClone()} />
      </Tooltip>
      <Tooltip title={isDeleting ? 'Deleting' : 'Delete'}>
        {isDeleting ? (
          <LoadingOutlined className="table__funneltypes__icon red" />
        ) : (
          <DeleteOutlined className="table__funneltypes__icon red" onClick={() => props.onDelete()} />
        )}
      </Tooltip>
    </Space>
  );

  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Stages',
      dataIndex: 'stages',
      key: 'stages',
      render: (value: string[]) => value.length,
    },
    {
      title: 'Campaigns',
      dataIndex: 'campaignRefNum',
      key: 'campaignRefNum',
    },
    {
      title: 'Forecasts',
      dataIndex: 'forecastRefNum',
      key: 'forecastRefNum',
    },
    {
      title: 'Scenarios',
      dataIndex: 'scenarioRefNum',
      key: 'scenarioRefNum',
    },
    {
      title: 'Created Date',
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: (value: string) => moment(value).format('LL'),
    },
    {
      title: 'Actions',
      dataIndex: 'actions',
      key: 'actions',
      render: (_: undefined, funnelType: FunnelType) => (
        <ActionsCell
          funnelType={funnelType}
          onEdit={() => onEdit(funnelType)}
          onDelete={() => onDelete(funnelType)}
          onClone={() => onClone(funnelType)}
        />
      ),
    },
  ];

  const [isCampaignModalVisible, setIsCampaignModalVisible] = useState<Partial<Campaign>>();
  const [isScenarioModalVisible, setIsScenarioModalVisible] = useState<string>();

  const newCampaign = (funnelTypeId: string) => {
    const funnel = funnelTypes.find((f) => f.id === funnelTypeId);
    if (!funnel) {
      return;
    }
    const campaign: Partial<CreateCampaignBodyRequest> = {
      costStage: { name: funnel.costStage },
      valueStage: { name: funnel.valueStage },
      funnelTypeId,
    };
    setIsCampaignModalVisible(campaign as Campaign);
  };

  const newScenario = (funneltypeId: string) => {
    setIsScenarioModalVisible(funneltypeId);
  };

  return (
    <div className={`${className || ''} table__funneltypes`}>
      <Table<FunnelType> rowKey={(f) => f.id} columns={columns} dataSource={funnelTypes} />
      {isCampaignModalVisible ? (
        <CampaignEditorModal
          funnelTypes={funnelTypes}
          campaign={isCampaignModalVisible}
          isModalVisible={!!isCampaignModalVisible}
          handleClose={() => setIsCampaignModalVisible(undefined)}
        />
      ) : null}
      {isScenarioModalVisible ? (
        <ScenarioEditorModal
          funnelTypes={funnelTypes}
          scenario={{ funnelTypeId: isScenarioModalVisible } as Scenario}
          isModalVisible={!!isScenarioModalVisible}
          handleClose={() => setIsScenarioModalVisible(undefined)}
        />
      ) : null}
    </div>
  );
}
