import React from 'react';
import './styles.less';

export default function Header({
  children,
  className,
}: {
  children: React.ReactNode;
  className?: string;
}): JSX.Element {
  return (
    <div className={`${className || ''} header`}>
      <div className="header__content">{children}</div>
    </div>
  );
}
