import { combineEpics } from 'redux-observable';
import { listClientEpic } from './list-client';
import { listClientAfterLoginEpic } from './list-client-after-login';
import { createClientEpic } from './create-client';
import { updateClientEpic } from './update-client';
import { setActiveAfterListEpic } from './set-active-after-list';
import { setActiveOnRequestEpic } from './set-active-on-request';
import { deleteClientEpic } from './delete-client';

const clientEpic = combineEpics(
  listClientEpic,
  listClientAfterLoginEpic,
  createClientEpic,
  updateClientEpic,
  setActiveAfterListEpic,
  setActiveOnRequestEpic,
  deleteClientEpic
);

export default clientEpic;
