import { Observable } from 'rxjs';
import { ofType, StateObservable } from 'redux-observable';
import { map, withLatestFrom, filter, debounceTime } from 'rxjs/operators';
import { CallHistoryMethodAction, LocationChangeAction, LOCATION_CHANGE, replace } from 'connected-react-router';
import AppState from '../../state';
// import { isPublicRoute } from '../../history/common';
import * as auth from '@webapp/sdk/auth';

export const authGuardEpic = (
  action$: Observable<LocationChangeAction>,
  state$: StateObservable<AppState>
): Observable<CallHistoryMethodAction> =>
  action$.pipe(
    ofType<LocationChangeAction>(LOCATION_CHANGE),
    debounceTime(100),
    withLatestFrom(state$, auth.isLoggedIn()),
    map((values) => values[1]),
    filter((state) => {
      if (state.auth.loggedIn) {
        return false;
      }

      // if (!isLoggedIn) {
      //   return !isPublicRoute(state.router.location.pathname);
      // }

      return false;
    }),
    map(() => replace('/login'))
  );
