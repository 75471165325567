import React from 'react';
import './styles.less';

interface SeparatorProps {
  className?: string;
}

export default function Separator({ className }: SeparatorProps): JSX.Element {
  return <hr className={`${className || ''} separator`} />;
}
