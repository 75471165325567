import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Form, Input, Button, notification } from 'antd';
// import Checkbox from 'antd/lib/checkbox/Checkbox';

import FormWrapper from './form-wrapper';

import { CreateAccount } from '../../store/auth/action-creators';
import BasicAccountData from '../../types/auth/basic-account-data';
import AppState from '@webapp/store/state';

import { CREATE_ACCOUNT } from '@webapp/store/auth/actions';
import { Invite } from '../../../../backend/services/invite/types/entities/invite';

interface AcceptInviteFormData extends BasicAccountData {
  password2: string;
}

interface AcceptInviteFormProps {
  invite: Invite;
}

export default function AcceptInviteForm({ invite }: AcceptInviteFormProps): JSX.Element {
  const dispatch = useDispatch();

  const loading = useSelector((state: AppState) => !!state.ui.loading[CREATE_ACCOUNT]);

  const onFinish = (values: AcceptInviteFormData) => {
    const { password2, ...account } = values;

    if (account.password !== password2) {
      notification.error({
        message: 'Error',
        description: 'Passwords do not match',
      });

      return;
    }

    dispatch(new CreateAccount(account));
  };

  return (
    <FormWrapper title="Accept invite">
      <Form name="basic" layout="vertical" onFinish={onFinish}>
        <Form.Item className="form__wrapper__item" name="given_name" initialValue={invite.givenName}>
          <Input defaultValue={invite.givenName} disabled size="large" />
        </Form.Item>

        <Form.Item className="form__wrapper__item" name="family_name" initialValue={invite.familyName}>
          <Input defaultValue={invite.familyName} disabled size="large" />
        </Form.Item>

        <Form.Item className="form__wrapper__item" name="email" initialValue={invite.email}>
          <Input defaultValue={invite.email} disabled size="large" />
        </Form.Item>

        <Form.Item
          className="form__wrapper__item"
          name="password"
          rules={[{ required: true, message: 'Please input your password!' }]}
        >
          <Input.Password size="large" placeholder="Password" />
        </Form.Item>

        <Form.Item
          className="form__wrapper__item"
          name="password2"
          rules={[{ required: true, message: 'Please input your password!' }]}
        >
          <Input.Password size="large" placeholder="Confirm Password" />
        </Form.Item>

        <Form.Item className="form__wrapper__button">
          <Button type="primary" htmlType="submit" size="large" loading={loading}>
            Accept Invite
          </Button>
        </Form.Item>
      </Form>
    </FormWrapper>
  );
}
