import React from 'react';
import './styles.less';

import { Typography, Modal, Space } from 'antd';
import { CloseCircleOutlined } from '@ant-design/icons';

interface CustomTextModalProps {
  className?: string;
  title: string;
  text: string;
  button?: React.ReactNode;
  isModalVisible: boolean;
  handleClose: () => void;
}

export default function CustomTextModal({
  className,
  title,
  text,
  button,
  isModalVisible,
  handleClose,
}: CustomTextModalProps): JSX.Element {
  return (
    <div className={`${className || ''} modal`}>
      <Modal
        className="modal__wrapper"
        visible={isModalVisible}
        maskClosable={true}
        centered={true}
        footer={null}
        width={1000}
        closeIcon={<CloseCircleOutlined onClick={handleClose} className="modal__close" />}
      >
        <Space direction="vertical" size="large" align="center" className="modal__custom__space">
          <Typography.Title className="modal__wrapper__title has-subtitle" level={3}>
            {title}
          </Typography.Title>

          <Typography.Text className="modal__wrapper__text">{text}</Typography.Text>

          {button ? button : null}
        </Space>
      </Modal>
    </div>
  );
}
