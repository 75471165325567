import AppState from '../state';
import {
  Subscription,
  SubscriptionStatus,
} from '../../../../backend/services/subscription/types/entities/subscription';
import moment, { Moment } from 'moment';

export const selectCurrentSubscription = (state: AppState): Subscription | undefined => state.subscriptions.current;

export const trialExpirationDate = (state: AppState): moment.Moment | undefined => {
  const subscription = selectCurrentSubscription(state);

  if (subscription?.status === 'trialing') {
    const createdAt = moment(subscription.createdAt);

    return createdAt.add(Number(subscription.trialDaysDuration), 'days');
  }
};

export const subscriptionStatus = (state: AppState): SubscriptionStatus | undefined =>
  selectCurrentSubscription(state)?.status;

export const isSubscriptionTrial = (state: AppState): boolean => subscriptionStatus(state) === 'trialing';
export const isSubscriptionActive = (state: AppState): boolean => subscriptionStatus(state) === 'active';
export const isSubscriptionCancelled = (state: AppState): boolean => subscriptionStatus(state) === 'canceled';

export const getSubscriptionEndDate = (state: AppState): Moment | undefined => {
  const subscription = selectCurrentSubscription(state);

  if (subscription?.cancelAtEnd && subscription.currentPeriodEnd) {
    return moment(subscription.currentPeriodEnd);
  }
};
