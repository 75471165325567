import React from 'react';
import './styles.less';

import { Menu, Typography } from 'antd';

interface MenuItems {
  title: string;
  onClick?: () => void;
  isDanger?: boolean;
  icon?: React.ReactNode;
}

export default function UserMenuItems({ className, items }: { className?: string; items: MenuItems[] }): JSX.Element {
  return (
    <Menu className={`${className || ''} menu`}>
      {items.map((item, i) => (
        <Menu.Item className={`menu__item ${item.isDanger ? 'danger' : ''}`} key={i} onClick={item.onClick}>
          <span className="menu__item__icon">{item.icon}</span>
          <Typography.Paragraph className="menu__item__text">{item.title}</Typography.Paragraph>
        </Menu.Item>
      ))}
    </Menu>
  );
}
