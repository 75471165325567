import { combineEpics } from 'redux-observable';
import { createInviteEpic } from './create-invite';
import { listInviteEpic } from './list-invites';
import { listInviteAfterLoginEpic } from './list-invites-after-login';
import { deleteInviteEpic } from './delete-invite';
import { getInviteEpic } from './get-invite';
import { resendInviteEpic } from './resend-invite';

const inviteEpic = combineEpics(
  listInviteEpic,
  listInviteAfterLoginEpic,
  createInviteEpic,
  deleteInviteEpic,
  getInviteEpic,
  resendInviteEpic
);

export default inviteEpic;
