import * as actions from './actions';
import { LoadingAction } from '../common/loading-action';
import { ErrorAction } from '../common/error-action';
import { ListProductResponse } from '../../../../backend/services/product/functions/list-product/interface';

export class ListProducts implements LoadingAction {
  readonly type = actions.LIST_PRODUCTS;
  readonly isLoading = true;
  readonly mainLoader = true;
}

export class ListProductsSuccess implements LoadingAction {
  readonly type = actions.LIST_PRODUCTS_SUCCESS;
  readonly isLoading = false;

  constructor(public products: ListProductResponse) {}
}

export class ListProductsError implements ErrorAction {
  readonly type = actions.LIST_PRODUCTS_ERROR;
  readonly isError = true;

  constructor(public code: string, public description: string | string[]) {}
}

export type ProductActions = ListProducts | ListProductsSuccess | ListProductsError;
