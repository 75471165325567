import { Observable } from 'rxjs';
import { map, delay } from 'rxjs/operators';
import { DestroyDialog, CloseDialog } from '../action-creators';
import { ofType } from 'redux-observable';
import { CLOSE_DIALOG } from '../actions';
import { Action } from 'redux';

export const destroyDialogEpic = (action$: Observable<Action>): Observable<DestroyDialog> =>
  action$.pipe(
    ofType<Action, CloseDialog>(CLOSE_DIALOG),
    delay(300),
    map((action) => new DestroyDialog(action.key))
  );
