export const LIST_FUNNEL_TEMPLATE = '[Template] List template';
export const LIST_FUNNEL_TEMPLATE_SUCCESS = '[Template] List template Success';
export const LIST_FUNNEL_TEMPLATE_ERROR = '[Template] List template Error';

export const CREATE_FUNNEL_TEMPLATE = '[Template] Create template';
export const CREATE_FUNNEL_TEMPLATE_SUCCESS = '[Template] Create template Success';
export const CREATE_FUNNEL_TEMPLATE_ERROR = '[Template] Create template Error';

export const UPDATE_FUNNEL_TEMPLATE = '[Template] Update template';
export const UPDATE_FUNNEL_TEMPLATE_SUCCESS = '[Template] Update template Success';
export const UPDATE_FUNNEL_TEMPLATE_ERROR = '[Template] Update template Error';

export const DELETE_FUNNEL_TEMPLATE = '[Template] Delete template';
export const DELETE_FUNNEL_TEMPLATE_SUCCESS = '[Template] Delete template Success';
export const DELETE_FUNNEL_TEMPLATE_ERROR = '[Template] Delete template Error';
