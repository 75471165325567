import * as actions from './actions';
import { LoadingAction } from '../common/loading-action';
import { ErrorAction } from '../common/error-action';
import { FunnelType } from '../../../../backend/services/funnel-type/types/entities/funnel-type';
import { ListFunnelTypeResponse } from '../../../../backend/services/funnel-type/functions/list-funnel-type/interface';
import { CreateFunnelTypeBodyRequest } from '../../../../backend/services/funnel-type/functions/create-funnel-type/interface';
import { UpdateFunnelTypeBodyRequest } from '../../../../backend/services/funnel-type/functions/update-funnel-type/interface';
import { DeleteFunnelTypeResponse } from '../../../../backend/services/funnel-type/functions/delete-funnel-type/interface';
export class ListFunnelType implements LoadingAction {
  readonly type = actions.LIST_FUNNEL_TYPE;
  readonly isLoading = true;
  readonly mainLoader = true;

  constructor(public clientId: string) {}
}

export class ListFunnelTypeSuccess implements LoadingAction {
  readonly type = actions.LIST_FUNNEL_TYPE_SUCCESS;
  readonly isLoading = false;

  constructor(public results: ListFunnelTypeResponse) {}
}

export class ListFunnelTypeError implements ErrorAction {
  readonly type = actions.LIST_FUNNEL_TYPE_ERROR;
  readonly isError = true;

  constructor(public code: string, public description: string | string[]) {}
}

export class CreateFunnelType implements LoadingAction {
  readonly type = actions.CREATE_FUNNEL_TYPE;
  readonly isLoading = true;
  readonly mainLoader = true;

  constructor(public newFunnelType: CreateFunnelTypeBodyRequest) {}
}

export class CreateFunnelTypeSuccess implements LoadingAction {
  readonly type = actions.CREATE_FUNNEL_TYPE_SUCCESS;
  readonly isLoading = false;

  constructor(public funnelType: FunnelType) {}
}

export class CreateFunnelTypeError implements ErrorAction {
  readonly type = actions.CREATE_FUNNEL_TYPE_ERROR;
  readonly isError = true;

  constructor(public code: string, public description: string | string[]) {}
}

export class UpdateFunnelType implements LoadingAction {
  readonly type = actions.UPDATE_FUNNEL_TYPE;
  readonly isLoading = true;
  readonly mainLoader = true;

  constructor(public funnelTypeId: string, public funnelType: UpdateFunnelTypeBodyRequest) {}
}

export class UpdateFunnelTypeSuccess implements LoadingAction {
  readonly type = actions.UPDATE_FUNNEL_TYPE_SUCCESS;
  readonly isLoading = false;

  constructor(public funnelType: FunnelType) {}
}

export class UpdateFunnelTypeError implements ErrorAction {
  readonly type = actions.UPDATE_FUNNEL_TYPE_ERROR;
  readonly isError = true;

  constructor(public code: string, public description: string | string[]) {}
}

export class DeleteFunnelType implements LoadingAction {
  readonly type = actions.DELETE_FUNNEL_TYPE;
  readonly isLoading = true;
  readonly mainLoader = true;

  constructor(public funnelTypeId: string) {}
}

export class DeleteFunnelTypeSuccess implements LoadingAction {
  readonly type = actions.DELETE_FUNNEL_TYPE_SUCCESS;
  readonly isLoading = false;

  constructor(public funnelTypeId: string, public result: DeleteFunnelTypeResponse) {}
}

export class DeleteFunnelTypeError implements ErrorAction {
  readonly type = actions.DELETE_FUNNEL_TYPE_ERROR;
  readonly isError = true;

  constructor(public code: string, public description: string | string[]) {}
}

export type FunnelTypeActions =
  | ListFunnelType
  | ListFunnelTypeSuccess
  | ListFunnelTypeError
  | CreateFunnelType
  | CreateFunnelTypeSuccess
  | CreateFunnelTypeError
  | UpdateFunnelType
  | UpdateFunnelTypeSuccess
  | UpdateFunnelTypeError
  | DeleteFunnelType
  | DeleteFunnelTypeSuccess
  | DeleteFunnelTypeError;
