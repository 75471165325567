import React from 'react';
import './styles.less';

import { Divider, Space, Typography } from 'antd';

import { formatCurrency } from '@webapp/utils/format-currency';

interface StatisticsProps {
  className?: string;
  budget: number;
  revenue: number;
  ROI: number;
}

export default function Statistics({ className, budget, revenue, ROI }: StatisticsProps): JSX.Element {
  const getRoiPercentage = (ROI: number) => {
    return `${Math.round(ROI * 100)}%`;
  };

  return (
    <div className={`${className || ''} statistics`}>
      <div className="statistics__form">
        <Space
          className="statistics__form__wrapper"
          align="center"
          size="large"
          split={<Divider type="vertical" />}
          style={{ justifyContent: 'space-between', width: '100%' }}
        >
          <Space align="center" size="large">
            <Typography className="statistics__form__item__title budget">Budget</Typography>
            <div className="statistics__form__item__value-warpper">
              <Typography className="statistics__form__item__value-warpper__text">{formatCurrency(budget)}</Typography>
            </div>
          </Space>
          <Space align="center" size="large">
            <Typography className="statistics__form__item__title revenue">Revenue</Typography>
            <div className="statistics__form__item__value-warpper">
              <Typography className="statistics__form__item__value-warpper__text">{formatCurrency(revenue)}</Typography>
            </div>
          </Space>
          <Space align="center" size="large">
            <Typography className="statistics__form__item__title">ROI</Typography>
            <div className="statistics__form__item__value-warpper">
              <Typography className="statistics__form__item__value-warpper__text">{getRoiPercentage(ROI)}</Typography>
            </div>
          </Space>
        </Space>
      </div>
    </div>
  );
}
