import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';
import { ofType } from 'redux-observable';
import { Action } from 'redux';
import { LOCATION_CHANGE } from 'connected-react-router';

export const scrollTopEpic = (action$: Observable<Action>): Observable<void> =>
  action$.pipe(
    ofType(LOCATION_CHANGE),
    map(() => {
      window.scrollTo(0, 1);
    }),
    filter(() => false)
  );
