import React from 'react';

import { Space, Table, Tooltip, Typography } from 'antd';

import { EyeOutlined, EditOutlined, DeleteOutlined, LoadingOutlined, CopyOutlined } from '@ant-design/icons';

import './styles.less';
import { ColumnsType } from 'antd/lib/table';

interface ActionsTableProps<T> {
  className?: string;
  title: string;
  data: T[];
  dataColumns: ColumnsType<T>;
  icon?: React.ReactNode;
  isDeleting?: boolean;
  keyMapper: (value: T) => string;
  handleView: (value: T) => void;
  handleEdit: (value: T) => void;
  handleDelete: (value: T) => void;
  handleClone: (value: T) => void;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export default function ActionsTable<T extends Record<string, any>>({
  className,
  title,
  data,
  dataColumns,
  icon,
  isDeleting,
  handleView,
  handleEdit,
  handleDelete,
  handleClone,
  keyMapper,
}: ActionsTableProps<T>): JSX.Element {
  const ActionsCell = (
    props: {
      handleView: (value: T) => void;
      handleEdit: (value: T) => void;
      handleDelete: (value: T) => void;
      handleClone: (value: T) => void;
    },
    value: T
  ): JSX.Element => (
    <Space size="large">
      <Tooltip title="View">
        <EyeOutlined className="table__actions__icon gray" onClick={() => props.handleView(value)} />
      </Tooltip>
      <Tooltip title="Edit">
        <EditOutlined className="table__actions__icon gray" onClick={() => props.handleEdit(value)} />
      </Tooltip>
      <Tooltip title="Clone">
        <CopyOutlined className="table__actions__icon gray" onClick={() => props.handleClone(value)} />
      </Tooltip>
      <Tooltip title={isDeleting ? 'Deleting' : 'Delete'}>
        {isDeleting ? (
          <LoadingOutlined className="table__actions__icon red" />
        ) : (
          <DeleteOutlined className="table__actions__icon red" onClick={() => props.handleDelete(value)} />
        )}
      </Tooltip>
    </Space>
  );

  const columns: ColumnsType<T> = [
    {
      title: 'Actions',
      dataIndex: 'actions',
      key: 'actions',
      render: (_, value) => (
        <ActionsCell
          handleView={() => handleView(value)}
          handleEdit={() => handleEdit(value)}
          handleDelete={() => handleDelete(value)}
          handleClone={() => handleClone(value)}
        />
      ),
    },
  ];

  return (
    <div className={`${className || ''} table__actions`}>
      <Space className="title-wrapper">
        <div className="icon">{icon ? icon : null}</div>
        <Typography.Title level={3}>{title}</Typography.Title>
      </Space>
      <Table<T>
        columns={[...dataColumns, ...columns]}
        dataSource={data.map((d) => ({
          ...d,
          key: keyMapper(d),
        }))}
        pagination={false}
      />
    </div>
  );
}
