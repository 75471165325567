import { Observable } from 'rxjs';
import { ofType, StateObservable } from 'redux-observable';
import { mergeMap, withLatestFrom } from 'rxjs/operators';
import * as campaigns from '../../../sdk/campaigns';
import { Actions } from '../../actions';
import AppState from '../../state';
import { UpdateCampaign, UpdateCampaignSuccess, UpdateCampaignError } from '../action-creators';
import { UPDATE_CAMPAIGN } from '../actions';
import { NoOp } from '../../ui/action-creators';
import ApiError from '../../../../../backend/commons/utils/api/api-error';

export const updateCampaignEpic = (
  action$: Observable<Actions>,
  state$: StateObservable<AppState>
): Observable<Actions> =>
  action$.pipe(
    ofType<Actions, UpdateCampaign>(UPDATE_CAMPAIGN),
    withLatestFrom(state$),
    mergeMap(async ([action, state]) => {
      if (!state.client.active?.id) {
        return new NoOp();
      }

      try {
        const funnelType = await campaigns.update(state.client.active.id, action.campaignId, action.campaign);

        return new UpdateCampaignSuccess(funnelType);
      } catch (err) {
        return new UpdateCampaignError((err as ApiError).message, (err as ApiError).description || '');
      }
    })
  );
