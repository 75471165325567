import { FunnelTemplateState } from './state';
import { funnelTemplateInitialState } from './initial-state';
import { FunnelTemplateActions } from './action-creators';
import * as actions from './actions';

export const funnelTemplateReducer = (
  state: FunnelTemplateState = funnelTemplateInitialState,
  action: FunnelTemplateActions
): FunnelTemplateState => {
  switch (action.type) {
    case actions.LIST_FUNNEL_TEMPLATE_SUCCESS: {
      return {
        ...state,
        items: action.results.items || [],
        nextToken: action.results.nextToken,
      };
    }

    case actions.CREATE_FUNNEL_TEMPLATE_SUCCESS: {
      return {
        ...state,
        items: state.items.concat([action.funnelTemplate]),
      };
    }

    case actions.UPDATE_FUNNEL_TEMPLATE_SUCCESS: {
      return {
        ...state,
        items: state.items.map((c) => {
          if (c.id === action.funnelTemplate.id) {
            return action.funnelTemplate;
          }
          return { ...c };
        }),
      };
    }

    case actions.DELETE_FUNNEL_TEMPLATE_SUCCESS: {
      return {
        ...state,
        items: state.items.filter((c) => c.id !== action.funnelTemplateId),
      };
    }

    default: {
      return { ...state };
    }
  }
};
