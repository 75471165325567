import { Observable } from 'rxjs';
import { ofType } from 'redux-observable';
import { mergeMap } from 'rxjs/operators';
import * as templatetypes from '../../../sdk/funnel-templates';
import { Actions } from '../../actions';
import { ListFunnelTemplate, ListFunnelTemplateSuccess, ListFunnelTemplateError } from '../action-creators';
import { LIST_FUNNEL_TEMPLATE } from '../actions';
import ApiError from '../../../../../backend/commons/utils/api/api-error';

export const listFunnelTemplateEpic = (action$: Observable<Actions>): Observable<Actions> =>
  action$.pipe(
    ofType<Actions, ListFunnelTemplate>(LIST_FUNNEL_TEMPLATE),
    mergeMap(async () => {
      try {
        const funnelTemplateList = await templatetypes.list();

        return new ListFunnelTemplateSuccess(funnelTemplateList);
      } catch (err) {
        return new ListFunnelTemplateError((err as ApiError).message, (err as ApiError).description || '');
      }
    })
  );
