import React from 'react';

interface ImageProps extends React.DetailedHTMLProps<React.ImgHTMLAttributes<HTMLImageElement>, HTMLImageElement> {
  maxRetries?: number;
}

export const Image: React.FC<ImageProps> = ({ maxRetries = 5, src, ...props }) => {
  const [localSrc, setLocalSrc] = React.useState(src);
  const [retries, setRetries] = React.useState(0);

  const handleError = () => {
    if (retries < maxRetries) {
      setRetries(retries + 1);
      setLocalSrc(undefined);
    }
  };

  React.useEffect(() => {
    if (src !== localSrc) {
      const timer = setTimeout(() => setLocalSrc(src), 500);

      return () => clearTimeout(timer);
    }
  }, [localSrc, src]);

  return <img {...props} onError={handleError} src={localSrc} />;
};
