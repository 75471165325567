import { Observable } from 'rxjs';
import { ofType, StateObservable } from 'redux-observable';
import { map, withLatestFrom, filter } from 'rxjs/operators';
import { Actions } from '../../actions';
import { SetActiveClient } from '../action-creators';
import { NoOp } from '../../ui/action-creators';
import AppState from '../../state';
import { GetCampaign } from '../../campaign/action-creators';
import { GetForecast } from '../../forecast/action-creators';
import { GetScenario } from '../../scenario/action-creators';
import { GET_CAMPAIGN } from '../../campaign/actions';
import { GET_FORECAST } from '../../forecast/actions';
import { GET_SCENARIO } from '../../scenario/actions';

export const setActiveOnRequestEpic = (
  action$: Observable<Actions>,
  state$: StateObservable<AppState>
): Observable<Actions> =>
  action$.pipe(
    ofType<Actions, GetCampaign | GetForecast | GetScenario>(GET_CAMPAIGN, GET_FORECAST, GET_SCENARIO),
    withLatestFrom(state$),
    filter(([action, state]) => state.client.active?.id !== action.clientId),
    map(([action, state]) => {
      const client = state.client.list.items.find((i) => i.id === action.clientId);

      if (client) {
        return new SetActiveClient(client);
      }

      return new NoOp();
    })
  );
