import { Observable } from 'rxjs';
import { ofType } from 'redux-observable';
import { mergeMap } from 'rxjs/operators';
import * as campaigns from '../../../sdk/campaigns';
import { Actions } from '../../actions';
import { GetCampaign, GetCampaignSuccess, GetCampaignError } from '../action-creators';
import { GET_CAMPAIGN } from '../actions';
import ApiError from '../../../../../backend/commons/utils/api/api-error';

export const getCampaignEpic = (action$: Observable<Actions>): Observable<Actions> =>
  action$.pipe(
    ofType<Actions, GetCampaign>(GET_CAMPAIGN),
    mergeMap(async (action) => {
      try {
        const campaign = await campaigns.get(action.clientId, action.campaignId);

        return new GetCampaignSuccess(campaign);
      } catch (err) {
        return new GetCampaignError((err as ApiError).message, (err as ApiError).description || '');
      }
    })
  );
