import { combineEpics } from 'redux-observable';
import { createScenarioEpic } from './create-scenario';
import { updateScenarioEpic } from './update-scenario';
import { listScenarioEpic } from './list-scenarios';
import { listScenarioAfterActiveEpic } from './list-scenarios-after-active';
import { deleteScenarioEpic } from './delete-scenario';
import { getScenarioEpic } from './get-scenario';

const scenarioEpic = combineEpics(
  listScenarioEpic,
  listScenarioAfterActiveEpic,
  createScenarioEpic,
  updateScenarioEpic,
  deleteScenarioEpic,
  getScenarioEpic
);

export default scenarioEpic;
