import React from 'react';
import './styles.less';

import { Button, Card, Typography } from 'antd';

import { CheckCircleOutlined } from '@ant-design/icons';
import { Product } from '../../../../backend/services/product/types/entities/product';
import { Price } from '../../../../backend/services/product/types/entities/price';
import { formatCurrency } from '../../utils/format-currency';

interface PlanCardProps {
  className?: string;
  product: Product;
  activeFrequency: string;
  onSelected: (plan: Product) => void;
}

export default function PlanCard({ className, product, activeFrequency, onSelected }: PlanCardProps): JSX.Element {
  const getPrice = (prices: Price[]): Price | undefined => prices.find((p) => p.frequency === activeFrequency);

  // const getOriginalPrice = (prices: Price[]): string => {
  //   const price = getPrice(prices);
  //   if (price?.originalValue) {
  //     return formatCurrency(price.originalValue / 100);
  //   }

  //   return '-';
  // };

  const getCurrentPrice = (prices: Price[]): string | undefined => {
    const price = getPrice(prices);
    if (price?.flatValue) {
      return formatCurrency(price.flatValue / 100);
    }

    return '-';
  };

  return (
    <div className={`${className || ''} plan`}>
      <Card className="plan__card" key={`${product.id}`}>
        <div className="plan__card__wrapper">
          <Typography.Title className="plan__card__wrapper__title" level={2}>
            {product.name}
          </Typography.Title>
          <div className="plan__card__wrapper__price">
            {/* <Typography.Title
              className="plan__card__wrapper__price__original"
              level={3}
              delete
            >
              {getOriginalPrice(product.prices)}
            </Typography.Title> */}
            <Typography.Title className="plan__card__wrapper__price__final">
              {getCurrentPrice(product.prices)}
            </Typography.Title>
          </div>
          <div className="plan__card__wrapper__features">
            {product.features?.map((feature, i) => (
              <div className="plan__card__wrapper__features__item" key={`feature-${i}`}>
                <CheckCircleOutlined height={18} width={18} className="plan__card__wrapper__features__item__icon" />
                <Typography.Paragraph key={i} className="plan__card__wrapper__features__item__text">
                  {feature}
                </Typography.Paragraph>
              </div>
            ))}
          </div>
          <div className="plan__card__wrapper__extras">
            {product.extras?.map((extra, i) => (
              <Typography.Text key={i} className="plan__card__wrapper__features__extra">
                {extra}
              </Typography.Text>
            ))}
          </div>
        </div>
        <Button className="plan__card__wrapper__button" type="primary" size="large" onClick={() => onSelected(product)}>
          Select Plan
        </Button>
      </Card>
    </div>
  );
}
