import React from 'react';
import './styles.less';

import { Button } from 'antd';

interface ToggleOptions {
  label: string;
  value: string;
}

interface ToggleProps {
  className?: string;
  options: ToggleOptions[];
  active: string;
  onChange: (value: string) => void;
}

export default function Toggle({ className, options, active, onChange }: ToggleProps): JSX.Element {
  return (
    <div className="toggle">
      {options.map((option, i) => (
        <Button
          key={`${option.value}-${i}`}
          className={`${className} toggle__button ${active === option.value ? 'active' : ''}`}
          type="primary"
          size="large"
          onClick={() => onChange(option.value)}
        >
          {option.label}
        </Button>
      ))}
    </div>
  );
}
