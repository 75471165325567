import { Observable } from 'rxjs';
import { ofType, StateObservable } from 'redux-observable';
import { mergeMap, withLatestFrom } from 'rxjs/operators';
import * as forecasts from '../../../sdk/forecasts';
import { Actions } from '../../actions';
import AppState from '../../state';
import { UpdateForecast, UpdateForecastSuccess, UpdateForecastError } from '../action-creators';
import { UPDATE_FORECAST } from '../actions';
import { NoOp } from '../../ui/action-creators';
import ApiError from '../../../../../backend/commons/utils/api/api-error';

export const updateForecastEpic = (
  action$: Observable<Actions>,
  state$: StateObservable<AppState>
): Observable<Actions> =>
  action$.pipe(
    ofType<Actions, UpdateForecast>(UPDATE_FORECAST),
    withLatestFrom(state$),
    mergeMap(async ([action, state]) => {
      if (!state.client.active?.id) {
        return new NoOp();
      }

      try {
        const funnelType = await forecasts.update(state.client.active.id, action.forecastId, action.forecast);

        return new UpdateForecastSuccess(funnelType);
      } catch (err) {
        return new UpdateForecastError((err as ApiError).message, (err as ApiError).description || '');
      }
    })
  );
