import React from 'react';
import './styles.less';

import { Space } from 'antd';

import Page from '@webapp/components/layout/page';
import ProfileUserInfo from '@webapp/components/profile/user-info';
import ProfileChangePassword from '@webapp/components/profile/change-password';

export default function ProfilePage(): JSX.Element {
  return (
    <Page className="profile" noBg>
      <Space size="large" align="start" className="profile__wrapper">
        <ProfileUserInfo className="profile__block" />
        <ProfileChangePassword className="profile__block" />
      </Space>
    </Page>
  );
}
