import React from 'react';
import './styles.less';

import { Image } from '@webapp/components/image';

import { UserOutlined } from '@ant-design/icons';

export default function Avatar({
  className,
  userAvatar,
  userName,
}: {
  className?: string;
  userAvatar?: string;
  userName?: string;
}): JSX.Element {
  return (
    <div className={`${className || ''} avatar`}>
      {userAvatar ? (
        <Image className="avatar__img" src={userAvatar} alt={userName ? `${userName} avatar` : 'User Avatar'} />
      ) : (
        <UserOutlined className="avatar__icon" />
      )}
    </div>
  );
}
