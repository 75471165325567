import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';
import { StartLoading, FinishiLoading } from '../action-creators';
import { LoadingAction } from '../../common/loading-action';
import { ErrorAction } from '../../common/error-action';
import { Action } from 'redux';

export const startLoadingEpic = (action$: Observable<LoadingAction>): Observable<Action> =>
  action$.pipe(
    filter((action) => action.isLoading),
    map((action) => new StartLoading(action.type, action.mainLoader))
  );

export const endLoadingEpic = (action$: Observable<LoadingAction & ErrorAction>): Observable<Action> =>
  action$.pipe(
    filter((action) => action.isLoading === false || action.isError),
    map((action) => {
      const original = action.type.replace('Success', '').replace('Error', '').trim();

      return new FinishiLoading(original);
    })
  );
