import { Observable } from 'rxjs';
import { Action } from 'redux';
import { ofType } from 'redux-observable';
import { map, delay } from 'rxjs/operators';
import { Actions } from '../../actions';
import { UPDATE_SUBSCRIPTION_SUCCESS } from '../actions';
import { GetSubscription, UpdateSubscriptionSuccess } from '../action-creators';

export const getSubscriptionAfterUpdateEpic = (action$: Observable<Actions>): Observable<Action> =>
  action$.pipe(
    ofType<Actions, UpdateSubscriptionSuccess>(UPDATE_SUBSCRIPTION_SUCCESS),
    delay(1000),
    map(() => new GetSubscription())
  );
