import { CampaignState } from './state';
import { campaignInitialState } from './initial-state';
import { CampaignActions } from './action-creators';
import * as actions from './actions';

export const campaignReducer = (
  state: CampaignState = campaignInitialState,
  action: CampaignActions
): CampaignState => {
  switch (action.type) {
    case actions.LIST_CAMPAIGN_SUCCESS: {
      return {
        ...state,
        list: {
          items: action.results.items || [],
          nextToken: action.results.nextToken,
        },
      };
    }

    case actions.CREATE_CAMPAIGN_SUCCESS: {
      return {
        ...state,
        list: {
          ...state.list,
          items: state.list.items.concat([action.campaign]),
        },
      };
    }

    case actions.UPDATE_CAMPAIGN_SUCCESS: {
      return {
        ...state,
        list: {
          ...state.list,
          items: state.list.items.map((c) => {
            if (c.id === action.campaign.id) {
              return { ...action.campaign };
            }
            return { ...c };
          }),
        },
        active: state.active?.id === action.campaign.id ? { ...action.campaign } : state.active,
      };
    }

    case actions.DELETE_CAMPAIGN_SUCCESS: {
      return {
        ...state,
        list: {
          ...state.list,
          items: state.list.items.filter((c) => c.id !== action.campaignId),
        },
      };
    }

    case actions.GET_CAMPAIGN: {
      return {
        ...state,
        active: undefined,
      };
    }

    case actions.GET_CAMPAIGN_SUCCESS: {
      return {
        ...state,
        active: action.campaign,
      };
    }

    default: {
      return { ...state };
    }
  }
};
