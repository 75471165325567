import { Observable } from 'rxjs';
import { ofType } from 'redux-observable';
import { mergeMap } from 'rxjs/operators';
import * as campaigns from '../../../sdk/campaigns';
import { Actions } from '../../actions';
import { ListCampaign, ListCampaignSuccess, ListCampaignError } from '../action-creators';
import { LIST_CAMPAIGN } from '../actions';
import ApiError from '../../../../../backend/commons/utils/api/api-error';

export const listCampaignEpic = (action$: Observable<Actions>): Observable<Actions> =>
  action$.pipe(
    ofType<Actions, ListCampaign>(LIST_CAMPAIGN),
    mergeMap(async (action) => {
      try {
        const campaignList = await campaigns.list(action.clientId);

        return new ListCampaignSuccess(campaignList);
      } catch (err) {
        return new ListCampaignError((err as ApiError).message, (err as ApiError).description || '');
      }
    })
  );
