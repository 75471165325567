import { combineEpics } from 'redux-observable';
import { createFunnelTypeEpic } from './create-funnel-type';
import { updateFunnelTypeEpic } from './update-funnel-type';
import { listFunnelTypeEpic } from './list-funnel-types';
import { listFunnelTypeAfterActiveEpic } from './list-funnel-types-after-active';
import { deleteFunnelTypeEpic } from './delete-funnel-type';

const funnelTypeEpic = combineEpics(
  listFunnelTypeEpic,
  listFunnelTypeAfterActiveEpic,
  createFunnelTypeEpic,
  updateFunnelTypeEpic,
  deleteFunnelTypeEpic
);

export default funnelTypeEpic;
