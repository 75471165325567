import React, { useState } from 'react';
import './styles.less';

import { Button, Input, Space, Typography, Modal } from 'antd';
import { CloseCircleOutlined } from '@ant-design/icons';

import Form from 'antd/lib/form/Form';
import FormItem from 'antd/lib/form/FormItem';
import { useDispatch, useSelector } from 'react-redux';
import { CreateInvite } from '../../store/invite/action-creators';
import { formatCurrency } from '../../utils/format-currency';
import { selectCurrentSubscription } from '../../store/subscriptions/selectors';

interface MemberEditorModalProps {
  className?: string;
  isModalVisible: boolean;
  handleClose: () => void;
}

export default function MemberEditorModal({
  className,
  isModalVisible,
  handleClose,
}: MemberEditorModalProps): JSX.Element {
  const dispatch = useDispatch();

  const [firstName, setFirstName] = useState<string>('');
  const [lastName, setLastName] = useState<string>('');
  const [email, setEmail] = useState<string>('');

  const subscription = useSelector(selectCurrentSubscription);
  const price = formatCurrency((subscription?.price?.extraUnitValue || 0) / 100);

  const frequency = subscription?.price?.frequency || 'month';

  const onFinish = () => {
    if (firstName && lastName && email) {
      dispatch(
        new CreateInvite({
          givenName: firstName,
          familyName: lastName,
          email,
        })
      );
    }
    handleClose();
  };

  return (
    <div className={`${className || ''} modal`}>
      <Modal
        className="modal__wrapper"
        visible={isModalVisible}
        maskClosable
        centered
        footer={null}
        width={500}
        closeIcon={<CloseCircleOutlined onClick={handleClose} className="modal__close" />}
      >
        <Typography.Title className="modal__wrapper__title has-subtitle" level={3}>
          New Member
        </Typography.Title>

        <Typography.Text type="warning" className="modal__wrapper__subtitle">
          For each new member you will be charged {price}/{frequency}.
        </Typography.Text>

        <Form name="newCampaign" layout="vertical" initialValues={{ remember: false }} onFinish={onFinish}>
          <Space direction="vertical" size="large" className="modal__wrapper__form__space">
            <FormItem
              name="First name"
              className="modal__wrapper__form__item"
              label="First name"
              initialValue={firstName}
              rules={[
                {
                  required: true,
                  message: "Add the new member's first name",
                },
              ]}
            >
              <Input
                className="modal__wrapper__form__item__input"
                placeholder="First name"
                size="large"
                value={firstName}
                onChange={(ev) => setFirstName(ev.target.value)}
              />
            </FormItem>
            <FormItem
              name="Last name"
              className="modal__wrapper__form__item"
              label="Last name"
              initialValue={lastName}
              rules={[
                {
                  required: true,
                  message: "Add the new member's last name",
                },
              ]}
            >
              <Input
                className="modal__wrapper__form__item__input"
                placeholder="Last name"
                size="large"
                value={lastName}
                onChange={(ev) => setLastName(ev.target.value)}
              />
            </FormItem>
            <FormItem
              name="Email"
              className="modal__wrapper__form__item"
              label="Email"
              initialValue={email}
              rules={[
                {
                  required: true,
                  type: 'email',
                  message: "Add the new member's email",
                },
              ]}
            >
              <Input
                className="modal__wrapper__form__item__input"
                placeholder="Email"
                size="large"
                value={email}
                onChange={(ev) => setEmail(ev.target.value)}
              />
            </FormItem>
          </Space>
          <Space size="large" className="modal__wrapper__buttongroup">
            <Button onClick={handleClose} htmlType="reset" size="large" className="modal__wrapper__button">
              Cancel
            </Button>
            <Button type="primary" htmlType="submit" size="large" className="modal__wrapper__button">
              Save
            </Button>
          </Space>
        </Form>
      </Modal>
    </div>
  );
}
