import { Observable } from 'rxjs';
import { ofType } from 'redux-observable';
import { mergeMap } from 'rxjs/operators';
import * as users from '../../../sdk/users';
import { Actions } from '../../actions';
import { DeleteUser, DeleteUserSuccess, DeleteUserError } from '../action-creators';
import { DELETE_USER } from '../actions';
import ApiError from '../../../../../backend/commons/utils/api/api-error';

export const deleteUserEpic = (action$: Observable<Actions>): Observable<Actions> =>
  action$.pipe(
    ofType<Actions, DeleteUser>(DELETE_USER),
    mergeMap(async (action) => {
      try {
        const result = await users.remove(action.userId);

        return new DeleteUserSuccess(action.userId, result);
      } catch (err) {
        return new DeleteUserError((err as ApiError).message, (err as ApiError).description || '');
      }
    })
  );
