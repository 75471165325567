import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { CloseCircleOutlined } from '@ant-design/icons';
import './styles.less';

import Modal from 'antd/lib/modal/Modal';

import { Form, FormInstance, Radio, Button, Input, Space, Typography } from 'antd';
import DragnDrop from '../upload/dragndrop';
import { CreateClient, UpdateClient } from '../../store/client/action-creators';

import { Client } from '../../../../backend/services/client/types/entities/client';
import { CreateClientBodyRequest } from '../../../../backend/services/client/functions/create-client/interface';
import { UpdateClientBodyRequest } from '../../../../backend/services/client/functions/update-client/interface';
import { getClientLabel } from '@webapp/store/auth/selectors';

interface ClientEditorModalProps {
  className?: string;
  isModalVisible: boolean;
  form: FormInstance<any>;
  client?: Client;
  isSales: boolean;
  handleClose: () => void;
}

const COLORS = ['#ff5500', '#ff5c8d', '#7e57c2', '#6f74dd', '#43a047', '#4ebaaa', '#2b83c2', '#fbc02d'];

export default function ClientEditorModal({
  className,
  isModalVisible,
  form,
  client,
  isSales,
  handleClose,
}: ClientEditorModalProps): JSX.Element {
  const dispatch = useDispatch();

  const [uploading, setUploading] = React.useState(false);

  const clientLabel = useSelector(getClientLabel);

  useEffect(() => {
    if (isModalVisible) {
      form.setFieldsValue({ name: client?.name, clientLogo: client?.logo, color: client?.color });
      setUploading(false);
    }
  }, [client, isModalVisible]);

  const onFinish = () => {
    if (!form.getFieldValue('name')) {
      return;
    }

    if (client?.id) {
      dispatch(
        new UpdateClient(client.id, {
          name: form.getFieldValue('name'),
          logo: form.getFieldValue('clientLogo'),
          color: form.getFieldValue('color'),
        } as UpdateClientBodyRequest)
      );
    } else {
      dispatch(
        new CreateClient({
          name: form.getFieldValue('name'),
          logo: form.getFieldValue('clientLogo'),
          color: form.getFieldValue('color'),
        } as CreateClientBodyRequest)
      );
    }
    handleClose();
  };

  const handleUpload = (url: string | undefined) => {
    form.setFieldsValue({ clientLogo: url });
    setUploading(false);
  };

  const clientLogo = form.getFieldValue('clientLogo') || '';

  return (
    <div className={`${className || ''} modal`}>
      <Modal
        className="modal__wrapper"
        visible={isModalVisible}
        maskClosable={true}
        centered={true}
        footer={null}
        width={1000}
        forceRender
        closeIcon={<CloseCircleOutlined onClick={handleClose} className="modal__close" />}
      >
        <Typography.Title className="modal__wrapper__title" level={3}>
          {client?.id ? 'Edit' : 'New'} {clientLabel}
        </Typography.Title>

        <Form
          form={form}
          name="newClient"
          layout="vertical"
          initialValues={{ name: client?.name || '', clientLogo: client?.logo, color: COLORS[0], remember: false }}
          onFinish={onFinish}
        >
          <Form.Item
            name="name"
            className="modal__wrapper__form__item"
            rules={[
              {
                required: true,
                message: `Please input the ${clientLabel} Name!`,
              },
            ]}
          >
            <Input placeholder={`${clientLabel} Name`} size="large" />
          </Form.Item>

          {isSales ? (
            <Space className="modal__wrapper__team" align="center" direction="vertical">
              <Typography.Paragraph className="modal__wrapper__team__title">
                Select a color for the team
              </Typography.Paragraph>
              <Form.Item
                name="color"
                className="modal__wrapper__form__item"
                rules={[
                  {
                    required: true,
                    message: `Please select the Team Color!`,
                  },
                ]}
              >
                <Radio.Group buttonStyle="solid">
                  {COLORS.map((color, i) => (
                    <Radio.Button
                      className="modal__wrapper__form__item__radio"
                      value={color}
                      key={i}
                      style={{ backgroundColor: color }}
                    />
                  ))}
                </Radio.Group>
              </Form.Item>
            </Space>
          ) : (
            <>
              <Typography.Paragraph className="modal__wrapper__upload__title">Client Logo</Typography.Paragraph>
              <Form.Item name="clientLogo" noStyle>
                <DragnDrop
                  className="modal__wrapper__upload__component"
                  type="logo"
                  current={clientLogo}
                  onUpload={handleUpload}
                  onUploadStart={() => setUploading(true)}
                />
              </Form.Item>
            </>
          )}
          <Button
            type="primary"
            htmlType="submit"
            size="large"
            className="modal__wrapper__newclient__button has-margin"
            loading={uploading}
            disabled={uploading}
          >
            {client?.id ? 'Save' : 'Create'} {clientLabel}
          </Button>
        </Form>
      </Modal>
    </div>
  );
}
