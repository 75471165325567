import React from 'react';

interface ScenarioIconProps {
  className?: string;
  fill?: string;
  onClick?: () => void;
}

export default function ScenarioIcon({ className, fill, onClick }: ScenarioIconProps): JSX.Element {
  return (
    <svg x="0" y="0" width="32" height="32" viewBox="0 0 32 32" onClick={onClick} className={className}>
      <path
        fill={fill ? fill : '#ffffff'}
        d="M22.5 9.3C20.8 7.6 18.4 6.7 15.9 6.7c-4.9 0.1-9.1 4.1-9.2 9.2 0 2.9 1.2 5.6 3.6 7.5C11.3 24.3 12 25.5 12 26.8v2.8c0 1.6 1.1 2.4 2 2.4h4c1.1 0 2-0.9 2-2v-3.1c0-1.3 0.7-2.8 1.9-3.7 2.3-1.7 3.5-4.4 3.5-7.2C25.3 13.5 24.3 11.1 22.5 9.3zM18 30.7h-4c-0.7 0-0.7-0.8-0.7-1.1V28h5.3v2C18.7 30.4 18.4 30.7 18 30.7zM21.1 22.1c-1.4 1.1-2.3 2.9-2.4 4.5h-5.4c0-1.7-0.8-3.2-2.3-4.4C9.1 20.8 8 18.4 8 15.9 8.1 11.6 11.7 8 16 8c2.1 0 4.1 0.8 5.6 2.3 1.6 1.6 2.4 3.6 2.4 5.7C24 18.4 22.9 20.7 21.1 22.1z"
      />
      <path
        fill={fill ? fill : '#ffffff'}
        d="M16 4c-0.4 0-0.7-0.3-0.7-0.7V0.7C15.3 0.3 15.6 0 16 0c0.4 0 0.7 0.3 0.7 0.7v2.7C16.7 3.7 16.4 4 16 4z"
      />
      <path
        fill={fill ? fill : '#ffffff'}
        d="M24.9 7.7c-0.1 0-0.4 0-0.5-0.1 -0.3-0.3-0.3-0.7 0-0.9l1.9-1.9c0.3-0.3 0.7-0.3 0.9 0 0.3 0.3 0.3 0.7 0 0.9L25.3 7.6C25.3 7.6 25.1 7.7 24.9 7.7L24.9 7.7z"
      />
      <path
        fill={fill ? fill : '#ffffff'}
        d="M31.3 16.7h-2.7C28.3 16.7 28 16.4 28 16c0-0.4 0.3-0.7 0.7-0.7h2.7c0.4 0 0.7 0.3 0.7 0.7C32 16.4 31.7 16.7 31.3 16.7z"
      />
      <path
        fill={fill ? fill : '#ffffff'}
        d="M26.8 27.5c-0.1 0-0.4 0-0.5-0.1l-1.9-1.9c-0.3-0.3-0.3-0.7 0-0.9s0.7-0.3 0.9 0l1.9 1.9c0.3 0.3 0.3 0.7 0 0.9C27.2 27.5 27.1 27.5 26.8 27.5L26.8 27.5z"
      />
      <path
        fill={fill ? fill : '#ffffff'}
        d="M5.2 27.5c-0.1 0-0.4 0-0.5-0.1 -0.3-0.3-0.3-0.7 0-0.9l1.9-1.9c0.3-0.3 0.7-0.3 0.9 0s0.3 0.7 0 0.9L5.6 27.3C5.5 27.5 5.3 27.5 5.2 27.5z"
      />
      <path
        fill={fill ? fill : '#ffffff'}
        d="M3.3 16.7H0.7C0.3 16.7 0 16.4 0 16c0-0.4 0.3-0.7 0.7-0.7h2.7C3.7 15.3 4 15.6 4 16 4 16.4 3.7 16.7 3.3 16.7z"
      />
      <path
        fill={fill ? fill : '#ffffff'}
        d="M7.1 7.7c-0.1 0-0.4 0-0.5-0.1l-1.9-2c-0.3-0.3-0.3-0.7 0-0.9 0.3-0.3 0.7-0.3 0.9 0l1.9 1.9c0.3 0.3 0.3 0.7 0 0.9C7.3 7.6 7.2 7.7 7.1 7.7L7.1 7.7z"
      />
      <path
        fill={fill ? fill : '#ffffff'}
        d="M21.3 16.7c-0.4 0-0.7-0.3-0.7-0.7 0-2.5-2.1-4.7-4.7-4.7 -0.4 0-0.7-0.3-0.7-0.7 0-0.4 0.3-0.7 0.7-0.7 3.3 0 6 2.7 6 6C22 16.4 21.7 16.7 21.3 16.7z"
      />
    </svg>
  );
}
