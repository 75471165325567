import { FunnelTypeState } from './state';
import { funnelTypeInitialState } from './initial-state';
import { FunnelTypeActions } from './action-creators';
import * as actions from './actions';

export const funnelTypeReducer = (
  state: FunnelTypeState = funnelTypeInitialState,
  action: FunnelTypeActions
): FunnelTypeState => {
  switch (action.type) {
    case actions.LIST_FUNNEL_TYPE_SUCCESS: {
      return {
        ...state,
        items: action.results.items || [],
        nextToken: action.results.nextToken,
      };
    }

    case actions.CREATE_FUNNEL_TYPE_SUCCESS: {
      return {
        ...state,
        items: state.items.concat([action.funnelType]),
      };
    }

    case actions.UPDATE_FUNNEL_TYPE_SUCCESS: {
      return {
        ...state,
        items: state.items.map((c) => {
          if (c.id === action.funnelType.id) {
            return action.funnelType;
          }
          return { ...c };
        }),
      };
    }

    case actions.DELETE_FUNNEL_TYPE_SUCCESS: {
      return {
        ...state,
        items: state.items.filter((c) => c.id !== action.funnelTypeId),
      };
    }

    default: {
      return { ...state };
    }
  }
};
