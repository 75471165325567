import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ofType } from 'redux-observable';
import { LOGOUT_USER_SUCCESS } from '../../auth/actions';
import { Action } from 'redux';
import { ResetUI } from '../action-creators';

export const resetAfterLogoutEpic = (action$: Observable<Action>): Observable<Action> =>
  action$.pipe(
    ofType(LOGOUT_USER_SUCCESS),
    map(() => new ResetUI())
  );
