import { Observable } from 'rxjs';
import { ofType } from 'redux-observable';
import { mergeMap } from 'rxjs/operators';
import * as clients from '../../../sdk/client';
import { Actions } from '../../actions';
import { UpdateClient, UpdateClientSuccess, UpdateClientError } from '../action-creators';
import { UPDATE_CLIENT } from '../actions';
import ApiError from '../../../../../backend/commons/utils/api/api-error';

export const updateClientEpic = (action$: Observable<Actions>): Observable<Actions> =>
  action$.pipe(
    ofType<Actions, UpdateClient>(UPDATE_CLIENT),
    mergeMap(async (action) => {
      try {
        const client = await clients.update(action.clientId, action.client);

        return new UpdateClientSuccess(client);
      } catch (err) {
        return new UpdateClientError((err as ApiError).message, (err as ApiError).description || '');
      }
    })
  );
