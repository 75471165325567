import React from 'react';
import { Row, Space } from 'antd';

import LoginForm from '../../components/auth/login-form';
import PageBackground from '@webapp/components/layout/page-background';

export default function LoginPage(): JSX.Element {
  return (
    <PageBackground>
      <Row justify="center" align="middle" style={{ minHeight: '100vh' }}>
        <Space direction="vertical" align="center">
          <LoginForm />
        </Space>
      </Row>
    </PageBackground>
  );
}
