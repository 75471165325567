import { Observable } from 'rxjs';
import { ofType } from 'redux-observable';
import { mergeMap } from 'rxjs/operators';
import * as funneltypes from '../../../sdk/funnel-types';
import { Actions } from '../../actions';
import { ListFunnelType, ListFunnelTypeSuccess, ListFunnelTypeError } from '../action-creators';
import { LIST_FUNNEL_TYPE } from '../actions';
import ApiError from '../../../../../backend/commons/utils/api/api-error';

export const listFunnelTypeEpic = (action$: Observable<Actions>): Observable<Actions> =>
  action$.pipe(
    ofType<Actions, ListFunnelType>(LIST_FUNNEL_TYPE),
    mergeMap(async (action) => {
      try {
        const funnelTypeList = await funneltypes.list(action.clientId);

        return new ListFunnelTypeSuccess(funnelTypeList);
      } catch (err) {
        return new ListFunnelTypeError((err as ApiError).message, (err as ApiError).description || '');
      }
    })
  );
