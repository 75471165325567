import React from 'react';
import './styles.less';

export default function Background({ className }: { className?: string }): JSX.Element {
  return (
    <svg width="100%" height="100%" className={`${className || ''} background`}>
      <defs>
        <pattern
          id="pattern"
          x="0"
          y="0"
          width="1920"
          height="1080"
          viewBox="0 0 460 260"
          patternUnits="userSpaceOnUse"
        >
          <circle className="background__dark" cx="320" cy="44.3" r="17.3" />
          <circle className="background__dark" cx="97.8" cy="34.2" r="16.9" />
          <circle className="background__dark" cx="26.5" cy="194.3" r="16.9" />
          <circle className="background__dark" cx="419.2" cy="151.9" r="16.9" />
          <path
            className="background__dark"
            d="M338.1 260c3.5-3.1 5.7-7.6 5.7-12.6 0-9.3-7.6-16.9-16.9-16.9s-16.9 7.6-16.9 16.9c0 5 2.2 9.6 5.7 12.6H338.1z"
          />
          <circle className="background__dark" cx="336.8" cy="135.7" r="10" />
          <path className="background__dark" d="M460 62.2v19.9c-5.3-0.2-9.6-4.6-9.6-9.9S454.7 62.4 460 62.2z" />
          <circle className="background__dark" cx="240.4" cy="155.4" r="10" />
          <circle className="background__dark" cx="187.5" cy="64.1" r="10" />
          <circle className="background__dark" cx="191.4" cy="17.4" r="10" />
          <path
            className="background__dark"
            d="M26.4 0.4c0 5.5-4.5 10-10 10S6.5 5.9 6.5 0.4c0-0.1 0-0.3 0-0.4h19.9C26.4 0.1 26.4 0.3 26.4 0.4z"
          />
          <circle className="background__dark" cx="65.6" cy="135.7" r="10" />
          <circle className="background__dark" cx="146.3" cy="190.9" r="10" />
          <circle className="background__dark" cx="208.9" cy="244.6" r="10" />
          <circle className="background__dark" cx="436.4" cy="236.6" r="10" />
          <path
            className="background__light"
            d="M159 114.3l-46.3 26.9c-4.7 2.7-10.6 1.1-13.4-3.5l0 0c-2.7-4.7-1.1-10.6 3.6-13.4l46.3-26.9c4.7-2.7 10.7-1.1 13.4 3.6l0 0C165.3 105.6 163.7 111.6 159 114.3z"
          />
          <path
            className="background__light"
            d="M460 0v37.3l-63.8 37c-7.9 4.6-17.9 1.9-22.5-6 -1.5-2.6-2.2-5.4-2.2-8.2 0-5.7 2.9-11.2 8.2-14.3l78-45.2c0.3-0.2 0.7-0.4 1-0.5H460z"
          />
          <path
            className="background__light"
            d="M63.4 84.4c0 5.7-2.9 11.2-8.2 14.3l-42.2 24.5c-4.1 2.4-8.8 2.8-13.1 1.5V92.6l38.7-22.4c7.9-4.6 17.9-1.9 22.5 6C62.7 78.7 63.4 81.6 63.4 84.4z"
          />
          <path
            className="background__light"
            d="M94 229.3l-46.3 26.9c-4.7 2.7-10.6 1.1-13.4-3.5l0 0c-2.7-4.7-1.1-10.6 3.6-13.4l46.3-26.9c4.7-2.7 10.7-1.1 13.4 3.6l0 0C100.3 220.6 98.7 226.6 94 229.3z"
          />
          <path
            className="background__light"
            d="M278.2 208.5l-23.4 13.6c-4.7 2.7-10.6 1.1-13.4-3.5l0 0c-2.7-4.7-1.1-10.6 3.6-13.4l23.4-13.6c4.7-2.7 10.7-1.1 13.4 3.6v0C284.4 199.8 282.9 205.8 278.2 208.5z"
          />
          <path
            className="background__light"
            d="M432.2 208.5l-23.4 13.6c-4.7 2.7-10.6 1.1-13.4-3.5v0c-2.7-4.7-1.1-10.6 3.6-13.4l23.4-13.6c4.7-2.7 10.7-1.1 13.4 3.6v0C438.4 199.8 436.9 205.8 432.2 208.5z"
          />
          <path
            className="background__light"
            d="M322.2 99.5l-23.4 13.6c-4.7 2.7-10.6 1.1-13.4-3.5v0c-2.7-4.7-1.1-10.6 3.6-13.4l23.4-13.6c4.7-2.7 10.7-1.1 13.4 3.6v0C328.4 90.8 326.9 96.8 322.2 99.5z"
          />
        </pattern>
      </defs>
      <rect x="0" y="0" height="100%" width="100%" fill="url(#pattern)" />
    </svg>
  );
}
