import Amplify from 'aws-amplify';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const setup = (awsconfig: any = {}): unknown => {
  const config = {
    ...awsconfig,
    Auth: {
      userPoolId: process.env.REACT_APP_COGNITO_USER_POOL_ID,
      userPoolWebClientId: process.env.REACT_APP_COGNITO_WEB_CLIENT_ID,
      region: process.env.REACT_APP_COGNITO_REGION,
      mandatorySignIn: true,
      ...awsconfig.Auth,
      oauth: {
        domain: process.env.REACT_APP_COGNITO_DOMAIN,
        redirectSignIn: `${window.location.protocol}//${window.location.host}/dashboard/`,
        redirectSignOut: `${window.location.protocol}//${window.location.host}/dashboard/`,
        responseType: 'code',
        scope: ['phone', 'email', 'profile', 'openid'],
        options: {
          AdvancedSecurityDataCollectionFlag: false,
        },
      },
    },
  };

  return Amplify.configure(config);
};
