import { CallApi, handleJSONResponse } from './common';
import { ListForecastResponse } from '../../../backend/services/forecast/functions/list-forecast/interface';
import {
  CreateForecastBodyRequest,
  CreateForecastResponse,
} from '../../../backend/services/forecast/functions/create-forecast/interface';
import {
  UpdateForecastBodyRequest,
  UpdateForecastResponse,
} from '../../../backend/services/forecast/functions/update-forecast/interface';
import { GetForecastResponse } from '../../../backend/services/forecast/functions/get-forecast/interface';
import { DeleteForecastResponse } from '../../../backend/services/forecast/functions/delete-forecast/interface';

const callAPI = CallApi('forecast');

export const list = async (clientId: string): Promise<ListForecastResponse> => {
  const data = {
    limit: 500,
  };
  const response = await callAPI(`/${clientId}`, 'GET', data);

  return await handleJSONResponse(response);
};

export const create = async (
  clientId: string,
  forecast: CreateForecastBodyRequest
): Promise<CreateForecastResponse> => {
  const response = await callAPI(`/${clientId}`, 'POST', forecast);

  return await handleJSONResponse(response);
};

export const update = async (
  clientId: string,
  forecastId: string,
  forecast: UpdateForecastBodyRequest
): Promise<UpdateForecastResponse> => {
  const response = await callAPI(`/${clientId}/${forecastId}`, 'PUT', forecast);

  return await handleJSONResponse(response);
};

export const get = async (clientId: string, forecastId: string): Promise<GetForecastResponse> => {
  const response = await callAPI(`/${clientId}/${forecastId}`, 'GET');

  return await handleJSONResponse(response);
};

export const remove = async (clientId: string, forecastId: string): Promise<DeleteForecastResponse> => {
  const response = await callAPI(`/${clientId}/${forecastId}`, 'DELETE');

  return await handleJSONResponse(response);
};
