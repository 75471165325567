import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import { Form, Input, Button, Checkbox, Typography } from 'antd';
import { UserOutlined, UnlockOutlined } from '@ant-design/icons';

import './styles.less';

import FormWrapper from './form-wrapper';
import { LoginUser } from '../../store/auth/action-creators';
import AppState from '@webapp/store/state';
import { LOGIN_USER, LOGIN_USER_ERROR } from '@webapp/store/auth/actions';

interface FormData {
  email: string;
  password: string;
}

export default function LoginForm(): JSX.Element {
  const dispatch = useDispatch();

  const onFinish = (values: FormData) => {
    dispatch(
      new LoginUser({
        email: values.email,
        password: values.password,
      })
    );
  };

  const loading = useSelector((state: AppState) => !!state.ui.loading[LOGIN_USER]);

  const loginFailed = useSelector((state: AppState) => state.ui.errors[LOGIN_USER_ERROR]);

  return (
    <FormWrapper title="Log in to your account">
      {loginFailed ? (
        <Typography.Text className="error" strong type="danger">
          The email address or password is incorrect
        </Typography.Text>
      ) : null}
      <Form name="basic" layout="vertical" initialValues={{ remember: true }} onFinish={onFinish}>
        <Form.Item
          className="form__wrapper__item"
          name="email"
          rules={[{ required: true, message: 'Please input your email!' }]}
        >
          <Input size="large" placeholder="E-mail" prefix={<UserOutlined className="form__wrapper__item__icon" />} />
        </Form.Item>

        <Form.Item
          className="form__wrapper__item"
          name="password"
          rules={[{ required: true, message: 'Please input your password!' }]}
        >
          <Input.Password
            size="large"
            placeholder="Password"
            prefix={<UnlockOutlined className="form__wrapper__item__icon" />}
          />
        </Form.Item>

        <Form.Item className="form__wrapper__item__remember">
          <Form.Item className="form__wrapper__item__remember__item" name="remember" valuePropName="checked">
            <Checkbox>Remember me</Checkbox>
          </Form.Item>
          <Form.Item className="form__wrapper__item__remember__item">
            <Link to="/forgot-password">Forgot password</Link>
          </Form.Item>
        </Form.Item>

        <Form.Item className="form__wrapper__button">
          <Button type="primary" htmlType="submit" size="large" loading={loading}>
            Log in
          </Button>
          <Typography.Paragraph className="form__wrapper__button__register">
            New to FunnelTap? <Link to="/registration">Register now!</Link>
          </Typography.Paragraph>
        </Form.Item>
      </Form>
    </FormWrapper>
  );
}
