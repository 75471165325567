import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import './styles.less';

import AppState from '@webapp/store/state';
import { UPDATE_USER } from '@webapp/store/user/actions';
import { userProfile } from '@webapp/store/auth/selectors';

import { Button, Space, Typography, Form, Input } from 'antd';
import { UpdateUser } from '../../../store/user/action-creators';
import DragnDrop from '../../upload/dragndrop';

interface ProfileUserInfoProps {
  className?: string;
}

export default function ProfileUserInfo({ className }: ProfileUserInfoProps): JSX.Element {
  const dispatch = useDispatch();

  const loading = useSelector((state: AppState) => !!state.ui.loading[UPDATE_USER]);

  const [uploading, setUploading] = useState(false);

  const profile = useSelector(userProfile);
  const user = useSelector((state: AppState) => state.user.items.find((u) => u.id === profile.sub));

  const onFinish = (values: { given_name: string; family_name: string }) => {
    dispatch(
      new UpdateUser(profile.sub, {
        givenName: values.given_name,
        familyName: values.family_name,
      })
    );
    return;
  };

  const handleUpload = (url: string) => {
    dispatch(
      new UpdateUser(profile.sub, {
        avatar: url,
      })
    );
    setUploading(false);
  };

  return (
    <div className={`${className} user-info`}>
      <Typography.Title level={4} className="user-info__title">
        User Info
      </Typography.Title>
      <Space size="large" direction="vertical">
        <DragnDrop
          className=""
          type="avatar"
          current={user?.avatar}
          onUpload={handleUpload}
          onUploadStart={() => setUploading(true)}
        />
        <Form name="basic" layout="vertical" onFinish={onFinish}>
          <Form.Item
            className="form__wrapper__item"
            name="given_name"
            initialValue={profile?.given_name}
            rules={[{ required: true, message: 'Please input your First Name!' }]}
          >
            <Input placeholder="First Name" size="large" />
          </Form.Item>

          <Form.Item
            className="form__wrapper__item"
            name="family_name"
            initialValue={profile?.family_name}
            rules={[{ required: true, message: 'Please input your Last Name!' }]}
          >
            <Input placeholder="Last Name" size="large" />
          </Form.Item>
          <Button type="primary" htmlType="submit" size="large" loading={loading || uploading}>
            Update Account
          </Button>
        </Form>
      </Space>
    </div>
  );
}
