import React from 'react';
import './styles.less';

import { Space, Typography } from 'antd';

import Page from '@webapp/components/layout/page';
import CurrentSubscription from '@webapp/components/subscription/current-subscription';
import Invoices from '@webapp/components/subscription/invoices';
import CancelSubscription from '@webapp/components/cancel-subscription';
import { useSelector } from 'react-redux';
import { isSubscriptionTrial } from '@webapp/store/subscriptions/selectors';

export default function BillingPage(): JSX.Element {
  const isTrialVersion = useSelector(isSubscriptionTrial);

  return (
    <Page className="settings">
      <Space align="start" style={{ justifyContent: 'space-between', width: '100%' }}>
        <Typography.Title level={3} className="settings__title">
          Subscription
        </Typography.Title>
      </Space>
      <CurrentSubscription className="settings__current" />
      {!isTrialVersion ? <CancelSubscription /> : null}
      <Invoices className="settings__invoices" />
    </Page>
  );
}
