import { funnelTemplateReducer } from './funnel-template/reducer';
import { createBrowserHistory } from 'history';
import { createStore, applyMiddleware, Dispatch, combineReducers } from 'redux';
import { createEpicMiddleware, combineEpics } from 'redux-observable';
import { connectRouter, routerMiddleware } from 'connected-react-router';
import { composeWithDevTools } from 'redux-devtools-extension/developmentOnly';
import authReducer from './auth/reducer';
import authEpic from './auth/effects';
import uiReducer from './ui/reducer';
import uiEpic from './ui/effects';
import { clientReducer } from './client/reducer';
import clientEpic from './client/effects';
import { funnelTypeReducer } from './funnel-type/reducer';
import funnelTypeEpic from './funnel-type/effects';
import { productReducer } from './products/reducer';
import productsEpic from './products/effects';
import { subscriptionReducer } from './subscriptions/reducer';
import subscriptionsEpic from './subscriptions/effects';
import { campaignReducer } from './campaign/reducer';
import campaignEpic from './campaign/effects';
import { forecastReducer } from './forecast/reducer';
import forecastEpic from './forecast/effects';
import { scenarioReducer } from './scenario/reducer';
import scenarioEpic from './scenario/effects';
import { inviteReducer } from './invite/reducer';
import inviteEpic from './invite/effects';
import { userReducer } from './user/reducer';
import userEpic from './user/effects';
import { invoiceReducer } from './invoice/reducer';
import invoiceEpic from './invoice/effects';
import AppState from './state';
import { Actions } from './actions';

import setupAmplifyIntegration from './auth/amplify-integration';
import { promoCodeReducer } from './promo-code/reducer';
import promoCodeEpic from './promo-code/effects';
import funnelTemplateEpic from './funnel-template/effects';

const epicMiddleware = createEpicMiddleware<Actions, Actions, AppState, unknown>();

const classMiddleware = () => (next: Dispatch<Actions>) => (action: Actions) => next({ ...action });

export const history = createBrowserHistory({
  basename: process.env.REACT_APP_BASENAME,
});

const store = createStore(
  combineReducers({
    router: connectRouter(history),
    auth: authReducer,
    ui: uiReducer,
    client: clientReducer,
    funnelTypes: funnelTypeReducer,
    products: productReducer,
    subscriptions: subscriptionReducer,
    campaign: campaignReducer,
    forecast: forecastReducer,
    scenario: scenarioReducer,
    invite: inviteReducer,
    user: userReducer,
    invoice: invoiceReducer,
    promoCode: promoCodeReducer,
    funnelTemplates: funnelTemplateReducer,
  }),
  composeWithDevTools(applyMiddleware(classMiddleware, routerMiddleware(history), epicMiddleware))
);

epicMiddleware.run(
  combineEpics(
    authEpic,
    uiEpic,
    clientEpic,
    funnelTypeEpic,
    productsEpic,
    subscriptionsEpic,
    campaignEpic,
    forecastEpic,
    scenarioEpic,
    inviteEpic,
    userEpic,
    invoiceEpic,
    promoCodeEpic,
    funnelTemplateEpic
  )
);

setupAmplifyIntegration(store.dispatch);

export default store;
