import { Observable } from 'rxjs';
import { ofType, StateObservable } from 'redux-observable';

import * as actions from '../actions';
import * as actionCreators from '../action-creators';
import { mergeMap, withLatestFrom } from 'rxjs/operators';
import { Actions } from '../../actions';
import * as auth from '@webapp/sdk/auth';
import AppState from '../../state';
import { notification } from 'antd';

export const changePasswordEpic = (
  action$: Observable<Actions>,
  state$: StateObservable<AppState>
): Observable<Actions> =>
  action$.pipe(
    ofType<Actions, actionCreators.ChangePassword>(actions.CHANGE_PASSWORD),
    withLatestFrom(state$),
    mergeMap(async ([action, state]) => {
      try {
        await auth.changePassword(state.auth.user, action.data.oldPassword, action.data.newPassword);
        notification.success({
          message: 'Success!',
          description: 'Password successfully changed',
        });
        return new actionCreators.ChangePasswordSuccess();
      } catch (err) {
        return new actionCreators.ChangePasswordError((err as any).code, (err as any).message);
      }
    })
  );
