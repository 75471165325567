import { Observable } from 'rxjs';
import { ofType } from 'redux-observable';
import { mergeMap } from 'rxjs/operators';
import * as scenarios from '../../../sdk/scenarios';
import { Actions } from '../../actions';
import { ListScenario, ListScenarioSuccess, ListScenarioError } from '../action-creators';
import { LIST_SCENARIO } from '../actions';
import ApiError from '../../../../../backend/commons/utils/api/api-error';

export const listScenarioEpic = (action$: Observable<Actions>): Observable<Actions> =>
  action$.pipe(
    ofType<Actions, ListScenario>(LIST_SCENARIO),
    mergeMap(async (action) => {
      try {
        const scenarioList = await scenarios.list(action.clientId);

        return new ListScenarioSuccess(scenarioList);
      } catch (err) {
        return new ListScenarioError((err as ApiError).message, (err as ApiError).description || '');
      }
    })
  );
