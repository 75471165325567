import { Typography } from 'antd';
import React from 'react';
import './styles.less';

interface ConversionRateItemProps {
  className?: string;
  conversionRatePercent: string | number;
}

export default function ConversionRateItem({ className, conversionRatePercent }: ConversionRateItemProps): JSX.Element {
  return (
    <div className={`${className || ''} conversion-rate-item`}>
      <Typography className="conversion-rate-item__text">{conversionRatePercent}%</Typography>
    </div>
  );
}
