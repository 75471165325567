import { CallApi, handleJSONResponse } from './common';
import { ListUserResponse } from '../../../backend/services/user/functions/list-user/interface';
import {
  UpdateUserBodyRequest,
  UpdateUserResponse,
} from '../../../backend/services/user/functions/update-user/interface';
import { GetUserResponse } from '../../../backend/services/user/functions/get-user/interface';
import { DeleteUserResponse } from '../../../backend/services/user/functions/delete-user/interface';

const callAPI = CallApi('user');

export const list = async (): Promise<ListUserResponse> => {
  const data = {
    limit: 500,
  };
  const response = await callAPI(`/`, 'GET', data);

  return await handleJSONResponse(response);
};

export const update = async (userId: string, user: UpdateUserBodyRequest): Promise<UpdateUserResponse> => {
  const response = await callAPI(`/${userId}`, 'PUT', user);

  return await handleJSONResponse(response);
};

export const get = async (userId: string): Promise<GetUserResponse> => {
  const response = await callAPI(`/${userId}`, 'GET');

  return await handleJSONResponse(response);
};

export const remove = async (userId: string): Promise<DeleteUserResponse> => {
  const response = await callAPI(`/${userId}`, 'DELETE');

  return await handleJSONResponse(response);
};
