import * as actions from './actions';
import { LoadingAction } from '../common/loading-action';
import { ErrorAction } from '../common/error-action';
import { ListInvoiceResponse } from '../../../../backend/services/invoice/functions/list-invoice/interface';

export class ListInvoice implements LoadingAction {
  readonly type = actions.LIST_INVOICE;
  readonly isLoading = true;
  readonly mainLoader = true;
}

export class ListInvoiceSuccess implements LoadingAction {
  readonly type = actions.LIST_INVOICE_SUCCESS;
  readonly isLoading = false;

  constructor(public results: ListInvoiceResponse) {}
}

export class ListInvoiceError implements ErrorAction {
  readonly type = actions.LIST_INVOICE_ERROR;
  readonly isError = true;

  constructor(public code: string, public description: string | string[]) {}
}

export type InvoiceActions = ListInvoice | ListInvoiceSuccess | ListInvoiceError;
