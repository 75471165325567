import { Observable } from 'rxjs';
import { ofType, StateObservable } from 'redux-observable';
import { mergeMap, withLatestFrom } from 'rxjs/operators';
import * as forecasts from '../../../sdk/forecasts';
import { Actions } from '../../actions';
import AppState from '../../state';
import { CreateForecast, CreateForecastSuccess, CreateForecastError } from '../action-creators';
import { CREATE_FORECAST } from '../actions';
import { NoOp } from '../../ui/action-creators';
import ApiError from '../../../../../backend/commons/utils/api/api-error';

export const createForecastEpic = (
  action$: Observable<Actions>,
  state$: StateObservable<AppState>
): Observable<Actions> =>
  action$.pipe(
    ofType<Actions, CreateForecast>(CREATE_FORECAST),
    withLatestFrom(state$),
    mergeMap(async ([action, state]) => {
      if (!state.client.active?.id) {
        return new NoOp();
      }

      try {
        const forecast = await forecasts.create(state.client.active.id, action.newForecast);

        return new CreateForecastSuccess(forecast);
      } catch (err) {
        return new CreateForecastError((err as ApiError).message, (err as ApiError).description || '');
      }
    })
  );
