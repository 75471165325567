import { Observable } from 'rxjs';
import { ofType, StateObservable } from 'redux-observable';
import { mergeMap, withLatestFrom } from 'rxjs/operators';
import * as campaigns from '../../../sdk/campaigns';
import { Actions } from '../../actions';
import AppState from '../../state';
import { DeleteCampaign, DeleteCampaignSuccess, DeleteCampaignError } from '../action-creators';
import { DELETE_CAMPAIGN } from '../actions';
import { NoOp } from '../../ui/action-creators';
import ApiError from '../../../../../backend/commons/utils/api/api-error';

export const deleteCampaignEpic = (
  action$: Observable<Actions>,
  state$: StateObservable<AppState>
): Observable<Actions> =>
  action$.pipe(
    ofType<Actions, DeleteCampaign>(DELETE_CAMPAIGN),
    withLatestFrom(state$),
    mergeMap(async ([action, state]) => {
      if (!state.client.active?.id) {
        return new NoOp();
      }

      try {
        const result = await campaigns.remove(state.client.active.id, action.campaignId);

        return new DeleteCampaignSuccess(action.campaignId, result);
      } catch (err) {
        return new DeleteCampaignError((err as ApiError).message, (err as ApiError).description || '');
      }
    })
  );
