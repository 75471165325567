import React, { useState } from 'react';
import './styles.less';

import { Form, Input, Button, Space, Typography, Modal, Tooltip, Select, Switch } from 'antd';
import { CloseCircleOutlined, PlusCircleFilled, DeleteOutlined } from '@ant-design/icons';

const { Option } = Select;

import { FunnelType } from '../../../../backend/services/funnel-type/types/entities/funnel-type';
import { useSelector } from 'react-redux';
import { selectFunnelTemplates } from '@webapp/store/funnel-template/selectors';
import { FunnelTemplate } from '@backend/services/funnel-template/types/entities/funnel-template';

interface FunnelTypeEditorModalProps {
  className?: string;
  isModalVisible: boolean;
  funnelType?: FunnelType;
  onSave: (funnelType: Partial<FunnelType>) => void;
  onSaveFunnelTemplate: (funnelTemplate: Partial<FunnelTemplate>) => void;
  onClose: () => void;
}

const DEFAULT_STAGES = ['', ''];

export default function FunnelTypeEditorModal({
  className,
  isModalVisible,
  funnelType,
  onSave,
  onSaveFunnelTemplate,
  onClose,
}: FunnelTypeEditorModalProps): JSX.Element {
  const [funnelTypeName, setFunnelTypeName] = useState(funnelType?.name || '');
  const [internalStages, setInternalStages] = useState(funnelType?.stages || DEFAULT_STAGES);

  const [isFunnelTemplate, setIsFunnelTemplate] = useState<boolean>(false);
  const [selectedFunnelTemplate, setSelectedFunnelTemplate] = useState<string>();

  const [titleEditing, setTitleEditing] = useState(false);

  const [hasFocus, setFocus] = useState(-1);

  const funnelTemplates = useSelector(selectFunnelTemplates);

  const [form] = Form.useForm();

  const onFinish = () => {
    const partialFunnel: Partial<FunnelType> = {
      id: funnelType?.id,
      name: funnelTypeName,
      stages: form.getFieldValue('stages'),
      costStage: form.getFieldValue('costStage'),
      valueStage: form.getFieldValue('valueStage'),
    };

    if (isFunnelTemplate) {
      onSaveFunnelTemplate(partialFunnel as FunnelTemplate);
    }

    onSave(partialFunnel);
    onClose();
  };

  const titleEditable = titleEditing || !funnelTypeName.length;

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>, i: number, handleAdd: () => void): void => {
    if (e.key === 'Enter') {
      e.preventDefault();
      e.stopPropagation();
      setFocus(i + 1);
      if (i === internalStages.length - 1) {
        handleAdd();
      }
    }
  };

  const focusLast = (i: number, ref?: Input | null) => {
    if (i === hasFocus) {
      setFocus(-1);
      ref?.focus({ cursor: 'end' });
    }
  };

  const handleSelectFunnelTemplate = (funnelTemplateId: string) => {
    setSelectedFunnelTemplate(funnelTemplateId);

    const funnelTemplate = funnelTemplates.find((f) => f.id === funnelTemplateId);

    if (funnelTemplateId && funnelTemplate) {
      setFunnelTypeName(funnelTemplate.name);
      setInternalStages(funnelTemplate.stages);
      form.setFieldsValue({
        stages: funnelTemplate.stages,
        costStage: funnelTemplate.costStage,
        valueStage: funnelTemplate.valueStage,
      });
    }
  };

  const handleClearSelectFunnelTemplate = () => {
    setFunnelTypeName('');
    setInternalStages(DEFAULT_STAGES);
    form.setFieldsValue({ stages: DEFAULT_STAGES, costStage: '', valueStage: '' });
  };

  return (
    <div className={`${className || ''} modal`}>
      <Modal
        className="modal__wrapper"
        visible={isModalVisible}
        maskClosable={true}
        centered={true}
        footer={null}
        width={1000}
        closeIcon={<CloseCircleOutlined onClick={onClose} className="modal__close" />}
      >
        <Form
          name="funnelTypeForm"
          form={form}
          layout="vertical"
          initialValues={{ stages: funnelType?.stages || DEFAULT_STAGES, remember: false }}
          onFinish={onFinish}
          onChange={() => setInternalStages(form.getFieldValue('stages'))}
        >
          <Form.Item name="funnel-template">
            <Select
              className="modal__wrapper__funnel-templates__select"
              placeholder="Select a Funnel Template"
              size="large"
              allowClear
              value={selectedFunnelTemplate}
              onChange={(value) => handleSelectFunnelTemplate(value)}
              onClear={handleClearSelectFunnelTemplate}
            >
              <Option value="empty">Start from scratch</Option>
              {funnelTemplates.map((funnelTemplate) => (
                <Option key={funnelTemplate.id} value={funnelTemplate.id}>
                  {funnelTemplate.name}
                </Option>
              ))}
            </Select>
          </Form.Item>
          <Typography.Title
            className="modal__wrapper__title"
            level={3}
            editable={{
              editing: titleEditable,
              onChange: setFunnelTypeName,
              onStart: () => setTitleEditing(true),
              onEnd: () => setTitleEditing(false),
              tooltip: 'click to edit text',
            }}
          >
            {funnelTypeName || `Funnel Name`}
          </Typography.Title>
          <Space direction="vertical" size="small" style={{ width: '100%' }}>
            <Form.List name="stages">
              {(fields, { add, remove }) => {
                return (
                  <Space size="middle" direction="vertical" className="modal__wrapper__form__item__space">
                    {fields.map((stage, i) => (
                      <Space key={stage.key} size="large" className="modal__wrapper__form__item__space">
                        <Typography.Title className="modal__wrapper__form__item__number" level={3}>
                          {i + 1}.
                        </Typography.Title>
                        <Form.Item
                          {...stage}
                          className="modal__wrapper__form__item"
                          rules={[
                            {
                              required: i <= 1,
                              message: `Please enter the name for stage nº${i + 1}`,
                            },
                          ]}
                        >
                          <Input
                            className="modal__wrapper__form__item__input"
                            placeholder={`Stage ${i + 1}`}
                            addonBefore={`Stage ${i + 1}`}
                            suffix={
                              i === fields.length - 1 && fields.length > 2 ? (
                                <DeleteOutlined
                                  className="modal__wrapper__form__item__input__icon"
                                  onClick={() => {
                                    remove(stage.name);
                                  }}
                                />
                              ) : null
                            }
                            size="large"
                            ref={(ref) => focusLast(i, ref)}
                            onKeyDown={(ev) => handleKeyDown(ev, i, add)}
                          />
                        </Form.Item>
                      </Space>
                    ))}
                    <Tooltip title="Add Stage">
                      <PlusCircleFilled className="modal__wrapper__plus" onClick={() => add()} />
                    </Tooltip>
                  </Space>
                );
              }}
            </Form.List>
            <Space size="large" align="center" className="modal__wrapper__cost-value">
              <Form.Item
                label="Cost"
                name="costStage"
                initialValue={funnelType?.costStage}
                className="modal__wrapper__cost-value__cost"
                rules={[
                  {
                    required: true,
                    message: `Please enter the cost stage`,
                  },
                ]}
              >
                <Select
                  disabled={internalStages.length < 2}
                  placeholder="Cost"
                  options={internalStages.map((stage: string, i: number) => ({ label: stage, value: stage, key: i }))}
                />
              </Form.Item>
              <Form.Item
                label="Value"
                name="valueStage"
                initialValue={funnelType?.valueStage}
                className="modal__wrapper__cost-value__value"
                rules={[
                  {
                    required: true,
                    message: `Please enter the value stage`,
                  },
                ]}
              >
                <Select
                  disabled={internalStages.length < 2}
                  placeholder="Value"
                  options={internalStages.map((stage: string, i: number) => ({ label: stage, value: stage, key: i }))}
                />
              </Form.Item>
            </Space>
          </Space>
          <Space size="large" className="modal__wrapper__buttongroup">
            <Space className="modal__wrapper__buttongroup__switch" align="center" size="small">
              <Typography.Paragraph className="modal__wrapper__buttongroup__switch__label">
                Add to Funnel Templates
              </Typography.Paragraph>
              <Switch onChange={() => setIsFunnelTemplate(!isFunnelTemplate)} />
            </Space>
            <Space size="large">
              <Button onClick={onClose} htmlType="reset" size="large" className="modal__wrapper__button">
                Cancel
              </Button>
              <Button type="primary" htmlType="submit" size="large" className="modal__wrapper__button">
                Save
              </Button>
            </Space>
          </Space>
        </Form>
      </Modal>
    </div>
  );
}
