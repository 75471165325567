import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import AppState from '@webapp/store/state';
// import { PROMO_CODE, PROMO_CODE_ERROR } from '@webapp/store/promo-code/actions';
import { GET_PROMO_CODE, GET_PROMO_CODE_ERROR } from '@webapp/store/promo-code/actions';

import './styles.less';

import { Typography, Modal, Space, Input, Button } from 'antd';
import { CloseCircleOutlined } from '@ant-design/icons';
import Form from 'antd/lib/form/Form';
import FormItem from 'antd/lib/form/FormItem';
import { GetPromoCode } from '../../store/promo-code/action-creators';

interface PromoCodeModalProps {
  className?: string;
  isModalVisible: boolean;
  handleClose: () => void;
}

export default function PromoCodeModal({ className, isModalVisible, handleClose }: PromoCodeModalProps): JSX.Element {
  const [code, setCode] = React.useState<string>('');

  const dispatch = useDispatch();

  const onFinish = () => {
    if (!code) {
      return;
    }
    dispatch(new GetPromoCode(code));
    // handleClose();
  };

  const loading = useSelector((state: AppState) => !!state.ui.loading[GET_PROMO_CODE]);

  const promoCodeFailed = useSelector((state: AppState) => state.ui.errors[GET_PROMO_CODE_ERROR]);

  return (
    <div className={`${className || ''} modal`}>
      <Modal
        className="modal__wrapper"
        visible={isModalVisible}
        maskClosable={true}
        centered={true}
        footer={null}
        width={500}
        closeIcon={<CloseCircleOutlined onClick={handleClose} className="modal__close" />}
      >
        <Space direction="vertical" size="large" align="center" className="modal__custom__space">
          <Typography.Title className="modal__wrapper__title has-subtitle" level={3}>
            Add Promo Code
          </Typography.Title>

          <Form name="promoCode" layout="vertical" initialValues={{ remember: false }} onFinish={onFinish}>
            <Space direction="vertical" align="center" size="large">
              <FormItem
                name="Name"
                className="modal__wrapper__form__item"
                label="Promo code"
                initialValue={code}
                rules={[{ required: true, message: 'Add a Promo code' }]}
              >
                <Input
                  className="modal__wrapper__form__item__input"
                  placeholder="Promo code"
                  size="large"
                  value={code}
                  onChange={(ev) => setCode(ev.target.value)}
                />
              </FormItem>

              {promoCodeFailed ? (
                <Typography.Text className="modal__wrapper__form__item__error" strong type="danger">
                  Invalid promo code
                </Typography.Text>
              ) : null}

              <Button
                type="primary"
                htmlType="submit"
                size="large"
                loading={loading}
                className="modal__wrapper__button"
              >
                Add PromoCode
              </Button>
            </Space>
          </Form>
        </Space>
      </Modal>
    </div>
  );
}
