import React from 'react';
import './styles.less';

import { CampaignCalulationStage, CampaignStage } from '../../../../backend/services/campaign/types/entities/campaign';

import { Typography } from 'antd';
import BarGraphItem, { BarGraphItemProps, StageType } from './bar-graph-item';

interface BarGraphProps {
  className?: string;
  itemClassName?: string;
  stages: CampaignStage[];
  costStage: CampaignCalulationStage;
  valueStage: CampaignCalulationStage;
  budget: number;
  revenue: number;
}

const getTotal = (
  _budget: number,
  _revenue: number,
  costStage: CampaignCalulationStage,
  valueStage: CampaignCalulationStage,
  stage: CampaignStage
): number | undefined => {
  if (costStage.name === stage.name) {
    return costStage.value;
  }
  if (valueStage.name === stage.name) {
    return valueStage.value;
  }
};

const getStageType = (costStageName: string, valueStageName: string, stageName: string): StageType => {
  if (costStageName === stageName) {
    return 'cost';
  }

  if (valueStageName == stageName) {
    return 'value';
  }

  return 'regular';
};

const getRatePercentage = (stages: CampaignStage[], i: number) => {
  return stages.reduce((memo, stage, j) => {
    if (j > i) {
      return memo;
    }

    return memo * (stage.conversionRate || 0);
  }, 100);
};

export default function BarGraph({
  className,
  itemClassName,
  stages,
  costStage,
  valueStage,
  budget,
  revenue,
}: BarGraphProps): JSX.Element {
  const CAMPAIGN_ITEMS: BarGraphItemProps[] = stages.map((stage, i) => {
    return {
      barClassName: itemClassName,
      orderNumber: i + 1,
      name: stage.name,
      total: getTotal(budget, revenue, costStage, valueStage, stage),
      number: stage.value,
      price: stage.value ? budget / stage.value : 0,
      barWidth: getRatePercentage(stages, i),
      conversionRate: stage.conversionRate || 0,
      stageType: getStageType(costStage.name, valueStage.name, stage.name),
    };
  });

  return (
    <div className={`${className || ''} campaign`}>
      <Typography.Title className="campaign__title" level={4}>
        Campaign
      </Typography.Title>
      <div className="campaign__content">
        {CAMPAIGN_ITEMS.map((item, i) => (
          <BarGraphItem key={`campaing-item-${i}`} {...item} />
        ))}
      </div>
    </div>
  );
}
