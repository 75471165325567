import { CallApi, handleJSONResponse } from './common';
import { ListCampaignResponse } from '../../../backend/services/campaign/functions/list-campaign/interface';
import {
  CreateCampaignBodyRequest,
  CreateCampaignResponse,
} from '../../../backend/services/campaign/functions/create-campaign/interface';
import {
  UpdateCampaignBodyRequest,
  UpdateCampaignResponse,
} from '../../../backend/services/campaign/functions/update-campaign/interface';
import { GetCampaignResponse } from '../../../backend/services/campaign/functions/get-campaign/interface';
import { DeleteCampaignResponse } from '../../../backend/services/campaign/functions/delete-campaign/interface';

const callAPI = CallApi('campaign');

export const list = async (clientId: string): Promise<ListCampaignResponse> => {
  const data = {
    limit: 500,
  };
  const response = await callAPI(`/${clientId}`, 'GET', data);

  return await handleJSONResponse(response);
};

export const create = async (
  clientId: string,
  campaign: CreateCampaignBodyRequest
): Promise<CreateCampaignResponse> => {
  const response = await callAPI(`/${clientId}`, 'POST', campaign);

  return await handleJSONResponse(response);
};

export const update = async (
  clientId: string,
  campaignId: string,
  campaign: UpdateCampaignBodyRequest
): Promise<UpdateCampaignResponse> => {
  const response = await callAPI(`/${clientId}/${campaignId}`, 'PUT', campaign);

  return await handleJSONResponse(response);
};

export const get = async (clientId: string, campaignId: string): Promise<GetCampaignResponse> => {
  const response = await callAPI(`/${clientId}/${campaignId}`, 'GET');

  return await handleJSONResponse(response);
};

export const remove = async (clientId: string, campaignId: string): Promise<DeleteCampaignResponse> => {
  const response = await callAPI(`/${clientId}/${campaignId}`, 'DELETE');

  return await handleJSONResponse(response);
};
