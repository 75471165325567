import { Observable } from 'rxjs';
import { ofType } from 'redux-observable';
import { mergeMap } from 'rxjs/operators';
import * as clients from '../../../sdk/client';
import { Actions } from '../../actions';
import { CreateClient, CreateClientSuccess, CreateClientError } from '../action-creators';
import { CREATE_CLIENT } from '../actions';
import ApiError from '../../../../../backend/commons/utils/api/api-error';

export const createClientEpic = (action$: Observable<Actions>): Observable<Actions> =>
  action$.pipe(
    ofType<Actions, CreateClient>(CREATE_CLIENT),
    mergeMap(async (action) => {
      try {
        const client = await clients.create(action.newClient);

        return new CreateClientSuccess(client);
      } catch (err) {
        return new CreateClientError((err as ApiError).message, (err as ApiError).description || '');
      }
    })
  );
