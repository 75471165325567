import { Observable } from 'rxjs';
import { ofType } from 'redux-observable';
import { mergeMap } from 'rxjs/operators';
import * as users from '../../../sdk/users';
import { Actions } from '../../actions';
import { UpdateUser, UpdateUserSuccess, UpdateUserError } from '../action-creators';
import { UPDATE_USER } from '../actions';
import ApiError from '../../../../../backend/commons/utils/api/api-error';

export const updateUserEpic = (action$: Observable<Actions>): Observable<Actions> =>
  action$.pipe(
    ofType<Actions, UpdateUser>(UPDATE_USER),
    mergeMap(async (action) => {
      try {
        const funnelType = await users.update(action.userId, action.user);

        return new UpdateUserSuccess(funnelType);
      } catch (err) {
        return new UpdateUserError((err as ApiError).message, (err as ApiError).description || '');
      }
    })
  );
