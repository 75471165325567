import React, { useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { push } from 'connected-react-router';

import { Button, Empty, Skeleton, Space, Typography } from 'antd';

import { NotificationOutlined } from '@ant-design/icons';

import Page from '@webapp/components/layout/page';
import ActionsTable from '@webapp/components/table/actions-table';
import CustomTextModal from '@webapp/components/modal/custom-text';
import ForecastEditorModal from '@webapp/components/modal/forecast-editor';

import { groupForecastsByCampaign } from '../../store/forecast/selectors';
import { selectCampaigns } from '../../store/campaign/selectors';
import { Forecast } from '../../../../backend/services/forecast/types/entities/forecast';
import { ColumnsType, ColumnType } from 'antd/lib/table';
import { DeleteForecast } from '../../store/forecast/action-creators';
import AppState from '../../store/state';
import { CREATE_FORECAST, LIST_FORECAST } from '../../store/forecast/actions';
import { selectFunnelTypes } from '../../store/funnel-type/selectors';
import { formatCurrency } from '@webapp/utils/format-currency';

export default function ForecastsPage(): JSX.Element {
  const dispatch = useDispatch();

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false);

  const [activeForecast, setActiveForecast] = useState<Partial<Forecast>>();

  const groupedForecasts = useSelector(groupForecastsByCampaign);
  const campaigns = useSelector(selectCampaigns);
  const funnelTypes = useSelector(selectFunnelTypes);
  const clientId = useSelector((state: AppState) => state.client.active?.id);
  const isLoadingList = useSelector((state: AppState) => state.ui.loading[LIST_FORECAST]);
  const isLoadingNew = useSelector((state: AppState) => !!state.ui.loading[CREATE_FORECAST]);

  const getByTitle = (campaignId: string) => {
    const campaign = campaigns.find((c) => c.id === campaignId);

    return campaign?.name || '?';
  };

  const getColumns = (campaignId: string): ColumnsType<Forecast> => {
    const campaign = campaigns.find((c) => c.id === campaignId);

    if (campaign) {
      return [
        {
          title: 'Name',
          dataIndex: 'name',
          key: `${campaignId}-name`,
        } as ColumnType<Forecast>,
        {
          title: 'Budget',
          dataIndex: 'budget',
          key: `${campaignId}-budget`,
          render: (_: string, forecast: Forecast) => {
            return formatCurrency(forecast.budget);
          },
        } as ColumnType<Forecast>,
        {
          title: 'Revenue',
          dataIndex: 'revenue',
          key: `${campaignId}-revenue`,
          render: (_: string, forecast: Forecast) => {
            return formatCurrency(forecast.revenue);
          },
        } as ColumnType<Forecast>,
      ].concat(
        campaign.stages.slice(-10).map(
          (stage, i) =>
            ({
              title: stage.name,
              dataIndex: `stages.${i}.value`,
              render: (_: string, forecast: Forecast) => {
                if (forecast.stages[i]?.value !== undefined) {
                  return `${forecast?.stages[i]?.value.toLocaleString()}`;
                }
                return '-';
              },
              key: `${campaignId}-${i}`,
            } as ColumnType<Forecast>)
        )
      );
    }

    return [];
  };

  const handleClose = () => {
    setIsModalVisible(false);
  };

  const handleView = (forecast: Forecast) => {
    dispatch(push(`/forecasts/${clientId}/${forecast.id}`));
  };

  const handleNew = () => {
    setActiveForecast(undefined);
    setIsModalVisible(true);
  };

  const handleEdit = (forecast: Forecast) => {
    setActiveForecast(forecast);
    setIsModalVisible(true);
  };

  const handleClone = (forecast: Forecast) => {
    setActiveForecast({
      ...forecast,
      name: `${forecast.name} Clone`,
      id: undefined,
      stages: forecast.stages.map((stage) => ({ ...stage })),
      costStage: { ...forecast.costStage },
      valueStage: { ...forecast.valueStage },
    });
    setIsModalVisible(true);
  };

  const handleDelete = () => {
    if (activeForecast?.id) {
      dispatch(new DeleteForecast(activeForecast.id));
    }
    handleCloseDelete();
  };

  const openDeleteModal = (forecast: Forecast) => {
    setActiveForecast(forecast);
    setIsDeleteModalVisible(true);
  };

  const handleCloseDelete = () => {
    setIsDeleteModalVisible(false);
  };

  return (
    <Page>
      <Space align="start" style={{ justifyContent: 'space-between', width: '100%' }}>
        <Typography.Title level={3}>Forecasts</Typography.Title>
        <Button type="primary" size="large" onClick={handleNew} loading={isLoadingNew}>
          New Forecast
        </Button>
      </Space>
      {isLoadingList ? (
        <Skeleton />
      ) : Object.keys(groupedForecasts).length ? (
        Object.keys(groupedForecasts).map((key) => {
          const forecasts = groupedForecasts[key];

          return (
            <ActionsTable<Forecast>
              key={key}
              title={getByTitle(key)}
              data={forecasts}
              keyMapper={(forecast) => forecast.id}
              dataColumns={getColumns(key)}
              handleView={handleView}
              handleEdit={handleEdit}
              handleDelete={openDeleteModal}
              handleClone={handleClone}
              icon={<NotificationOutlined />}
            />
          );
        })
      ) : (
        <Space
          className="forecast__empty"
          direction="vertical"
          align="center"
          style={{
            height: 'calc(100% - 80px - 88px)',
            width: '100%',
            marginTop: 80,
          }}
        >
          <Empty description={<Typography.Text>No Forecasts yet</Typography.Text>}>
            <Button type="primary" size="large" onClick={handleNew} loading={isLoadingNew}>
              New Forecast
            </Button>
          </Empty>
        </Space>
      )}
      {isModalVisible ? (
        <ForecastEditorModal
          isModalVisible={isModalVisible}
          handleClose={handleClose}
          campaigns={campaigns}
          funnelTypes={funnelTypes}
          forecast={activeForecast as Forecast}
        />
      ) : null}
      <CustomTextModal
        title="Are you sure you want to delete this Forecast?"
        text="You will not be able to undo this action"
        button={
          <Button danger onClick={handleDelete}>
            Delete
          </Button>
        }
        isModalVisible={isDeleteModalVisible}
        handleClose={handleCloseDelete}
      />
    </Page>
  );
}
