export const LIST_FORECAST = '[Forecast] List forecast';
export const LIST_FORECAST_SUCCESS = '[Forecast] List forecast Success';
export const LIST_FORECAST_ERROR = '[Forecast] List forecast Error';

export const CREATE_FORECAST = '[Forecast] Create forecast';
export const CREATE_FORECAST_SUCCESS = '[Forecast] Create forecast Success';
export const CREATE_FORECAST_ERROR = '[Forecast] Create forecast Error';

export const GET_FORECAST = '[Forecast] Get forecast';
export const GET_FORECAST_SUCCESS = '[Forecast] Get forecast Success';
export const GET_FORECAST_ERROR = '[Forecast] Get forecast Error';

export const UPDATE_FORECAST = '[Forecast] Update forecast';
export const UPDATE_FORECAST_SUCCESS = '[Forecast] Update forecast Success';
export const UPDATE_FORECAST_ERROR = '[Forecast] Update forecast Error';

export const DELETE_FORECAST = '[Forecast] Delete forecast';
export const DELETE_FORECAST_SUCCESS = '[Forecast] Delete forecast Success';
export const DELETE_FORECAST_ERROR = '[Forecast] Delete forecast Error';
