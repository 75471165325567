import * as actions from './actions';
import { LoadingAction } from '../common/loading-action';
import { ErrorAction } from '../common/error-action';
import { User } from '../../../../backend/services/user/types/entities/user';
import { ListUserResponse } from '../../../../backend/services/user/functions/list-user/interface';
import { UpdateUserBodyRequest } from '../../../../backend/services/user/functions/update-user/interface';
import { DeleteUserResponse } from '../../../../backend/services/user/functions/delete-user/interface';

export class ListUser implements LoadingAction {
  readonly type = actions.LIST_USER;
  readonly isLoading = true;
  readonly mainLoader = true;
}

export class ListUserSuccess implements LoadingAction {
  readonly type = actions.LIST_USER_SUCCESS;
  readonly isLoading = false;

  constructor(public results: ListUserResponse) {}
}

export class ListUserError implements ErrorAction {
  readonly type = actions.LIST_USER_ERROR;
  readonly isError = true;

  constructor(public code: string, public description: string | string[]) {}
}

export class UpdateUser implements LoadingAction {
  readonly type = actions.UPDATE_USER;
  readonly isLoading = true;
  readonly mainLoader = true;

  constructor(public userId: string, public user: UpdateUserBodyRequest) {}
}

export class UpdateUserSuccess implements LoadingAction {
  readonly type = actions.UPDATE_USER_SUCCESS;
  readonly isLoading = false;

  constructor(public user: User) {}
}

export class UpdateUserError implements ErrorAction {
  readonly type = actions.UPDATE_USER_ERROR;
  readonly isError = true;

  constructor(public code: string, public description: string | string[]) {}
}

export class DeleteUser implements LoadingAction {
  readonly type = actions.DELETE_USER;
  readonly isLoading = true;
  readonly mainLoader = true;

  constructor(public userId: string) {}
}

export class DeleteUserSuccess implements LoadingAction {
  readonly type = actions.DELETE_USER_SUCCESS;
  readonly isLoading = false;

  constructor(public userId: string, public result: DeleteUserResponse) {}
}

export class DeleteUserError implements ErrorAction {
  readonly type = actions.DELETE_USER_ERROR;
  readonly isError = true;

  constructor(public code: string, public description: string | string[]) {}
}

export type UserActions =
  | ListUser
  | ListUserSuccess
  | ListUserError
  | UpdateUser
  | UpdateUserSuccess
  | UpdateUserError
  | DeleteUser
  | DeleteUserSuccess
  | DeleteUserError;
