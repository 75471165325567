export const LIST_CLIENT = '[Client] List client';
export const LIST_CLIENT_SUCCESS = '[Client] List client Success';
export const LIST_CLIENT_ERROR = '[Client] List client Error';

export const CREATE_CLIENT = '[Client] Create client';
export const CREATE_CLIENT_SUCCESS = '[Client] Create client Success';
export const CREATE_CLIENT_ERROR = '[Client] Create client Error';

export const UPDATE_CLIENT = '[Client] Update client';
export const UPDATE_CLIENT_SUCCESS = '[Client] Update client Success';
export const UPDATE_CLIENT_ERROR = '[Client] Update client Error';

export const DELETE_CLIENT = '[Client] Delete client';
export const DELETE_CLIENT_SUCCESS = '[Client] Delete client Success';
export const DELETE_CLIENT_ERROR = '[Client] Delete client Error';

export const SET_ACTIVE_CLIENT = '[Client] Set active';
