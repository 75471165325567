import { CallApi, handleJSONResponse } from './common';
import { ListFunnelTypeResponse } from '../../../backend/services/funnel-type/functions/list-funnel-type/interface';
import {
  CreateFunnelTypeBodyRequest,
  CreateFunnelTypeResponse,
} from '../../../backend/services/funnel-type/functions/create-funnel-type/interface';
import {
  UpdateFunnelTypeBodyRequest,
  UpdateFunnelTypeResponse,
} from '../../../backend/services/funnel-type/functions/update-funnel-type/interface';
import { GetFunnelTypeResponse } from '../../../backend/services/funnel-type/functions/get-funnel-type/interface';
import { DeleteFunnelTypeResponse } from '../../../backend/services/funnel-type/functions/delete-funnel-type/interface';

const callAPI = CallApi('funnel-type');

export const list = async (clientId: string): Promise<ListFunnelTypeResponse> => {
  const data = {
    limit: 500,
  };
  const response = await callAPI(`/${clientId}`, 'GET', data);

  return await handleJSONResponse(response);
};

export const create = async (
  clientId: string,
  funnelType: CreateFunnelTypeBodyRequest
): Promise<CreateFunnelTypeResponse> => {
  const response = await callAPI(`/${clientId}`, 'POST', funnelType);

  return await handleJSONResponse(response);
};

export const update = async (
  clientId: string,
  funnelTypeId: string,
  funnelType: UpdateFunnelTypeBodyRequest
): Promise<UpdateFunnelTypeResponse> => {
  const response = await callAPI(`/${clientId}/${funnelTypeId}`, 'PUT', funnelType);

  return await handleJSONResponse(response);
};

export const get = async (clientId: string, funnelTypeId: string): Promise<GetFunnelTypeResponse> => {
  const response = await callAPI(`/${clientId}/${funnelTypeId}`, 'GET');

  return await handleJSONResponse(response);
};

export const remove = async (clientId: string, funnelTypeId: string): Promise<DeleteFunnelTypeResponse> => {
  const response = await callAPI(`/${clientId}/${funnelTypeId}`, 'DELETE');

  return await handleJSONResponse(response);
};
