import { combineEpics } from 'redux-observable';
import { createFunnelTemplateEpic } from './create-funnel-template';
import { updateFunnelTemplateEpic } from './update-funnel-template';
import { listFunnelTemplateEpic } from './list-funnel-templates';
import { listFunnelTemplateAfterActiveEpic } from './list-funnel-templates-after-active';
import { deleteFunnelTemplateEpic } from './delete-funnel-template';

const funnelTemplateEpic = combineEpics(
  listFunnelTemplateEpic,
  listFunnelTemplateAfterActiveEpic,
  createFunnelTemplateEpic,
  updateFunnelTemplateEpic,
  deleteFunnelTemplateEpic
);

export default funnelTemplateEpic;
