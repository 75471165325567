import { combineEpics } from 'redux-observable';
import { getSubscriptionEpic } from './get-subscription';
import { getSubscriptionAfterLoginEpic } from './get-subscription-after-login';
import { getSubscriptionAfterUpdateEpic } from './get-subscription-after-update';
import { updateSubscriptionEpic } from './update-subscription';
import { redirectAfterUpdateEpic } from './redirect-after-update';
import { validateSubscriptionEpic } from './validate-subscription';
import { cancelSubscriptionEpic } from './cancel-subscription';

const subscriptionEpic = combineEpics(
  getSubscriptionEpic,
  getSubscriptionAfterLoginEpic,
  getSubscriptionAfterUpdateEpic,
  updateSubscriptionEpic,
  redirectAfterUpdateEpic,
  validateSubscriptionEpic,
  cancelSubscriptionEpic
);

export default subscriptionEpic;
