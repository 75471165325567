import * as actions from './actions';
import { LoadingAction } from '../common/loading-action';
import { ErrorAction } from '../common/error-action';
import { Campaign } from '../../../../backend/services/campaign/types/entities/campaign';
import { CreateCampaignBodyRequest } from '../../../../backend/services/campaign/functions/create-campaign/interface';
import { ListCampaignResponse } from '../../../../backend/services/campaign/functions/list-campaign/interface';
import { UpdateCampaignBodyRequest } from '../../../../backend/services/campaign/functions/update-campaign/interface';
import { DeleteCampaignResponse } from '../../../../backend/services/campaign/functions/delete-campaign/interface';

export class ListCampaign implements LoadingAction {
  readonly type = actions.LIST_CAMPAIGN;
  readonly isLoading = true;
  readonly mainLoader = true;

  constructor(public clientId: string) {}
}

export class ListCampaignSuccess implements LoadingAction {
  readonly type = actions.LIST_CAMPAIGN_SUCCESS;
  readonly isLoading = false;

  constructor(public results: ListCampaignResponse) {}
}

export class ListCampaignError implements ErrorAction {
  readonly type = actions.LIST_CAMPAIGN_ERROR;
  readonly isError = true;

  constructor(public code: string, public description: string | string[]) {}
}

export class CreateCampaign implements LoadingAction {
  readonly type = actions.CREATE_CAMPAIGN;
  readonly isLoading = true;
  readonly mainLoader = true;

  constructor(public newCampaign: CreateCampaignBodyRequest) {}
}

export class CreateCampaignSuccess implements LoadingAction {
  readonly type = actions.CREATE_CAMPAIGN_SUCCESS;
  readonly isLoading = false;

  constructor(public campaign: Campaign) {}
}

export class CreateCampaignError implements ErrorAction {
  readonly type = actions.CREATE_CAMPAIGN_ERROR;
  readonly isError = true;

  constructor(public code: string, public description: string | string[]) {}
}

export class GetCampaign implements LoadingAction {
  readonly type = actions.GET_CAMPAIGN;
  readonly isLoading = true;
  readonly mainLoader = true;

  constructor(public clientId: string, public campaignId: string) {}
}

export class GetCampaignSuccess implements LoadingAction {
  readonly type = actions.GET_CAMPAIGN_SUCCESS;
  readonly isLoading = false;

  constructor(public campaign: Campaign) {}
}

export class GetCampaignError implements ErrorAction {
  readonly type = actions.GET_CAMPAIGN_ERROR;
  readonly isError = true;

  constructor(public code: string, public description: string | string[]) {}
}

export class UpdateCampaign implements LoadingAction {
  readonly type = actions.UPDATE_CAMPAIGN;
  readonly isLoading = true;
  readonly mainLoader = true;

  constructor(public campaignId: string, public campaign: UpdateCampaignBodyRequest) {}
}

export class UpdateCampaignSuccess implements LoadingAction {
  readonly type = actions.UPDATE_CAMPAIGN_SUCCESS;
  readonly isLoading = false;

  constructor(public campaign: Campaign) {}
}

export class UpdateCampaignError implements ErrorAction {
  readonly type = actions.UPDATE_CAMPAIGN_ERROR;
  readonly isError = true;

  constructor(public code: string, public description: string | string[]) {}
}

export class DeleteCampaign implements LoadingAction {
  readonly type = actions.DELETE_CAMPAIGN;
  readonly isLoading = true;
  readonly mainLoader = true;

  constructor(public campaignId: string) {}
}

export class DeleteCampaignSuccess implements LoadingAction {
  readonly type = actions.DELETE_CAMPAIGN_SUCCESS;
  readonly isLoading = false;

  constructor(public campaignId: string, public result: DeleteCampaignResponse) {}
}

export class DeleteCampaignError implements ErrorAction {
  readonly type = actions.DELETE_CAMPAIGN_ERROR;
  readonly isError = true;

  constructor(public code: string, public description: string | string[]) {}
}

export type CampaignActions =
  | ListCampaign
  | ListCampaignSuccess
  | ListCampaignError
  | CreateCampaign
  | CreateCampaignSuccess
  | CreateCampaignError
  | GetCampaign
  | GetCampaignSuccess
  | GetCampaignError
  | UpdateCampaign
  | UpdateCampaignSuccess
  | UpdateCampaignError
  | DeleteCampaign
  | DeleteCampaignSuccess
  | DeleteCampaignError;
