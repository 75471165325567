import { PromoCodeState } from './state';
import { PromoCodeInitialState } from './initial-state';
import { PromoCodeActions } from './action-creators';
import * as actions from './actions';

export const promoCodeReducer = (
  state: PromoCodeState = PromoCodeInitialState,
  action: PromoCodeActions
): PromoCodeState => {
  switch (action.type) {
    case actions.RESET_PROMO_CODE: {
      return {
        ...state,
        promoCode: undefined,
      };
    }

    case actions.GET_PROMO_CODE_SUCCESS: {
      return {
        ...state,
        promoCode: action.coupon,
      };
    }

    default: {
      return { ...state };
    }
  }
};
