import { Observable } from 'rxjs';
import { ofType } from 'redux-observable';
import { mergeMap } from 'rxjs/operators';
import * as invites from '../../../sdk/invites';
import { Actions } from '../../actions';
import { ListInvite, ListInviteSuccess, ListInviteError } from '../action-creators';
import { LIST_INVITE } from '../actions';
import ApiError from '../../../../../backend/commons/utils/api/api-error';

export const listInviteEpic = (action$: Observable<Actions>): Observable<Actions> =>
  action$.pipe(
    ofType<Actions, ListInvite>(LIST_INVITE),
    mergeMap(async () => {
      try {
        const inviteList = await invites.list();

        return new ListInviteSuccess(inviteList);
      } catch (err) {
        return new ListInviteError((err as ApiError).message, (err as ApiError).description || '');
      }
    })
  );
