import { Observable } from 'rxjs';
import { ofType } from 'redux-observable';
import { map } from 'rxjs/operators';
import { Actions } from '../../actions';
import { ListInvite } from '../action-creators';
import { LoginUserSuccess } from '../../auth/action-creators';
import { LOGIN_USER_SUCCESS } from '../../auth/actions';

export const listInviteAfterLoginEpic = (action$: Observable<Actions>): Observable<Actions> =>
  action$.pipe(
    ofType<Actions, LoginUserSuccess>(LOGIN_USER_SUCCESS),
    map(() => new ListInvite())
  );
