import { UserState } from './state';
import { userInitialState } from './initial-state';
import { UserActions } from './action-creators';
import * as actions from './actions';

export const userReducer = (state: UserState = userInitialState, action: UserActions): UserState => {
  switch (action.type) {
    case actions.LIST_USER_SUCCESS: {
      return {
        ...state,
        items: action.results.items || [],
        nextToken: action.results.nextToken,
      };
    }

    case actions.UPDATE_USER_SUCCESS: {
      return {
        ...state,
        items: state.items.map((c) => {
          if (c.id === action.user.id) {
            return {
              ...c,
              ...action.user,
            };
          }
          return { ...c };
        }),
      };
    }

    case actions.DELETE_USER_SUCCESS: {
      return {
        ...state,
        items: state.items.filter((c) => c.id !== action.userId),
      };
    }

    default: {
      return { ...state };
    }
  }
};
