import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Form, Input, Button, Typography, notification, Select } from 'antd';
import Checkbox from 'antd/lib/checkbox/Checkbox';

import FormWrapper from './form-wrapper';

import { CreateAccount } from '../../store/auth/action-creators';
import BasicAccountData from '../../types/auth/basic-account-data';
import { CompanyType } from '../../types/auth/company-type';
import { Link } from 'react-router-dom';
import AppState from '@webapp/store/state';

import { CREATE_ACCOUNT } from '@webapp/store/auth/actions';
interface RegistrationFormData extends BasicAccountData {
  password2: string;
  terms: boolean;
}

const { Option } = Select;

export default function RegistrationForm(): JSX.Element {
  const dispatch = useDispatch();

  const [isTermshecked, setIsTermshecked] = useState(false);
  const [companyTypeValue, setCompanyTypeValue] = useState<CompanyType>('sales');

  const loading = useSelector((state: AppState) => !!state.ui.loading[CREATE_ACCOUNT]);

  const onFinish = (values: RegistrationFormData) => {
    const { password2, terms, ...account } = values;

    if (!terms) {
      return;
    }

    if (!account['custom:companyType']) {
      return;
    }

    if (account.password !== password2) {
      notification.error({
        message: 'Error',
        description: 'Passwords do not match',
      });
      return;
    }

    dispatch(new CreateAccount(account));
  };

  return (
    <FormWrapper title="Register">
      <Form name="basic" layout="vertical" initialValues={{ remember: true }} onFinish={onFinish}>
        <Form.Item
          className="form__wrapper__item"
          name="given_name"
          rules={[{ required: true, message: 'Please input your First Name!' }]}
        >
          <Input placeholder="First Name" size="large" />
        </Form.Item>

        <Form.Item
          className="form__wrapper__item"
          name="family_name"
          rules={[{ required: true, message: 'Please input your Last Name!' }]}
        >
          <Input placeholder="Last Name" size="large" />
        </Form.Item>

        <Form.Item
          className="form__wrapper__item"
          name="custom:company"
          rules={[{ required: true, message: 'Please input your Company Name!' }]}
        >
          <Input placeholder="Company" size="large" />
        </Form.Item>

        <Form.Item
          className="form__wrapper__item"
          name="custom:companyType"
          rules={[{ required: true, message: 'Please select your Company Type!' }]}
        >
          <Select
            size="large"
            value={companyTypeValue}
            onSelect={setCompanyTypeValue}
            placeholder="Select an Account Type"
          >
            <Option value="sales">Marketing/Sales Team</Option>
            <Option value="agency">Agency</Option>
          </Select>
        </Form.Item>

        <Form.Item
          className="form__wrapper__item"
          name="email"
          rules={[{ required: true, message: 'Please input your Work Email!' }]}
        >
          <Input placeholder="Work Email" size="large" />
        </Form.Item>

        <Form.Item
          className="form__wrapper__item"
          name="password"
          rules={[{ required: true, message: 'Please input your password!' }]}
        >
          <Input.Password size="large" placeholder="Password" />
        </Form.Item>

        <Form.Item
          className="form__wrapper__item"
          name="password2"
          rules={[{ required: true, message: 'Please input your password!' }]}
        >
          <Input.Password size="large" placeholder="Confirm Password" />
        </Form.Item>

        <Form.Item className="form__wrapper__item" name="terms" valuePropName="checked" initialValue={isTermshecked}>
          <Checkbox checked={isTermshecked} onChange={() => setIsTermshecked(!isTermshecked)}>
            I acknowledge that I have read and agree to the{' '}
            <a href="https://www.funneltap.com/terms-conditions" target="_blank" rel="noopener noreferrer">
              Terms and Conditions
            </a>{' '}
            and{' '}
            <a href="https://www.funneltap.com/privacy-policy" target="_blank" rel="noopener noreferrer">
              Privacy Policy
            </a>
            .
          </Checkbox>
        </Form.Item>

        <Form.Item className="form__wrapper__button">
          <Button type="primary" htmlType="submit" size="large" loading={loading} disabled={!isTermshecked}>
            Create Account
          </Button>
          <Typography.Paragraph className="form__wrapper__button__register">
            Already have an account? <Link to="/login">Log in!</Link>
          </Typography.Paragraph>
        </Form.Item>
      </Form>
    </FormWrapper>
  );
}
