import React from 'react';

import './styles.less';

import { Table, Typography, Space, Tooltip } from 'antd';
import { EyeOutlined } from '@ant-design/icons';
import moment from 'moment';
import { Invoice } from '../../../../../backend/services/invoice/types/entities/invoice';
import { useSelector } from 'react-redux';
import AppState from '../../../store/state';

interface InvoicesProps {
  className?: string;
}

export default function Invoices({ className }: InvoicesProps): JSX.Element {
  const columns = [
    {
      title: 'Message',
      dataIndex: 'description',
      key: 'description',
    },
    {
      title: 'Date',
      dataIndex: 'invoiceDate',
      key: 'invoiceDate',
      render: (value: string) => moment(value).format('LL'),
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      render: (value: string) => <Typography.Text className={`invoices__status ${value}`}>{value}</Typography.Text>,
    },
    {
      title: '',
      dataIndex: 'view',
      key: 'view',
      render: (_: string, invoice: Invoice) => (
        <Space size="large">
          <Tooltip title="View">
            <EyeOutlined className="table__actions__icon gray" onClick={() => window.open(invoice.url)} />
          </Tooltip>
        </Space>
      ),
    },
  ];

  const invoices: Invoice[] = useSelector((state: AppState) => state.invoice.list.items);

  return (
    <div className={`${className} invoices`}>
      <Typography.Title level={4} className="invoices__title">
        Invoices
      </Typography.Title>
      {!invoices.length ? (
        <Typography.Text>No invoices yet</Typography.Text>
      ) : (
        <Table style={{ width: '100%' }} pagination={false} dataSource={invoices} columns={columns} />
      )}
    </div>
  );
}
