import { SubscriptionState } from './state';
import { subscriptionInitialState } from './initial-state';
import { SubscriptionActions } from './action-creators';
import * as actions from './actions';

export const subscriptionReducer = (
  state: SubscriptionState = subscriptionInitialState,
  action: SubscriptionActions
): SubscriptionState => {
  switch (action.type) {
    case actions.GET_SUBSCRIPTION_SUCCESS: {
      return {
        ...state,
        current: action.subscription,
      };
    }

    case actions.UPDATE_SUBSCRIPTION_SUCCESS: {
      return {
        ...state,
        current: action.subscription,
      };
    }

    case actions.CANCEL_SUBSCRIPTION_SUCCESS: {
      return {
        ...state,
        current: action.subscription,
      };
    }

    case actions.SET_SELECTED_SUBSCRIPTION: {
      return {
        ...state,
        selected: {
          frequency: action.frequecy,
          product: action.product,
        },
      };
    }

    default: {
      return { ...state };
    }
  }
};
