import React, { useState } from 'react';
import './styles.less';

import { Button, Space, Typography } from 'antd';

import Page from '@webapp/components/layout/page';
import CustomTextModal from '@webapp/components/modal/custom-text';
import MemberEditorModal from '@webapp/components/modal/member-editor';
import MembersTable from '@webapp/components/table/members-table';

import { Invite } from '../../../../backend/services/invite/types/entities/invite';
import moment from 'moment';
import { ColumnsType } from 'antd/lib/table';
import { useDispatch, useSelector } from 'react-redux';
import AppState from '../../store/state';
import { User } from '../../../../backend/services/user/types/entities/user';
import { DeleteUser } from '../../store/user/action-creators';
import { DeleteInvite, ResendInvite } from '../../store/invite/action-creators';
import { CREATE_INVITE } from '../../store/invite/actions';

export default function TeamPage(): JSX.Element {
  const dispatch = useDispatch();

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false);
  const [selectedMember, setSelectedMember] = useState<User>();

  const invites = useSelector((state: AppState) => state.invite.list.items);
  const users = useSelector((state: AppState) => state.user.items);
  const isLoadingNew = useSelector((state: AppState) => !!state.ui.loading[CREATE_INVITE]);

  const handleNew = () => {
    setIsModalVisible(true);
  };

  const handleClose = () => {
    setIsModalVisible(false);
  };

  const handleDelete = () => {
    if (selectedMember) {
      dispatch(new DeleteUser(selectedMember.id));
    }
    handleCloseDelete();
  };

  const handleOpenDelete = (user: User) => {
    setSelectedMember(user);
    setIsDeleteModalVisible(true);
  };

  const handleCloseDelete = () => {
    setIsDeleteModalVisible(false);
  };

  const handleResend = (invite: Invite) => {
    dispatch(new ResendInvite(invite.id));
  };

  const handleCancel = (invite: Invite) => {
    dispatch(new DeleteInvite(invite.id));
  };

  const getColumns = <T extends Invite | User>(): ColumnsType<T> => [
    {
      title: 'Name',
      dataIndex: 'name',
      key: `name`,
      render: (_, item) => `${item.givenName} ${item.familyName}`,
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: `email`,
    },
    {
      title: 'Date Added',
      dataIndex: 'date-added',
      key: `createdAt`,
      render: (_, item) => {
        return `${moment(item.createdAt).format('LL')}`;
      },
    },
    {
      title: 'Role',
      dataIndex: 'role',
      key: `role`,
      render: (_, item) => {
        return 'isOwner' in item ? ((item as User).isOwner ? 'Owner' : 'Member') : 'Member';
      },
    },
  ];

  return (
    <Page className="team">
      <Space align="start" style={{ justifyContent: 'space-between', width: '100%' }}>
        <Typography.Title level={3}>Team</Typography.Title>
        <Button type="primary" size="large" onClick={handleNew} loading={isLoadingNew}>
          New Member
        </Button>
      </Space>
      <MembersTable<User>
        className="team__table"
        data={users}
        dataColumns={getColumns<User>()}
        keyMapper={(invite) => invite.email}
        handleDelete={handleOpenDelete}
        isAccepted
        isDeleting={false}
      />
      {invites.length ? (
        <>
          <Space
            align="start"
            style={{
              justifyContent: 'space-between',
              width: '100%',
              marginTop: '4rem',
            }}
          >
            <Typography.Title level={3}>Pending Invites</Typography.Title>
          </Space>
          <MembersTable<Invite>
            className="team__table"
            data={invites}
            dataColumns={getColumns<Invite>()}
            keyMapper={(invite) => invite.email}
            handleResend={handleResend}
            handleCancel={handleCancel}
            isAccepted={false}
            isDeleting={false}
          />
        </>
      ) : null}
      {isModalVisible ? <MemberEditorModal isModalVisible={isModalVisible} handleClose={handleClose} /> : null}
      <CustomTextModal
        title="Are you sure you want to delete this member?"
        text="You will not be able to undo this action"
        button={
          <Button danger onClick={handleDelete}>
            Delete member
          </Button>
        }
        isModalVisible={isDeleteModalVisible}
        handleClose={handleCloseDelete}
      />
    </Page>
  );
}
