import React from 'react';
import './styles.less';

import { Space } from 'antd';

import Toggle from '@webapp/components/toggle';
import PlanCard from '@webapp/components/plan-card';

import { useDispatch, useSelector } from 'react-redux';
import { push } from 'connected-react-router';
import AppState from '../../store/state';
import { PaymentFrequency } from '../../../../backend/services/product/types/entities/price';
import { Product } from '../../../../backend/services/product/types/entities/product';
import { SetSelectedSubscription } from '../../store/subscriptions/action-creators';

const TOGGLE_OPTIONS: { label: string; value: PaymentFrequency }[] = [
  { label: 'Monthly', value: 'month' },
  { label: 'Annual', value: 'year' },
];

// const PLAN_OPTIONS = [
//   {
//     planName: 'Monthly Plan',
//     value: 'monthly',
//     originalPrice: '$50/month',
//     finalPrice: '$39/month',
//     features: [
//       'Unlimited clients',
//       'Unlimited funnels',
//       'Unlimited campaigns',
//       'Unlimited scenarios',
//     ],
//     extras: ['Each additional user on the same account is $19/month.'],
//   },
//   {
//     planName: 'Annual Plan',
//     value: 'annual',
//     originalPrice: '$468/annual',
//     finalPrice: '$250/annual',
//     features: [
//       'Unlimited clients',
//       'Unlimited funnels',
//       'Unlimited campaigns',
//       'Unlimited scenarios',
//     ],
//     extras: ['Each additional user on the same account is $140/year.'],
//   },
// ];

interface PlanWrapperProps {
  className?: string;
}

export default function PlanWrapper({ className }: PlanWrapperProps): JSX.Element {
  const [activeFrequency, setActiveFrequency] = React.useState<PaymentFrequency>(TOGGLE_OPTIONS[0].value);
  const dispatch = useDispatch();

  const handlePlan = (product: Product) => {
    dispatch(new SetSelectedSubscription(activeFrequency, product));
    dispatch(push('/checkout'));
  };

  const plans = useSelector((state: AppState) =>
    state.products.list.items.filter((p) => p.category === 'SUBSCRIPTION')
  );

  return (
    <Space className={`${className || ''} plan__wrapper`} direction="vertical" align="center" size="large">
      <Toggle
        options={TOGGLE_OPTIONS}
        active={activeFrequency}
        onChange={(value: string) => setActiveFrequency(value as PaymentFrequency)}
      />
      {plans.map((plan) => (
        <PlanCard key={plan.id} activeFrequency={activeFrequency} product={plan} onSelected={handlePlan} />
      ))}
    </Space>
  );
}
