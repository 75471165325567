import React from 'react';
import { push } from 'connected-react-router';
import { useDispatch, useSelector } from 'react-redux';

import AppState from '@webapp/store/state';
import {
  getSubscriptionEndDate,
  isSubscriptionTrial,
  selectCurrentSubscription,
} from '@webapp/store/subscriptions/selectors';

// import { Price } from '../../../../../backend/services/product/types/entities/price';

import './styles.less';

import { Button, Space, Typography } from 'antd';
import { formatCurrency } from '../../../utils/format-currency';

interface CurrentSubscriptionProps {
  className?: string;
}

export default function CurrentSubscription({ className }: CurrentSubscriptionProps): JSX.Element {
  const dispatch = useDispatch();
  const isTrialVersion = useSelector(isSubscriptionTrial);
  const subscription = useSelector(selectCurrentSubscription);
  const subscriptionEndDate = useSelector(getSubscriptionEndDate);

  const handleUpgrade = () => {
    dispatch(push('/plans'));
  };

  const userCount = useSelector((state: AppState) => state.user.items.length - 1);

  return (
    <div className={`${className} subscription__current`}>
      <Space direction="vertical" size="middle" className="subscription__current__header">
        <Space size="large">
          <Space>
            <Typography.Title level={4} className="subscription__current__title">
              Current Plan:
            </Typography.Title>
            <Typography.Title level={3} className="subscription__current__plan">
              {isTrialVersion ? 'Free Trial' : subscription?.product?.name}
            </Typography.Title>
          </Space>
          {isTrialVersion ? (
            <Button type="primary" onClick={handleUpgrade}>
              Upgrade
            </Button>
          ) : null}
        </Space>
      </Space>
      {subscriptionEndDate ? (
        <Typography.Text className="subscription__current__price">
          <b>Ends: {subscriptionEndDate.format('LL')}</b>
        </Typography.Text>
      ) : null}
      <Typography.Text className="subscription__current__price">
        <b>Price:</b> {formatCurrency((subscription?.price?.flatValue || 0) / 100)}
      </Typography.Text>
      <Typography.Text className="subscription__current__frequency">
        <b>Frequency:</b> {subscription?.price?.frequency === 'month' ? 'Monthly' : 'Yearly'}
      </Typography.Text>
      <Typography.Text className="subscription__current__team">
        <b>Team members:</b> {userCount}
      </Typography.Text>
      <Typography.Text className="subscription__current__team__extra">
        <b>Price for each member:</b> {formatCurrency((subscription?.price?.extraUnitValue || 0) / 100)}
      </Typography.Text>
    </div>
  );
}
