import * as actions from './actions';
import { LoadingAction } from '../common/loading-action';
import { ErrorAction } from '../common/error-action';
import { GetCouponResponse } from '../../../../backend/services/stripe-connector/functions/get-coupon/interface';
import { Action } from 'redux';

export class GetPromoCode implements LoadingAction {
  readonly type = actions.GET_PROMO_CODE;
  readonly isLoading = true;
  readonly mainLoader = true;

  constructor(public couponId: string) {}
}

export class GetPromoCodeSuccess implements LoadingAction {
  readonly type = actions.GET_PROMO_CODE_SUCCESS;
  readonly isLoading = false;

  constructor(public coupon: GetCouponResponse) {}
}

export class GetPromoCodeError implements ErrorAction {
  readonly type = actions.GET_PROMO_CODE_ERROR;
  readonly isError = true;

  constructor(public code: string, public description: string | string[]) {}
}

export class ResetPromoCode implements Action {
  readonly type = actions.RESET_PROMO_CODE;
}

export type PromoCodeActions = GetPromoCode | GetPromoCodeSuccess | GetPromoCodeError | ResetPromoCode;
