import { InviteState } from './state';
import { inviteInitialState } from './initial-state';
import { InviteActions } from './action-creators';
import * as actions from './actions';

export const inviteReducer = (state: InviteState = inviteInitialState, action: InviteActions): InviteState => {
  switch (action.type) {
    case actions.LIST_INVITE_SUCCESS: {
      return {
        ...state,
        list: {
          items: action.results.items || [],
          nextToken: action.results.nextToken,
        },
      };
    }

    case actions.CREATE_INVITE_SUCCESS: {
      return {
        ...state,
        list: {
          ...state.list,
          items: state.list.items.concat([action.invite]),
        },
      };
    }

    case actions.DELETE_INVITE_SUCCESS: {
      return {
        ...state,
        list: {
          ...state.list,
          items: state.list.items.filter((c) => c.id !== action.inviteId),
        },
      };
    }

    case actions.GET_INVITE: {
      return {
        ...state,
        active: undefined,
      };
    }

    case actions.GET_INVITE_SUCCESS: {
      return {
        ...state,
        active: action.invite,
      };
    }

    default: {
      return { ...state };
    }
  }
};
