import { combineEpics } from 'redux-observable';
import { createCampaignEpic } from './create-campaign';
import { updateCampaignEpic } from './update-campaign';
import { listCampaignEpic } from './list-campaigns';
import { listCampaignAfterActiveEpic } from './list-campaigns-after-active';
import { deleteCampaignEpic } from './delete-campaign';
import { getCampaignEpic } from './get-campaign';

const campaignEpic = combineEpics(
  listCampaignEpic,
  listCampaignAfterActiveEpic,
  getCampaignEpic,
  createCampaignEpic,
  updateCampaignEpic,
  deleteCampaignEpic
);

export default campaignEpic;
