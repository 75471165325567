import { ForecastState } from './state';
import { forecastInitialState } from './initial-state';
import { ForecastActions } from './action-creators';
import * as actions from './actions';

export const forecastReducer = (
  state: ForecastState = forecastInitialState,
  action: ForecastActions
): ForecastState => {
  switch (action.type) {
    case actions.LIST_FORECAST_SUCCESS: {
      return {
        ...state,
        list: {
          items: action.results.items || [],
          nextToken: action.results.nextToken,
        },
      };
    }

    case actions.CREATE_FORECAST_SUCCESS: {
      return {
        ...state,
        list: {
          ...state.list,
          items: state.list.items.concat([action.forecast]),
        },
      };
    }

    case actions.UPDATE_FORECAST_SUCCESS: {
      return {
        ...state,
        list: {
          ...state.list,
          items: state.list.items.map((c) => {
            if (c.id === action.forecast.id) {
              return action.forecast;
            }
            return { ...c };
          }),
        },
        active: state.active?.id === action.forecast.id ? { ...action.forecast } : state.active,
      };
    }

    case actions.DELETE_FORECAST_SUCCESS: {
      return {
        ...state,
        list: {
          ...state.list,
          items: state.list.items.filter((c) => c.id !== action.forecastId),
        },
      };
    }

    case actions.GET_FORECAST: {
      return {
        ...state,
        active: undefined,
      };
    }

    case actions.GET_FORECAST_SUCCESS: {
      return {
        ...state,
        active: action.forecast,
      };
    }

    default: {
      return { ...state };
    }
  }
};
