import { Observable } from 'rxjs';
import { Actions } from '../../actions';
import * as authActionCreators from '../../auth/action-creators';
import * as authActions from '../../auth/actions';
import { ofType } from 'redux-observable';
import { map } from 'rxjs/operators';
import { push } from 'connected-react-router';
import { Action } from 'redux';
import { notification } from 'antd';

export const redirectVerificationSuccessEpic = (action$: Observable<Actions>): Observable<Action> =>
  action$.pipe(
    ofType<Actions, authActionCreators.VerifyAccountSuccess>(authActions.VERIFY_ACCOUNT_SUCCESS),
    map(() => {
      notification.success({
        message: 'The verification was successful!',
        description: 'Please login again to access your new account.',
      });

      return push('/login');
    })
  );
