import { Observable } from 'rxjs';
import { Action } from 'redux';
import { ofType } from 'redux-observable';
import { push } from 'connected-react-router';
import { map } from 'rxjs/operators';
import { Actions } from '../../actions';
import { UPDATE_SUBSCRIPTION_SUCCESS } from '../actions';
import { UpdateSubscriptionSuccess } from '../action-creators';

export const redirectAfterUpdateEpic = (action$: Observable<Actions>): Observable<Action> =>
  action$.pipe(
    ofType<Actions, UpdateSubscriptionSuccess>(UPDATE_SUBSCRIPTION_SUCCESS),
    map(() => push('/clients'))
  );
