import React, { useState } from 'react';
import './styles.less';

import { Button, Empty, Space, Typography } from 'antd';

import Page from '@webapp/components/layout/page';

import { useDispatch, useSelector } from 'react-redux';
import AppState from '../../store/state';

import { FunnelType } from '@backend/services/funnel-type/types/entities/funnel-type';

import { selectFunnelTemplates } from '@webapp/store/funnel-template/selectors';
import { CREATE_FUNNEL_TEMPLATE } from '@webapp/store/funnel-template/actions';
import { FunnelTemplate } from '@backend/services/funnel-template/types/entities/funnel-template';
import {
  CreateFunnelTemplate,
  DeleteFunnelTemplate,
  UpdateFunnelTemplate,
} from '@webapp/store/funnel-template/action-creators';
import { UpdateFunnelTemplateBodyRequest } from '@backend/services/funnel-template/functions/update-funnel-template/interface';
import { CreateFunnelTemplateBodyRequest } from '@backend/services/funnel-template/functions/create-funnel-template/interface';

import CustomTextModal from '@webapp/components/modal/custom-text';
import FunnelTemplateTable from '@webapp/components/table/funnel-template-table';
import FunnelTemplateEditorModal from '@webapp/components/modal/funnel-template-editor';

export default function FunnelTemplatesPage(): JSX.Element {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false);

  const [selectedFunnelTemplate, setSelectedFunnelTemplate] = useState<Partial<FunnelType>>();

  const funnelTemplates = useSelector(selectFunnelTemplates);
  const isLoadingNew = useSelector((state: AppState) => !!state.ui.loading[CREATE_FUNNEL_TEMPLATE]);

  const dispatch = useDispatch();

  const handleNew = () => {
    setSelectedFunnelTemplate(undefined);
    setIsModalVisible(true);
  };

  const handleClose = () => {
    setIsModalVisible(false);
  };

  const handleCloseDelete = () => {
    setIsDeleteModalVisible(false);
  };

  const handleEdit = (funnelTemplate: FunnelTemplate) => {
    setSelectedFunnelTemplate(funnelTemplate);
    setIsModalVisible(true);
  };

  const handleClone = (funnelTemplate: FunnelTemplate) => {
    setSelectedFunnelTemplate({
      ...funnelTemplate,
      name: `${funnelTemplate.name} Clone`,
      id: undefined,
    });
    setIsModalVisible(true);
  };

  const handleDelete = () => {
    if (selectedFunnelTemplate?.id) {
      dispatch(new DeleteFunnelTemplate(selectedFunnelTemplate.id));
    }
    handleCloseDelete();
  };

  const openDeleteModal = (funnelTemplate: FunnelTemplate) => {
    setSelectedFunnelTemplate(funnelTemplate);
    setIsDeleteModalVisible(true);
  };

  const handleSave = (funnelTemplate: Partial<FunnelTemplate>) => {
    if (funnelTemplate.id) {
      setSelectedFunnelTemplate(funnelTemplate as FunnelTemplate);
      return dispatch(new UpdateFunnelTemplate(funnelTemplate.id, funnelTemplate as UpdateFunnelTemplateBodyRequest));
    }

    return dispatch(new CreateFunnelTemplate(funnelTemplate as CreateFunnelTemplateBodyRequest));
  };

  return (
    <Page className="funnel-templates">
      <Space align="start" style={{ justifyContent: 'space-between', width: '100%' }}>
        <Typography.Title level={3}>Funnel Templates</Typography.Title>
        <Button type="primary" size="large" onClick={handleNew} loading={isLoadingNew}>
          New Template
        </Button>
      </Space>
      {funnelTemplates.length ? (
        <FunnelTemplateTable
          funnelTemplates={funnelTemplates}
          onEdit={handleEdit}
          onDelete={openDeleteModal}
          onClone={handleClone}
        />
      ) : (
        <Space
          className="funnel__empty"
          direction="vertical"
          align="center"
          style={{
            height: 'calc(100% - 80px - 88px)',
            width: '100%',
            marginTop: 80,
          }}
        >
          <Empty description={<Typography.Text>No Templates yet</Typography.Text>}>
            <Button type="primary" size="large" onClick={handleNew} loading={isLoadingNew}>
              New Template
            </Button>
          </Empty>
        </Space>
      )}
      {isModalVisible ? (
        <FunnelTemplateEditorModal
          funnelTemplate={selectedFunnelTemplate as FunnelTemplate}
          isModalVisible={isModalVisible}
          onClose={handleClose}
          onSave={handleSave}
        />
      ) : null}
      <CustomTextModal
        title="Are you sure you want to delete this Funnel Template?"
        text="You will not be able to undo this action"
        button={
          <Button danger onClick={handleDelete}>
            Delete
          </Button>
        }
        isModalVisible={isDeleteModalVisible}
        handleClose={handleCloseDelete}
      />
    </Page>
  );
}
