import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import AppState from '@webapp/store/state';

import './styles.less';

import { Button, Select, Typography } from 'antd';
import { Client } from '@backend/services/client/types/entities/client';
import { Image } from '@webapp/components/image';

import { LIST_CLIENT } from '@webapp/store/client/actions';
import { push } from 'connected-react-router';
import { SetActiveClient } from '../../store/client/action-creators';
import { getClientLabel } from '@webapp/store/auth/selectors';

const { Option } = Select;

interface ClientSelectProps {
  options: Client[];
  active?: Client;
  className?: string;
}

export default function ClientSelect({ options, className, active }: ClientSelectProps): JSX.Element {
  const dispatch = useDispatch();

  const loading = useSelector((state: AppState) => !!state.ui.loading[LIST_CLIENT]);

  const handleManage = () => {
    dispatch(push('/clients'));
  };

  const handleChange = (value: string) => {
    if (value === 'empty') {
      return;
    }

    const client = options.find((o) => o.id === value);

    if (!client) {
      return;
    }

    dispatch(new SetActiveClient(client));
  };

  const clientLabel = useSelector(getClientLabel);

  return (
    <Select
      className={`${className || ''} client__select`}
      size="large"
      bordered={false}
      loading={loading}
      defaultValue="empty"
      value={active?.id}
      listHeight={448}
      onChange={handleChange}
      dropdownRender={(menu) => (
        <div>
          {menu}
          <div className="client__select__option__manage">
            <Button type="primary" block onClick={handleManage}>
              Manage {`${clientLabel}s`}
            </Button>
          </div>
        </div>
      )}
    >
      <Option value="empty" className="client__select__option">
        <span className="client__select__placeholder" />
        Select {`${clientLabel}`}
      </Option>
      {options.map((option) => (
        <Option value={option.id} key={option.id} className="client__select__option">
          {option.logo ? (
            <span className="client__select__placeholder">
              <Image className="client__select__logo" src={option.logo} alt={option.name} />
            </span>
          ) : (
            <span className="client__select__placeholder" />
          )}
          <Typography.Paragraph className="client__select__label">{option.name}</Typography.Paragraph>
        </Option>
      ))}
    </Select>
  );
}
