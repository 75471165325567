import { CallApi, handleJSONResponse } from './common';
import { ListInvoiceResponse } from '../../../backend/services/invoice/functions/list-invoice/interface';

const callAPI = CallApi('invoice');

export const list = async (): Promise<ListInvoiceResponse> => {
  const data = {
    limit: 500,
  };
  const response = await callAPI(`/`, 'GET', data);

  return await handleJSONResponse(response);
};
