import { Observable } from 'rxjs';
import { ofType } from 'redux-observable';
import { mergeMap } from 'rxjs/operators';
import * as subscriptions from '../../../sdk/subscriptions';
import { Actions } from '../../actions';
import { UpdateSubscription, UpdateSubscriptionSuccess, UpdateSubscriptionError } from '../action-creators';
import { UPDATE_SUBSCRIPTION } from '../actions';
import { delayPromise } from '@webapp/utils/delay-promise';
import ApiError from '../../../../../backend/commons/utils/api/api-error';

export const updateSubscriptionEpic = (action$: Observable<Actions>): Observable<Actions> =>
  action$.pipe(
    ofType<Actions, UpdateSubscription>(UPDATE_SUBSCRIPTION),
    mergeMap(async (action) => {
      try {
        const subscription = await subscriptions.update(action.subscriptionUpdate);

        await delayPromise(5000);

        return new UpdateSubscriptionSuccess(subscription);
      } catch (err) {
        return new UpdateSubscriptionError((err as ApiError).message, (err as ApiError).description || '');
      }
    })
  );
