import React, { useState } from 'react';
import './styles.less';

import { Button, Input, Space, Typography, Modal, Select } from 'antd';
import { CloseCircleOutlined } from '@ant-design/icons';

const { Option } = Select;

import Form from 'antd/lib/form/Form';
import FormItem from 'antd/lib/form/FormItem';
import ScenariosModalTable from '../table/campaign-modal-table';
import { FunnelType } from '../../../../backend/services/funnel-type/types/entities/funnel-type';
import { Scenario, ScenarioType } from '../../../../backend/services/scenario/types/entities/scenario';
import { CreateScenario, UpdateScenario } from '../../store/scenario/action-creators';
import { useDispatch } from 'react-redux';
import { push } from 'connected-react-router';

interface ScenarioEditorModalProps {
  className?: string;
  isModalVisible: boolean;
  funnelTypes: FunnelType[];
  scenario?: Scenario;
  handleClose: () => void;
}

const SCENARIO_TYPES: ScenarioType[] = ['Budget', 'Outcome', 'Revenue'];

const getValueKey = (type: ScenarioType): keyof Scenario => {
  switch (type) {
    case 'Budget':
      return 'budget';
    case 'Outcome':
      return 'outcome';
    case 'Revenue':
      return 'revenue';
    default:
      return 'budget';
  }
};

const getScenarioValue = (scenario?: Scenario): number => {
  if (scenario?.scenarioType) {
    return scenario[getValueKey(scenario?.scenarioType)] as number;
  }
  return 0;
};

const hasPrefix = (type?: ScenarioType): boolean => {
  switch (type) {
    case 'Budget':
      return true;
    case 'Revenue':
      return true;
    default:
      return false;
  }
};

export default function ScenarioEditorModal({
  className,
  isModalVisible,
  scenario,
  funnelTypes,
  handleClose,
}: ScenarioEditorModalProps): JSX.Element {
  const initialCost = scenario?.costStage?.value || 0;
  const initialValue = scenario?.valueStage?.value || 0;

  const [funnelType, setFunnelType] = useState<FunnelType | undefined>(
    funnelTypes.find((f) => f.id === scenario?.funnelTypeId)
  );

  const [stageValues, setStageValues] = useState<Record<number, number>>(
    scenario?.stages?.reduce((memo: Record<number, number>, stage, i) => {
      memo[i] = stage.value;
      return memo;
    }, {}) || {}
  );
  const [name, setName] = useState<string>(scenario?.name || '');

  const [cost, setCost] = useState<number>(initialCost);
  const [value, setValue] = useState<number>(initialValue);

  const [type, setType] = useState<ScenarioType | undefined>(scenario?.scenarioType);
  const [scenarioValue, setScenarioValue] = useState<number>(getScenarioValue(scenario));

  const dispatch = useDispatch();

  const handleValuesChange = (values: Record<number, number>) => {
    setStageValues(values);
  };

  const onFinish = () => {
    if (
      name.length &&
      funnelType?.id &&
      cost > 0 &&
      value > 0 &&
      stageValues &&
      type &&
      scenarioValue > 0 &&
      Object.keys(stageValues || {}).length === funnelType?.stages.length
    ) {
      if (scenario?.id) {
        dispatch(
          new UpdateScenario(scenario.id, {
            name,
            scenarioType: type,
            stages: Object.keys(stageValues || {}).map((i) => ({
              name: funnelType.stages[Number(i)],
              conversionRate: stageValues[Number(i)] / 100,
            })),
            [getValueKey(type)]: scenarioValue,
            costStage: {
              name: funnelType.costStage,
              value: cost,
            },
            valueStage: {
              name: funnelType.valueStage,
              value,
            },
          })
        );
      } else {
        dispatch(
          new CreateScenario({
            name,
            funnelTypeId: funnelType.id,
            scenarioType: type,
            stages: Object.keys(stageValues || {}).map((i) => ({
              name: funnelType.stages[Number(i)],
              conversionRate: stageValues[Number(i)] / 100,
            })),
            [getValueKey(type)]: scenarioValue,
            costStage: {
              name: funnelType.costStage,
              value: cost,
            },
            valueStage: {
              name: funnelType.valueStage,
              value,
            },
          })
        );
      }
    }
    handleClose();
    if (!scenario?.id) {
      dispatch(push('/scenarios'));
    }
  };

  const isEditing = !!scenario?.id;

  const getMin = (type?: ScenarioType): number => {
    if (type === 'Budget') {
      return 0.01;
    }

    return 0;
  };

  return (
    <div className={`${className || ''} modal`}>
      <Modal
        className="modal__wrapper"
        visible={isModalVisible}
        maskClosable
        centered
        footer={null}
        width={1000}
        closeIcon={<CloseCircleOutlined onClick={handleClose} className="modal__close" />}
      >
        <Typography.Title className="modal__wrapper__title" level={3}>
          {isEditing ? 'Edit' : 'New'} Scenario
        </Typography.Title>

        <Form name="newScenario" layout="vertical" initialValues={{ remember: false }} onFinish={onFinish}>
          <Space size="large" className="modal__wrapper__form__space growth">
            <FormItem
              name="Name"
              className="modal__wrapper__form__item"
              label="Scenario name"
              initialValue={name}
              rules={[{ required: true, message: 'Add a Scenario name' }]}
            >
              <Input
                className="modal__wrapper__form__item__input"
                placeholder="Scenario name"
                size="large"
                value={name}
                onChange={(ev) => setName(ev.target.value)}
              />
            </FormItem>
            <FormItem
              label="Select a Funnel"
              className="modal__wrapper__form__item__select"
              initialValue={funnelType?.id}
            >
              <Select
                placeholder="Funnel"
                size="large"
                value={funnelType?.id}
                disabled={isEditing}
                onChange={(value) => setFunnelType(funnelTypes.find((f) => f.id === value))}
              >
                {funnelTypes.map((funnelType) => (
                  <Option key={funnelType.id} value={funnelType.id}>
                    {funnelType.name}
                  </Option>
                ))}
              </Select>
            </FormItem>
          </Space>
          <Space size="large" className="modal__wrapper__form__space growth">
            <FormItem label="Select a Scenario Type" className="modal__wrapper__form__item__select" initialValue={type}>
              <Select
                placeholder="Scenario Type"
                size="large"
                value={type}
                onChange={(value) => setType(value as ScenarioType)}
              >
                {SCENARIO_TYPES.map((scenarioType, i) => (
                  <Option key={`scenario-type-${i}`} value={scenarioType}>
                    {scenarioType}
                  </Option>
                ))}
              </Select>
            </FormItem>
            <FormItem
              name="Value"
              className="modal__wrapper__form__item"
              label={type || 'Value'}
              initialValue={scenarioValue || undefined}
              rules={[{ required: true, message: 'Add a value' }]}
            >
              <Input
                className="modal__wrapper__form__item__input"
                placeholder="0"
                size="large"
                min={getMin(type)}
                step={getMin(type)}
                type="number"
                value={scenarioValue || undefined}
                prefix={hasPrefix(type) ? '$' : ''}
                onChange={(ev) => setScenarioValue(Number(ev.target.value))}
              />
            </FormItem>
          </Space>
          <Space size="large" className="modal__wrapper__form__space growth" align="start">
            <ScenariosModalTable
              className="modal__wrapper__new-scenario__table"
              funnelType={funnelType}
              campaign={scenario}
              onChange={handleValuesChange}
              valueTitle="Conversion Rate"
              isRatio
            />
            <Space direction="vertical">
              <FormItem
                name="cost"
                label={`Cost${funnelType?.costStage ? ` (${funnelType?.costStage})` : ''}`}
                className="modal__wrapper__form__item cost"
                initialValue={cost || undefined}
                rules={[{ required: true, message: 'Input the cost' }]}
              >
                <Input
                  className="modal__wrapper__form__item__input"
                  placeholder="0"
                  min={0.01}
                  step={0.01}
                  addonBefore="Cost"
                  size="large"
                  type="number"
                  prefix="$"
                  value={cost || undefined}
                  onChange={(ev) => setCost(Number(ev.target.value))}
                />
              </FormItem>
              <FormItem
                name="value"
                label={`Value${funnelType?.valueStage ? ` (${funnelType?.valueStage})` : ''}`}
                className="modal__wrapper__form__item value"
                initialValue={value || undefined}
                rules={[{ required: true, message: 'Input the cost' }]}
              >
                <Input
                  className="modal__wrapper__form__item__input"
                  placeholder="0"
                  addonBefore="Value"
                  min={0.01}
                  step={0.01}
                  type="number"
                  size="large"
                  prefix="$"
                  value={value || undefined}
                  onChange={(ev) => setValue(Number(ev.target.value))}
                />
              </FormItem>
            </Space>
          </Space>
          <Space size="large" className="modal__wrapper__buttongroup">
            <Button onClick={handleClose} htmlType="reset" size="large" className="modal__wrapper__button">
              Cancel
            </Button>
            <Button type="primary" htmlType="submit" size="large" className="modal__wrapper__button">
              Save
            </Button>
          </Space>
        </Form>
      </Modal>
    </div>
  );
}
