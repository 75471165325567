import React from 'react';
import moment from 'moment';

import { Space, Table, Tooltip } from 'antd';
import { EditOutlined, DeleteOutlined, LoadingOutlined, CopyOutlined } from '@ant-design/icons';

import { FunnelTemplate } from '@backend/services/funnel-template/types/entities/funnel-template';

import './styles.less';

interface FunnelTemplateTableProps {
  className?: string;
  isDeleting?: boolean;
  funnelTemplates: FunnelTemplate[];
  onEdit: (funnelTemplate: FunnelTemplate) => void;
  onDelete: (funnelTemplate: FunnelTemplate) => void;
  onClone: (funnelTemplate: FunnelTemplate) => void;
}

export default function FunnelTemplateTable({
  className,
  isDeleting,
  funnelTemplates,
  onEdit,
  onDelete,
  onClone,
}: FunnelTemplateTableProps): JSX.Element {
  const ActionsCell = (props: {
    funnelTemplate: FunnelTemplate;
    onEdit: () => void;
    onDelete: () => void;
    onClone: () => void;
  }): JSX.Element => (
    <Space size="large">
      <Tooltip title="Edit">
        <EditOutlined className="table__funneltypes__icon gray" onClick={() => props.onEdit()} />
      </Tooltip>
      <Tooltip title="Clone">
        <CopyOutlined className="table__funneltypes__icon gray" onClick={() => props.onClone()} />
      </Tooltip>
      <Tooltip title={isDeleting ? 'Deleting' : 'Delete'}>
        {isDeleting ? (
          <LoadingOutlined className="table__funneltypes__icon red" />
        ) : (
          <DeleteOutlined className="table__funneltypes__icon red" onClick={() => props.onDelete()} />
        )}
      </Tooltip>
    </Space>
  );

  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Stages',
      dataIndex: 'stages',
      key: 'stages',
      render: (value: string[]) => value.length,
    },
    {
      title: 'Created Date',
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: (value: string) => moment(value).format('LL'),
    },
    {
      title: 'Actions',
      dataIndex: 'actions',
      key: 'actions',
      render: (_: undefined, funnelTemplate: FunnelTemplate) => (
        <ActionsCell
          funnelTemplate={funnelTemplate}
          onEdit={() => onEdit(funnelTemplate)}
          onDelete={() => onDelete(funnelTemplate)}
          onClone={() => onClone(funnelTemplate)}
        />
      ),
    },
  ];

  return (
    <div className={`${className || ''} table__funneltypes`}>
      <Table<FunnelTemplate> rowKey={(f) => f.id} columns={columns} dataSource={funnelTemplates} />
    </div>
  );
}
