import React from 'react';

import './styles.less';

import Menu from '../../menu';
import Header from '../../header';
import UserMenu from '../../user-menu';
import { userFullName, userProfile } from '@webapp/store/auth/selectors';
import {
  getSubscriptionEndDate,
  isSubscriptionTrial,
  trialExpirationDate,
} from '@webapp/store/subscriptions/selectors';
import { useDispatch, useSelector } from 'react-redux';

import { LoadingOutlined } from '@ant-design/icons';

import { Button, Space, Tag, Typography } from 'antd';
import { push } from 'connected-react-router';
import AppState from '@webapp/store/state';

interface PageProps {
  children: React.ReactNode;
  className?: string;
  noMenu?: boolean;
  noBg?: boolean;
}

const Page: React.FC<PageProps> = ({ children, className, noMenu, noBg }) => {
  const dispatch = useDispatch();

  const fullName = useSelector(userFullName);
  const isTrialVersion = useSelector(isSubscriptionTrial);
  const expirationDate = useSelector(trialExpirationDate);
  const subscriptionEndDate = useSelector(getSubscriptionEndDate);

  const handleUpgrade = () => {
    dispatch(push('/plans'));
  };

  const planName = useSelector((state: AppState) => state.subscriptions.current?.product?.name);

  const hasSubscription = useSelector((state: AppState) => state.subscriptions.current);

  const profile = useSelector(userProfile);
  const user = useSelector((state: AppState) => state.user.items.find((u) => u.id === profile.sub));

  return (
    <div className={`${className || ''} main-page`}>
      {!noMenu && <Menu className="main-page__menu" />}
      <div className="main-page__wrapper">
        <Header>
          {subscriptionEndDate ? (
            <Space className="main-page__wrapper__upgrade">
              <Typography>Your subscription ends {subscriptionEndDate?.fromNow()}</Typography>
              <Button type="primary" onClick={handleUpgrade}>
                Restore
              </Button>
            </Space>
          ) : null}
          {isTrialVersion ? (
            <Space className="main-page__wrapper__upgrade">
              <Typography>
                Your <b>Free Trial</b> will end {expirationDate?.fromNow()}
              </Typography>
              <Button type="primary" onClick={handleUpgrade}>
                Upgrade
              </Button>
            </Space>
          ) : hasSubscription ? (
            <Tag className="main-page__wrapper__tag">{planName}</Tag>
          ) : (
            <LoadingOutlined className="main-page__wrapper__load" />
          )}
          <UserMenu userAvatar={user?.avatar} userName={fullName} />
        </Header>
        <div className={`main-page__wrapper__content ${noBg ? 'no-background' : ''}`}>{children}</div>
      </div>
    </div>
  );
};

export default Page;
