import * as actions from './actions';
import { LoadingAction } from '../common/loading-action';
import { ErrorAction } from '../common/error-action';
import { Invite } from '../../../../backend/services/invite/types/entities/invite';
import { CreateInviteBodyRequest } from '../../../../backend/services/invite/functions/create-invite/interface';
import { ListInviteResponse } from '../../../../backend/services/invite/functions/list-invite/interface';
import { DeleteInviteResponse } from '../../../../backend/services/invite/functions/delete-invite/interface';

export class ListInvite implements LoadingAction {
  readonly type = actions.LIST_INVITE;
  readonly isLoading = true;
  readonly mainLoader = true;
}

export class ListInviteSuccess implements LoadingAction {
  readonly type = actions.LIST_INVITE_SUCCESS;
  readonly isLoading = false;

  constructor(public results: ListInviteResponse) {}
}

export class ListInviteError implements ErrorAction {
  readonly type = actions.LIST_INVITE_ERROR;
  readonly isError = true;

  constructor(public code: string, public description: string | string[]) {}
}

export class ResendInvite implements LoadingAction {
  readonly type = actions.RESEND_INVITE;
  readonly isLoading = true;
  readonly mainLoader = true;

  constructor(public inviteId: string) {}
}

export class ResendInviteSuccess implements LoadingAction {
  readonly type = actions.RESEND_INVITE_SUCCESS;
  readonly isLoading = false;

  constructor(public invite: Invite) {}
}

export class ResendInviteError implements ErrorAction {
  readonly type = actions.RESEND_INVITE_ERROR;
  readonly isError = true;

  constructor(public code: string, public description: string | string[]) {}
}

export class CreateInvite implements LoadingAction {
  readonly type = actions.CREATE_INVITE;
  readonly isLoading = true;
  readonly mainLoader = true;

  constructor(public newInvite: CreateInviteBodyRequest) {}
}

export class CreateInviteSuccess implements LoadingAction {
  readonly type = actions.CREATE_INVITE_SUCCESS;
  readonly isLoading = false;

  constructor(public invite: Invite) {}
}

export class CreateInviteError implements ErrorAction {
  readonly type = actions.CREATE_INVITE_ERROR;
  readonly isError = true;

  constructor(public code: string, public description: string | string[]) {}
}
export class GetInvite implements LoadingAction {
  readonly type = actions.GET_INVITE;
  readonly isLoading = true;
  readonly mainLoader = true;

  constructor(public inviteId: string) {}
}

export class GetInviteSuccess implements LoadingAction {
  readonly type = actions.GET_INVITE_SUCCESS;
  readonly isLoading = false;

  constructor(public invite: Invite) {}
}

export class GetInviteError implements ErrorAction {
  readonly type = actions.GET_INVITE_ERROR;
  readonly isError = true;

  constructor(public code: string, public description: string | string[]) {}
}

export class DeleteInvite implements LoadingAction {
  readonly type = actions.DELETE_INVITE;
  readonly isLoading = true;
  readonly mainLoader = true;

  constructor(public inviteId: string) {}
}

export class DeleteInviteSuccess implements LoadingAction {
  readonly type = actions.DELETE_INVITE_SUCCESS;
  readonly isLoading = false;

  constructor(public inviteId: string, public result: DeleteInviteResponse) {}
}

export class DeleteInviteError implements ErrorAction {
  readonly type = actions.DELETE_INVITE_ERROR;
  readonly isError = true;

  constructor(public code: string, public description: string | string[]) {}
}

export type InviteActions =
  | ListInvite
  | ListInviteSuccess
  | ListInviteError
  | GetInvite
  | GetInviteSuccess
  | GetInviteError
  | ResendInvite
  | ResendInviteSuccess
  | ResendInviteError
  | CreateInvite
  | CreateInviteSuccess
  | CreateInviteError
  | DeleteInvite
  | DeleteInviteSuccess
  | DeleteInviteError;
