import { getToken } from './auth';
import { ApiError } from '../../../backend/commons/utils/api/api-error';

const API_DOMAIN = process.env.REACT_APP_API_URL;

export type ApiClient = (endpoint: string, method: string, data?: unknown, isPublic?: boolean) => Promise<Response>;

export const getFullUrl = (service: string, endpoint: string): string => `${API_DOMAIN}${service}${endpoint}`;

export const CallApi =
  (service: string): ApiClient =>
  async (endpoint: string, method: string, data?: unknown, isPublic?: boolean) => {
    const token = await getToken();

    if (!token && !isPublic) {
      throw new Error('Missing token!');
    }

    const headers = new Headers();
    if (token) {
      headers.append('Authorization', token);
    }
    headers.append('Content-Type', 'application/json');

    const params: RequestInit = {
      method,
      headers,
    };

    if (data) {
      if (method !== 'GET') {
        params.body = JSON.stringify(data);
      } else {
        endpoint = `${endpoint}?${new URLSearchParams(data as Record<string, string>).toString()}`;
      }
    }

    return fetch(getFullUrl(service, endpoint), params);
  };

export const handleResponse = async (response: Response): Promise<string> => {
  const data = await response.text();

  if (!response.ok) {
    throw new Error(data);
  }

  return data;
};

export const handleJSONResponse = async <T = unknown>(response: Response): Promise<T> => {
  const data = await response.json();

  if (!response.ok) {
    throw new ApiError(data.code, response.status, data.description);
  }

  return parseCommonFields(data);
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const parseCommonFields = (data: any): any => {
  if (Array.isArray(data)) {
    return data.map((d) => parseCommonFields(d));
  }

  if ('created_at' in data) {
    data.created_at = new Date(data.created_at);
  }

  if ('updated_at' in data) {
    data.updated_at = new Date(data.updated_at);
  }

  return data;
};
