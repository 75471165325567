import { Observable } from 'rxjs';
import { ofType } from 'redux-observable';
import { mergeMap } from 'rxjs/operators';
import { Actions } from '../../actions';
import { GetPromoCode, GetPromoCodeError, GetPromoCodeSuccess } from '../action-creators';
import { GET_PROMO_CODE } from '../actions';
import * as coupons from '../../../sdk/coupon';
import ApiError from '../../../../../backend/commons/utils/api/api-error';

export const getPromoCodeEpic = (action$: Observable<Actions>): Observable<Actions> =>
  action$.pipe(
    ofType<Actions, GetPromoCode>(GET_PROMO_CODE),
    mergeMap(async (action) => {
      try {
        const promoCode = await coupons.getCoupon(action.couponId);

        return new GetPromoCodeSuccess(promoCode);
      } catch (err) {
        return new GetPromoCodeError((err as ApiError).message, (err as ApiError).description || '');
      }
    })
  );
