import { CallApi, handleJSONResponse } from './common';
import { Client } from '../../../backend/services/client/types/entities/client';
import { PaginatedResponse } from '../../../backend/commons/types/api-interface';
import { CreateClientBodyRequest } from '../../../backend/services/client/functions/create-client/interface';
import { UpdateClientBodyRequest } from '../../../backend/services/client/functions/update-client/interface';
import { DeleteClientResponse } from '../../../backend/services/client/functions/delete-client/interface';

const callAPI = CallApi('client');

export const list = async (): Promise<PaginatedResponse<Client>> => {
  const data = {
    limit: 500,
  };
  const response = await callAPI('/', 'GET', data);

  return await handleJSONResponse(response);
};

export const create = async (client: CreateClientBodyRequest): Promise<Client> => {
  const response = await callAPI('/', 'POST', client);

  return await handleJSONResponse(response);
};

export const get = async (clientId: string): Promise<Client> => {
  const response = await callAPI(`/${clientId}`, 'GET');

  return await handleJSONResponse(response);
};

export const remove = async (clientId: string): Promise<DeleteClientResponse> => {
  const response = await callAPI(`/${clientId}`, 'DELETE');

  return await handleJSONResponse(response);
};

export const update = async (clientId: string, payload: UpdateClientBodyRequest): Promise<Client> => {
  const response = await callAPI(`/${clientId}`, 'PUT', payload);

  return await handleJSONResponse(response);
};
