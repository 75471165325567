import * as actions from './actions';
import { LoadingAction } from '../common/loading-action';
import { ErrorAction } from '../common/error-action';
import { Scenario } from '../../../../backend/services/scenario/types/entities/scenario';
import { CreateScenarioBodyRequest } from '../../../../backend/services/scenario/functions/create-scenario/interface';
import { ListScenarioResponse } from '../../../../backend/services/scenario/functions/list-scenario/interface';
import { UpdateScenarioBodyRequest } from '../../../../backend/services/scenario/functions/update-scenario/interface';
import { DeleteScenarioResponse } from '../../../../backend/services/scenario/functions/delete-scenario/interface';

export class ListScenario implements LoadingAction {
  readonly type = actions.LIST_SCENARIO;
  readonly isLoading = true;
  readonly mainLoader = true;

  constructor(public clientId: string) {}
}

export class ListScenarioSuccess implements LoadingAction {
  readonly type = actions.LIST_SCENARIO_SUCCESS;
  readonly isLoading = false;

  constructor(public results: ListScenarioResponse) {}
}

export class ListScenarioError implements ErrorAction {
  readonly type = actions.LIST_SCENARIO_ERROR;
  readonly isError = true;

  constructor(public code: string, public description: string | string[]) {}
}

export class CreateScenario implements LoadingAction {
  readonly type = actions.CREATE_SCENARIO;
  readonly isLoading = true;
  readonly mainLoader = true;

  constructor(public newScenario: CreateScenarioBodyRequest) {}
}

export class CreateScenarioSuccess implements LoadingAction {
  readonly type = actions.CREATE_SCENARIO_SUCCESS;
  readonly isLoading = false;

  constructor(public scenario: Scenario) {}
}

export class CreateScenarioError implements ErrorAction {
  readonly type = actions.CREATE_SCENARIO_ERROR;
  readonly isError = true;

  constructor(public code: string, public description: string | string[]) {}
}

export class GetScenario implements LoadingAction {
  readonly type = actions.GET_SCENARIO;
  readonly isLoading = true;
  readonly mainLoader = true;

  constructor(public clientId: string, public id: string) {}
}

export class GetScenarioSuccess implements LoadingAction {
  readonly type = actions.GET_SCENARIO_SUCCESS;
  readonly isLoading = false;

  constructor(public scenario: Scenario) {}
}

export class GetScenarioError implements ErrorAction {
  readonly type = actions.GET_SCENARIO_ERROR;
  readonly isError = true;

  constructor(public code: string, public description: string | string[]) {}
}

export class UpdateScenario implements LoadingAction {
  readonly type = actions.UPDATE_SCENARIO;
  readonly isLoading = true;
  readonly mainLoader = true;

  constructor(public scenarioId: string, public scenario: UpdateScenarioBodyRequest) {}
}

export class UpdateScenarioSuccess implements LoadingAction {
  readonly type = actions.UPDATE_SCENARIO_SUCCESS;
  readonly isLoading = false;

  constructor(public scenario: Scenario) {}
}

export class UpdateScenarioError implements ErrorAction {
  readonly type = actions.UPDATE_SCENARIO_ERROR;
  readonly isError = true;

  constructor(public code: string, public description: string | string[]) {}
}

export class DeleteScenario implements LoadingAction {
  readonly type = actions.DELETE_SCENARIO;
  readonly isLoading = true;
  readonly mainLoader = true;

  constructor(public scenarioId: string) {}
}

export class DeleteScenarioSuccess implements LoadingAction {
  readonly type = actions.DELETE_SCENARIO_SUCCESS;
  readonly isLoading = false;

  constructor(public scenarioId: string, public result: DeleteScenarioResponse) {}
}

export class DeleteScenarioError implements ErrorAction {
  readonly type = actions.DELETE_SCENARIO_ERROR;
  readonly isError = true;

  constructor(public code: string, public description: string | string[]) {}
}

export type ScenarioActions =
  | ListScenario
  | ListScenarioSuccess
  | ListScenarioError
  | GetScenario
  | GetScenarioSuccess
  | GetScenarioError
  | CreateScenario
  | CreateScenarioSuccess
  | CreateScenarioError
  | UpdateScenario
  | UpdateScenarioSuccess
  | UpdateScenarioError
  | DeleteScenario
  | DeleteScenarioSuccess
  | DeleteScenarioError;
