import React, { useState } from 'react';
import './styles.less';

import Avatar from '../avatar';

import { Dropdown, Typography } from 'antd';
import { UpOutlined, DownOutlined, LogoutOutlined, UserOutlined } from '@ant-design/icons';

import UserMenuItems from './user-menu-list';
import { useDispatch } from 'react-redux';
import { push } from 'connected-react-router';

export default function UserMenu({
  className,
  userAvatar,
  userName,
}: {
  className?: string;
  userAvatar?: string;
  userName?: string;
}): JSX.Element {
  const [visible, setVisible] = useState(false);
  const dispatch = useDispatch();

  const ITEMS = [
    {
      title: 'Profile',
      onClick: () => dispatch(push('/profile')),
      isDanger: false,
      icon: <UserOutlined />,
    },
    {
      title: 'Logout',
      onClick: () => Logout(),
      isDanger: true,
      icon: <LogoutOutlined />,
    },
  ];

  const Logout = () => {
    localStorage.clear();
    window.location.reload();
  };

  return (
    <Dropdown
      className={`${className || ''} usermenu`}
      overlay={<UserMenuItems items={ITEMS} />}
      placement="bottomRight"
      trigger={['click']}
      onVisibleChange={() => setVisible(!visible)}
    >
      <a href="#" onClick={(e) => e.preventDefault()}>
        <Avatar className="usermenu__avatar" userAvatar={userAvatar || undefined} userName={userName || undefined} />
        <Typography.Paragraph className="usermenu__name">
          {userName}{' '}
          {visible ? (
            <UpOutlined className="usermenu__name__icon" />
          ) : (
            <DownOutlined className="usermenu__name__icon" />
          )}
        </Typography.Paragraph>
      </a>
    </Dropdown>
  );
}
