import { ClientState } from './state';
import { clientInitialState } from './initial-state';
import { ClientActions } from './action-creators';
import * as actions from './actions';

export const clientReducer = (state: ClientState = clientInitialState, action: ClientActions): ClientState => {
  switch (action.type) {
    case actions.LIST_CLIENT_SUCCESS: {
      return {
        ...state,
        list: {
          items: action.clients.items.sort((a, b) => a.name.localeCompare(b.name)),
          nextToken: action.clients.nextToken,
        },
      };
    }

    case actions.DELETE_CLIENT_SUCCESS: {
      return {
        ...state,
        list: {
          ...state.list,
          items: state.list.items.filter((i) => i.id !== action.clientId),
        },
        active: state.active?.id === action.clientId ? undefined : state.active,
      };
    }

    case actions.CREATE_CLIENT_SUCCESS: {
      return {
        ...state,
        list: {
          ...state.list,
          items: state.list.items.concat([action.client]),
        },
      };
    }

    case actions.UPDATE_CLIENT_SUCCESS: {
      return {
        ...state,
        list: {
          ...state.list,
          items: state.list.items.map((i) => {
            if (i.id === action.client.id) {
              return { ...action.client };
            }
            return { ...i };
          }),
        },
      };
    }

    case actions.SET_ACTIVE_CLIENT: {
      localStorage.setItem('activeClient', JSON.stringify(action.client));
      return {
        ...state,
        active: action.client,
      };
    }

    default: {
      return { ...state };
    }
  }
};
