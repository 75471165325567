export const GET_SUBSCRIPTION = '[Subscription] Get subscription';
export const GET_SUBSCRIPTION_SUCCESS = '[Subscription] Get subscription Success';
export const GET_SUBSCRIPTION_ERROR = '[Subscription] Get subscription Error';

export const UPDATE_SUBSCRIPTION = '[Subscription] Update subscription';
export const UPDATE_SUBSCRIPTION_SUCCESS = '[Subscription] Update subscription Success';
export const UPDATE_SUBSCRIPTION_ERROR = '[Subscription] Update subscription Error';

export const CANCEL_SUBSCRIPTION = '[Subscription] Cancel subscription';
export const CANCEL_SUBSCRIPTION_SUCCESS = '[Subscription] Cancel subscription Success';
export const CANCEL_SUBSCRIPTION_ERROR = '[Subscription] Cancel subscription Error';

export const SET_SELECTED_SUBSCRIPTION = '[Subscription] Set selected subscription';
