import { ProductState } from './state';
import { productInitialState } from './initial-state';
import { ProductActions } from './action-creators';
import * as actions from './actions';

export const productReducer = (state: ProductState = productInitialState, action: ProductActions): ProductState => {
  switch (action.type) {
    case actions.LIST_PRODUCTS_SUCCESS: {
      return {
        ...state,
        list: {
          ...action.products,
        },
      };
    }

    default: {
      return { ...state };
    }
  }
};
