import React, { useState } from 'react';
import './styles.less';

import { Button, Card, Typography } from 'antd';
import { EditOutlined, DeleteOutlined, TeamOutlined } from '@ant-design/icons';
import { Image } from '@webapp/components/image';
import { Client } from '../../../../backend/services/client/types/entities/client';

import EXAMPLE_IMAGE from './example.png';
import { useDispatch, useSelector } from 'react-redux';
import { SetActiveClient } from '../../store/client/action-creators';

import CustomTextModal from '../modal/custom-text';
import { push } from 'connected-react-router';
import { DeleteClient } from '../../store/client/action-creators';
import { getClientLabel } from '@webapp/store/auth/selectors';

interface ClientCardProps {
  className?: string;
  client: Client;
  isSales?: boolean;
  onEdit: (client: Client) => void;
}

export default function ClientCard({ className, client, isSales, onEdit }: ClientCardProps): JSX.Element {
  const dispatch = useDispatch();
  const [isModalVisible, setIsModalVisible] = useState(false);

  const handleClick = () => {
    dispatch(new SetActiveClient(client));
    dispatch(push('/funnels'));
  };

  const handleEdit = (ev: React.MouseEvent<HTMLSpanElement>) => {
    ev.stopPropagation();

    onEdit(client);

    return false;
  };

  const handleDeleteModal = (ev: React.MouseEvent<HTMLSpanElement>) => {
    ev.stopPropagation();

    setIsModalVisible(true);

    return false;
  };

  const handleClose = () => {
    setIsModalVisible(false);
  };

  const handleDelete = (ev: React.MouseEvent<HTMLSpanElement>) => {
    ev.stopPropagation();

    dispatch(new DeleteClient(client.id));

    handleClose();

    return false;
  };

  const clientLabel = useSelector(getClientLabel);

  return (
    <Card
      className={`${className || ''} client__card`}
      hoverable
      onClick={handleClick}
      actions={[
        <Typography.Paragraph className="client__card__action__title" ellipsis={{ rows: 2, tooltip: `${client.name}` }}>
          {client.name}
        </Typography.Paragraph>,
        <DeleteOutlined className="client__card__action__icon__red" onClick={handleDeleteModal} />,
        <EditOutlined className="client__card__action__icon" onClick={handleEdit} />,
      ]}
    >
      <div className="client__card__container" style={isSales ? { backgroundColor: client?.color } : undefined}>
        {isSales ? (
          <TeamOutlined className="client__card__container__icon" />
        ) : (
          <Image src={client.logo || EXAMPLE_IMAGE} className="client__card__container__logo" />
        )}
      </div>
      <CustomTextModal
        isModalVisible={isModalVisible}
        handleClose={handleClose}
        title={`Delete ${client.name}`}
        text={`Are you sure you want to delete this ${clientLabel.toLowerCase()}?`}
        button={
          <Button danger size="large" onClick={handleDelete}>
            Delete {clientLabel}
          </Button>
        }
      />
    </Card>
  );
}
