import { CallApi, handleJSONResponse } from './common';
import { ListScenarioResponse } from '../../../backend/services/scenario/functions/list-scenario/interface';
import {
  CreateScenarioBodyRequest,
  CreateScenarioResponse,
} from '../../../backend/services/scenario/functions/create-scenario/interface';
import {
  UpdateScenarioBodyRequest,
  UpdateScenarioResponse,
} from '../../../backend/services/scenario/functions/update-scenario/interface';
import { GetScenarioResponse } from '../../../backend/services/scenario/functions/get-scenario/interface';
import { DeleteScenarioResponse } from '../../../backend/services/scenario/functions/delete-scenario/interface';

const callAPI = CallApi('scenario');

export const list = async (clientId: string): Promise<ListScenarioResponse> => {
  const data = {
    limit: 500,
  };
  const response = await callAPI(`/${clientId}`, 'GET', data);

  return await handleJSONResponse(response);
};

export const create = async (
  clientId: string,
  scenario: CreateScenarioBodyRequest
): Promise<CreateScenarioResponse> => {
  const response = await callAPI(`/${clientId}`, 'POST', scenario);

  return await handleJSONResponse(response);
};

export const update = async (
  clientId: string,
  scenarioId: string,
  scenario: UpdateScenarioBodyRequest
): Promise<UpdateScenarioResponse> => {
  const response = await callAPI(`/${clientId}/${scenarioId}`, 'PUT', scenario);

  return await handleJSONResponse(response);
};

export const get = async (clientId: string, scenarioId: string): Promise<GetScenarioResponse> => {
  const response = await callAPI(`/${clientId}/${scenarioId}`, 'GET');

  return await handleJSONResponse(response);
};

export const remove = async (clientId: string, scenarioId: string): Promise<DeleteScenarioResponse> => {
  const response = await callAPI(`/${clientId}/${scenarioId}`, 'DELETE');

  return await handleJSONResponse(response);
};
