import { Observable } from 'rxjs';
import { ofType } from 'redux-observable';
import { mergeMap } from 'rxjs/operators';
import * as forecasts from '../../../sdk/forecasts';
import { Actions } from '../../actions';
import { ListForecast, ListForecastSuccess, ListForecastError } from '../action-creators';
import { LIST_FORECAST } from '../actions';
import ApiError from '../../../../../backend/commons/utils/api/api-error';

export const listForecastEpic = (action$: Observable<Actions>): Observable<Actions> =>
  action$.pipe(
    ofType<Actions, ListForecast>(LIST_FORECAST),
    mergeMap(async (action) => {
      try {
        const forecastList = await forecasts.list(action.clientId);

        return new ListForecastSuccess(forecastList);
      } catch (err) {
        return new ListForecastError((err as ApiError).message, (err as ApiError).description || '');
      }
    })
  );
