import { Typography } from 'antd';
import React from 'react';
import ConversionRateItem from './conversion-rate-item';
import './styles.less';

interface ConversionRateProps {
  className?: string;
  rates: number[];
}

export default function ConversionRate({ className, rates }: ConversionRateProps): JSX.Element {
  return (
    <div className={`${className || ''} conversion-rate`}>
      <Typography.Title className="conversion-rate__title" level={4}>
        Conversion Rate
      </Typography.Title>
      {rates.map((rate, i) => (
        <ConversionRateItem key={i} conversionRatePercent={(rate * 100).toFixed(1)} />
      ))}
    </div>
  );
}
