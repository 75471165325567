import { Observable } from 'rxjs';
import { ofType } from 'redux-observable';
import { mergeMap } from 'rxjs/operators';
import * as invites from '../../../sdk/invites';
import { Actions } from '../../actions';
import { ResendInvite, ResendInviteSuccess, ResendInviteError } from '../action-creators';
import { RESEND_INVITE } from '../actions';
import { notification } from 'antd';
import ApiError from '../../../../../backend/commons/utils/api/api-error';

export const resendInviteEpic = (action$: Observable<Actions>): Observable<Actions> =>
  action$.pipe(
    ofType<Actions, ResendInvite>(RESEND_INVITE),
    mergeMap(async (action) => {
      try {
        const result = await invites.resend(action.inviteId);

        notification.success({
          message: 'Success!',
          description: 'The invite has been sent',
        });

        return new ResendInviteSuccess(result);
      } catch (err) {
        return new ResendInviteError((err as ApiError).message, (err as ApiError).description || '');
      }
    })
  );
