import { combineEpics } from 'redux-observable';
import { createForecastEpic } from './create-forecast';
import { updateForecastEpic } from './update-forecast';
import { listForecastEpic } from './list-forecasts';
import { listForecastAfterActiveEpic } from './list-forecasts-after-active';
import { deleteForecastEpic } from './delete-forecast';
import { getForecastEpic } from './get-forecast';

const forecastEpic = combineEpics(
  listForecastEpic,
  listForecastAfterActiveEpic,
  createForecastEpic,
  updateForecastEpic,
  deleteForecastEpic,
  getForecastEpic
);

export default forecastEpic;
