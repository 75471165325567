import React from 'react';
// import { useSelector } from 'react-redux';

import { Space, Table, Tooltip } from 'antd';

import { SendOutlined, StopOutlined, DeleteOutlined, LoadingOutlined } from '@ant-design/icons';

import './styles.less';
import { ColumnsType } from 'antd/lib/table';
import { User } from '../../../../backend/services/user/types/entities/user';
import { Invite } from '../../../../backend/services/invite/types/entities/invite';

interface MembersTableProps<T> {
  className?: string;
  data: T[];
  dataColumns: ColumnsType<T>;
  isAccepted: boolean;
  isDeleting: boolean;
  keyMapper: (value: T) => string;
  handleResend?: (value: T) => void;
  handleCancel?: (value: T) => void;
  handleDelete?: (value: T) => void;
}

export default function MembersTable<T extends User | Invite>({
  className,
  data,
  dataColumns,
  isAccepted,
  isDeleting,
  handleResend,
  handleCancel,
  handleDelete,
  keyMapper,
}: MembersTableProps<T>): JSX.Element {
  // const loading = useSelector(
  //   (state: AppState) => !!state.ui.loading[LOADING_CAMPAINGS]
  // );

  const ActionsCell = (
    props: {
      handleResend: (value: T) => void;
      handleCancel: (value: T) => void;
      handleDelete: (value: T) => void;
      isAccepted: boolean;
      isDeleting: boolean;
      canDelete: boolean;
    },
    value: T
  ): JSX.Element =>
    props.isAccepted ? (
      <Space size="large">
        {props.canDelete ? (
          <Tooltip title={isDeleting ? 'Deleting Member' : 'Delete Member'}>
            {isDeleting ? (
              <LoadingOutlined className="table__actions__icon red" />
            ) : (
              <DeleteOutlined className="table__actions__icon red" onClick={() => props.handleDelete(value)} />
            )}
          </Tooltip>
        ) : null}
      </Space>
    ) : (
      <Space size="large">
        <Tooltip title="Re-send Invitation">
          <SendOutlined className="table__actions__icon gray" onClick={() => props.handleResend(value)} />
        </Tooltip>
        <Tooltip title="Cancel Invitation">
          <StopOutlined className="table__actions__icon red" onClick={() => props.handleCancel(value)} />
        </Tooltip>
      </Space>
    );

  const columns: ColumnsType<T> = [
    {
      title: 'Actions',
      dataIndex: 'actions',
      key: 'actions',
      render: (_, value) => (
        <ActionsCell
          handleResend={() => (handleResend ? handleResend(value) : null)}
          handleCancel={() => (handleCancel ? handleCancel(value) : null)}
          handleDelete={() => (handleDelete ? handleDelete(value) : null)}
          isAccepted={isAccepted}
          isDeleting={isDeleting}
          canDelete={'isOwner' in value ? !(value as User).isOwner : true}
        />
      ),
    },
  ];

  return (
    <div className={`${className || ''} table__actions`}>
      <Table<T>
        columns={[...dataColumns, ...columns]}
        dataSource={data.map((d) => ({
          ...d,
          key: keyMapper(d),
        }))}
        pagination={false}
        // loading={loading}
      />
    </div>
  );
}
