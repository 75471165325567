import * as actions from './actions';
import { LoadingAction } from '../common/loading-action';
import { ErrorAction } from '../common/error-action';
import { Subscription } from '../../../../backend/services/subscription/types/entities/subscription';
import { UpdateSubscriptionBodyRequest } from '../../../../backend/services/subscription/functions/update-subscription/interface';
import { Action } from 'redux';
import { PaymentFrequency } from '../../../../backend/services/product/types/entities/price';
import { Product } from '../../../../backend/services/product/types/entities/product';

export class GetSubscription implements LoadingAction {
  readonly type = actions.GET_SUBSCRIPTION;
  readonly isLoading = true;
  readonly mainLoader = true;

  constructor(public retries = 0) {}
}

export class GetSubscriptionSuccess implements LoadingAction {
  readonly type = actions.GET_SUBSCRIPTION_SUCCESS;
  readonly isLoading = false;

  constructor(public subscription: Subscription) {}
}

export class GetSubscriptionError implements ErrorAction {
  readonly type = actions.GET_SUBSCRIPTION_ERROR;
  readonly isError = true;

  constructor(public code: string, public description: string | string[]) {}
}

export class CancelSubscription implements LoadingAction {
  readonly type = actions.CANCEL_SUBSCRIPTION;
  readonly isLoading = true;
  readonly mainLoader = true;
}

export class CancelSubscriptionSuccess implements LoadingAction {
  readonly type = actions.CANCEL_SUBSCRIPTION_SUCCESS;
  readonly isLoading = false;

  constructor(public subscription: Subscription) {}
}

export class CancelSubscriptionError implements ErrorAction {
  readonly type = actions.CANCEL_SUBSCRIPTION_ERROR;
  readonly isError = true;

  constructor(public code: string, public description: string | string[]) {}
}

export class UpdateSubscription implements LoadingAction {
  readonly type = actions.UPDATE_SUBSCRIPTION;
  readonly isLoading = true;
  readonly mainLoader = true;

  constructor(public subscriptionUpdate: UpdateSubscriptionBodyRequest) {}
}

export class UpdateSubscriptionSuccess implements LoadingAction {
  readonly type = actions.UPDATE_SUBSCRIPTION_SUCCESS;
  readonly isLoading = false;

  constructor(public subscription: Subscription) {}
}

export class UpdateSubscriptionError implements ErrorAction {
  readonly type = actions.UPDATE_SUBSCRIPTION_ERROR;
  readonly isError = true;

  constructor(public code: string, public description: string | string[]) {}
}

export class SetSelectedSubscription implements Action {
  readonly type = actions.SET_SELECTED_SUBSCRIPTION;

  constructor(public frequecy: PaymentFrequency, public product: Product) {}
}

export type SubscriptionActions =
  | GetSubscription
  | GetSubscriptionSuccess
  | GetSubscriptionError
  | UpdateSubscription
  | UpdateSubscriptionSuccess
  | UpdateSubscriptionError
  | CancelSubscription
  | CancelSubscriptionSuccess
  | CancelSubscriptionError
  | SetSelectedSubscription;
