import { Campaign } from '../../../../backend/services/campaign/types/entities/campaign';
import AppState from '../state';

export const selectCampaigns = (state: AppState): Campaign[] => state.campaign.list.items;

export const groupCampaignsByFunnelType = (state: AppState): Record<string, Campaign[]> =>
  state.campaign.list.items.reduce((memo: Record<string, Campaign[]>, campaign) => {
    if (!(campaign.funnelTypeId in memo)) {
      memo[campaign.funnelTypeId] = [];
    }
    memo[campaign.funnelTypeId].push(campaign);

    return memo;
  }, {});
