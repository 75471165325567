import React from 'react';
import { Switch, Route } from 'react-router-dom';
import routes from './routes';
import './styles/index.less';
import './index.css';
import { useSelector } from 'react-redux';
import { selectIsOwner } from './store/auth/selectors';

export default function App(): JSX.Element {
  const isOwner = useSelector(selectIsOwner);

  return (
    <div className="app">
      <Switch>
        {routes
          .filter((r) => (r.requiresOwner ? isOwner : true))
          .map((route, index) => (
            <Route
              path={route.path}
              exact
              key={index}
              render={(props) =>
                React.createElement(route.component, {
                  ...props,
                } as React.Attributes)
              }
            />
          ))}
      </Switch>
    </div>
  );
}
