import { Observable } from 'rxjs';
import { ofType } from 'redux-observable';
import { map } from 'rxjs/operators';
import { Actions } from '../../actions';
import { ListClientSuccess, SetActiveClient } from '../action-creators';
import { LIST_CLIENT_SUCCESS } from '../actions';
import { NoOp } from '../../ui/action-creators';
import { Client } from '../../../../../backend/services/client/types/entities/client';

export const setActiveAfterListEpic = (action$: Observable<Actions>): Observable<Actions> =>
  action$.pipe(
    ofType<Actions, ListClientSuccess>(LIST_CLIENT_SUCCESS),
    map((action) => {
      if ('activeClient' in localStorage) {
        const client: Client = JSON.parse(localStorage.getItem('activeClient') || '{}');

        if (client?.id && action.clients.items.some((i) => i.id === client.id)) {
          return new SetActiveClient(client);
        }

        localStorage.removeItem('activeClient');
      }

      return new NoOp();
    })
  );
