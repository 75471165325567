export const LIST_FUNNEL_TYPE = '[Funnel] List funnel';
export const LIST_FUNNEL_TYPE_SUCCESS = '[Funnel] List funnel Success';
export const LIST_FUNNEL_TYPE_ERROR = '[Funnel] List funnel Error';

export const CREATE_FUNNEL_TYPE = '[Funnel] Create funnel';
export const CREATE_FUNNEL_TYPE_SUCCESS = '[Funnel] Create funnel Success';
export const CREATE_FUNNEL_TYPE_ERROR = '[Funnel] Create funnel Error';

export const UPDATE_FUNNEL_TYPE = '[Funnel] Update funnel';
export const UPDATE_FUNNEL_TYPE_SUCCESS = '[Funnel] Update funnel Success';
export const UPDATE_FUNNEL_TYPE_ERROR = '[Funnel] Update funnel Error';

export const DELETE_FUNNEL_TYPE = '[Funnel] Delete funnel';
export const DELETE_FUNNEL_TYPE_SUCCESS = '[Funnel] Delete funnel Success';
export const DELETE_FUNNEL_TYPE_ERROR = '[Funnel] Delete funnel Error';
