import React from 'react';
import './styles.less';

import { Card, Typography } from 'antd';
import { push } from 'connected-react-router';
import { useDispatch } from 'react-redux';

interface SettingsCardProps {
  className?: string;
  icon: React.ReactNode;
  title: string;
  to: string;
}

export default function SettingsCard({ className, icon, title, to }: SettingsCardProps): JSX.Element {
  const dispatch = useDispatch();

  const handleClick = (to: string) => {
    dispatch(push(to));
  };

  return (
    <Card className={`${className || ''} card`} hoverable onClick={() => handleClick(to)}>
      <div className="card__icon">{icon}</div>
      <Typography.Paragraph className="card__title">{title}</Typography.Paragraph>
    </Card>
  );
}
