import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Form, Input, Button } from 'antd';
import { ForgotPasswordStart } from '../../store/auth/action-creators';
import FormWrapper from './form-wrapper';
import AppState from '@webapp/store/state';
import { FORGOT_PASSWORD_START } from '@webapp/store/auth/actions';

export default function ForgotPasswordForm(): JSX.Element {
  const dispatch = useDispatch();

  const loading = useSelector((state: AppState) => !!state.ui.loading[FORGOT_PASSWORD_START]);

  const onFinish = ({ email }: { email: string }) => {
    if (!email) {
      return;
    }

    dispatch(new ForgotPasswordStart(email));
  };

  return (
    <FormWrapper title="Change password" hasBack>
      <Form name="basic" layout="vertical" initialValues={{ remember: true }} onFinish={onFinish}>
        <Form.Item
          name="email"
          className="form__wrapper__item"
          rules={[{ required: true, message: 'Please input your Verification Code!' }]}
        >
          <Input placeholder="Email" size="large" />
        </Form.Item>

        <Form.Item className="form__wrapper__button">
          <Button type="primary" htmlType="submit" size="large" loading={loading}>
            Reset password
          </Button>
        </Form.Item>
      </Form>
    </FormWrapper>
  );
}
