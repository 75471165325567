import { Observable } from 'rxjs';
import { ofType, StateObservable } from 'redux-observable';
import { mergeMap, withLatestFrom } from 'rxjs/operators';
import * as scenarios from '../../../sdk/scenarios';
import { Actions } from '../../actions';
import AppState from '../../state';
import { UpdateScenario, UpdateScenarioSuccess, UpdateScenarioError } from '../action-creators';
import { UPDATE_SCENARIO } from '../actions';
import { NoOp } from '../../ui/action-creators';
import ApiError from '../../../../../backend/commons/utils/api/api-error';

export const updateScenarioEpic = (
  action$: Observable<Actions>,
  state$: StateObservable<AppState>
): Observable<Actions> =>
  action$.pipe(
    ofType<Actions, UpdateScenario>(UPDATE_SCENARIO),
    withLatestFrom(state$),
    mergeMap(async ([action, state]) => {
      if (!state.client.active?.id) {
        return new NoOp();
      }

      try {
        const funnelType = await scenarios.update(state.client.active.id, action.scenarioId, action.scenario);

        return new UpdateScenarioSuccess(funnelType);
      } catch (err) {
        return new UpdateScenarioError((err as ApiError).message, (err as ApiError).description || '');
      }
    })
  );
