import React from 'react';

import Background from '@webapp/components/background';

import './styles.less';

const PageBackground: React.FC<{
  children: React.ReactNode;
  className?: string;
}> = ({ children, className }) => {
  return (
    <div className={`${className || ''} page-background`}>
      <Background className="page-background__background" />
      {children}
    </div>
  );
};

export default PageBackground;
