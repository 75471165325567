import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';
import { ShowError } from '../action-creators';
import { ErrorAction } from '../../common/error-action';

export const handleErrorsEpic = (action$: Observable<ErrorAction>): Observable<ShowError> =>
  action$.pipe(
    filter((action) => action.isError),
    map((action) => new ShowError(action.type, action.code))
  );
