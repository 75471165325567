import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { push } from 'connected-react-router';

import AppState from '@webapp/store/state';

import { NavLink } from 'react-router-dom';
import routes from '../../routes';

import './styles.less';

import { Client } from '@backend/services/client/types/entities/client';

import IconLogo from '../icons/logo';
import ClientSelect from '../client-select';
import { selectIsOwner } from '../../store/auth/selectors';

const Menu: React.FC<{ className?: string }> = ({ className }) => {
  const dispatch = useDispatch();

  const clientList: Client[] = useSelector((state: AppState) => state.client.list.items);

  const activeClient: Client | undefined = useSelector((state: AppState) => state.client.active);

  const isOwner = useSelector(selectIsOwner);

  const links = routes
    .filter((route) => route.onMenu)
    .filter((route) => (route.requiresOwner ? isOwner : true))
    .map((route, idx) => (
      <NavLink
        to={route.path}
        key={idx}
        className={`
          menu__content__link
          ${!activeClient && route.requiresClient ? 'requires-client' : ''}
          ${route.hasClass}
        `}
      >
        {route.icon}
        {route.label}
      </NavLink>
    ));

  return (
    <div className={`menu ${className || ''}`}>
      <div className="menu__header">
        <IconLogo isWhite onClick={() => dispatch(push('/clients'))} />
      </div>
      <ClientSelect options={clientList} active={activeClient} />
      <div className="menu__content">{links}</div>
    </div>
  );
};

export default Menu;
