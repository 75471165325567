import { CallApi, handleJSONResponse } from './common';
import { ListFunnelTemplateResponse } from '../../../backend/services/funnel-template/functions/list-funnel-template/interface';
import {
  CreateFunnelTemplateBodyRequest,
  CreateFunnelTemplateResponse,
} from '../../../backend/services/funnel-template/functions/create-funnel-template/interface';
import {
  UpdateFunnelTemplateBodyRequest,
  UpdateFunnelTemplateResponse,
} from '../../../backend/services/funnel-template/functions/update-funnel-template/interface';
import { GetFunnelTemplateResponse } from '../../../backend/services/funnel-template/functions/get-funnel-template/interface';
import { DeleteFunnelTemplateResponse } from '../../../backend/services/funnel-template/functions/delete-funnel-template/interface';

const callAPI = CallApi('funnel-template');

export const list = async (): Promise<ListFunnelTemplateResponse> => {
  const data = {
    limit: 500,
  };
  const response = await callAPI(`/`, 'GET', data);

  return await handleJSONResponse(response);
};

export const create = async (
  funnelTemplate: CreateFunnelTemplateBodyRequest
): Promise<CreateFunnelTemplateResponse> => {
  const response = await callAPI(`/`, 'POST', funnelTemplate);

  return await handleJSONResponse(response);
};

export const update = async (
  funnelTemplateId: string,
  funnelTemplate: UpdateFunnelTemplateBodyRequest
): Promise<UpdateFunnelTemplateResponse> => {
  const response = await callAPI(`/${funnelTemplateId}`, 'PUT', funnelTemplate);

  return await handleJSONResponse(response);
};

export const get = async (funnelTemplateId: string): Promise<GetFunnelTemplateResponse> => {
  const response = await callAPI(`/${funnelTemplateId}`, 'GET');

  return await handleJSONResponse(response);
};

export const remove = async (funnelTemplateId: string): Promise<DeleteFunnelTemplateResponse> => {
  const response = await callAPI(`/${funnelTemplateId}`, 'DELETE');

  return await handleJSONResponse(response);
};
