import { CallApi, handleJSONResponse } from './common';
import {
  UpdateSubscriptionBodyRequest,
  UpdateSubscriptionResponse,
} from '../../../backend/services/subscription/functions/update-subscription/interface';
import { GetSubscriptionResponse } from '../../../backend/services/subscription/functions/get-subscription/interface';
import { CancelSubscriptionResponse } from '../../../backend/services/subscription/functions/cancel-subscription/interface';

const callAPI = CallApi('subscription');

export const update = async (payload: UpdateSubscriptionBodyRequest): Promise<UpdateSubscriptionResponse> => {
  const response = await callAPI(`/`, 'PUT', payload);

  return await handleJSONResponse(response);
};

export const get = async (): Promise<GetSubscriptionResponse> => {
  const response = await callAPI(`/`, 'GET');

  return await handleJSONResponse(response);
};

export const cancel = async (): Promise<CancelSubscriptionResponse> => {
  const response = await callAPI(`/`, 'DELETE');

  return await handleJSONResponse(response);
};
