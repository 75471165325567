export const LIST_USER = '[User] List user';
export const LIST_USER_SUCCESS = '[User] List user Success';
export const LIST_USER_ERROR = '[User] List user Error';

export const UPDATE_USER = '[User] Update user';
export const UPDATE_USER_SUCCESS = '[User] Update user Success';
export const UPDATE_USER_ERROR = '[User] Update user Error';

export const DELETE_USER = '[User] Delete user';
export const DELETE_USER_SUCCESS = '[User] Delete user Success';
export const DELETE_USER_ERROR = '[User] Delete user Error';
