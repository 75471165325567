export const LIST_SCENARIO = '[Scenario] List scenario';
export const LIST_SCENARIO_SUCCESS = '[Scenario] List scenario Success';
export const LIST_SCENARIO_ERROR = '[Scenario] List scenario Error';

export const CREATE_SCENARIO = '[Scenario] Create scenario';
export const CREATE_SCENARIO_SUCCESS = '[Scenario] Create scenario Success';
export const CREATE_SCENARIO_ERROR = '[Scenario] Create scenario Error';

export const GET_SCENARIO = '[Scenario] Get scenario';
export const GET_SCENARIO_SUCCESS = '[Scenario] Get scenario Success';
export const GET_SCENARIO_ERROR = '[Scenario] Get scenario Error';

export const UPDATE_SCENARIO = '[Scenario] Update scenario';
export const UPDATE_SCENARIO_SUCCESS = '[Scenario] Update scenario Success';
export const UPDATE_SCENARIO_ERROR = '[Scenario] Update scenario Error';

export const DELETE_SCENARIO = '[Scenario] Delete scenario';
export const DELETE_SCENARIO_SUCCESS = '[Scenario] Delete scenario Success';
export const DELETE_SCENARIO_ERROR = '[Scenario] Delete scenario Error';
