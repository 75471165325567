export const LIST_INVITE = '[Invite] List invite';
export const LIST_INVITE_SUCCESS = '[Invite] List invite Success';
export const LIST_INVITE_ERROR = '[Invite] List invite Error';

export const CREATE_INVITE = '[Invite] Create invite';
export const CREATE_INVITE_SUCCESS = '[Invite] Create invite Success';
export const CREATE_INVITE_ERROR = '[Invite] Create invite Error';

export const GET_INVITE = '[Invite] Get invite';
export const GET_INVITE_SUCCESS = '[Invite] Get invite Success';
export const GET_INVITE_ERROR = '[Invite] Get invite Error';

export const RESEND_INVITE = '[Invite] Resend invite';
export const RESEND_INVITE_SUCCESS = '[Invite] Resend invite Success';
export const RESEND_INVITE_ERROR = '[Invite] Resend invite Error';

export const DELETE_INVITE = '[Invite] Delete invite';
export const DELETE_INVITE_SUCCESS = '[Invite] Delete invite Success';
export const DELETE_INVITE_ERROR = '[Invite] Delete invite Error';
