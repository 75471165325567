import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Form, Input, Button, Typography } from 'antd';
import { UnlockOutlined } from '@ant-design/icons';
import { ForgotPassword, ResendCode } from '../../store/auth/action-creators';
import FormWrapper from './form-wrapper';
import ForgotPasswordData from '../../types/auth/forgot-password-data';
import AppState from '../../store/state';
import { FORGOT_PASSWORD } from '@webapp/store/auth/actions';

export default function ForgotPasswordVerifyForm(): JSX.Element {
  const dispatch = useDispatch();

  const urlParams = new URLSearchParams(window.location.search);

  const username = useSelector((state: AppState) => state.auth.registrationData?.email);

  const loading = useSelector((state: AppState) => !!state.ui.loading[FORGOT_PASSWORD]);

  const onFinish = ({ code, newPassword }: ForgotPasswordData) => {
    if (!username) {
      return;
    }

    dispatch(
      new ForgotPassword({
        username,
        code,
        newPassword,
      })
    );
  };

  const resendCode = (ev: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    ev.preventDefault();

    if (!username) {
      return;
    }

    dispatch(new ResendCode(username));
  };

  return (
    <FormWrapper title="New password" subtitle="Enter the code we sent you by email" hasBack>
      <Form autoComplete="off" name="basic" layout="vertical" initialValues={{ remember: true }} onFinish={onFinish}>
        <Form.Item
          name="code"
          className="form__wrapper__item"
          initialValue={urlParams.get('code') || ''}
          rules={[{ required: true, message: 'Please input your verification code!' }]}
        >
          <Input placeholder="Code" size="large" autoComplete="code" />
        </Form.Item>

        <Form.Item
          className="form__wrapper__item"
          name="newPassword"
          rules={[{ required: true, message: 'Please input your new password!' }]}
        >
          <Input.Password
            size="large"
            placeholder="New Password"
            prefix={<UnlockOutlined className="form__wrapper__item__icon" />}
            autoComplete="new-password"
          />
        </Form.Item>

        <Form.Item className="form__wrapper__button">
          <Button type="primary" htmlType="submit" size="large" loading={loading}>
            Reset password
          </Button>
          <Typography.Paragraph className="form__wrapper__button__register">
            Code not received or expired?
            <br />
            <a href="#" onClick={(ev) => resendCode(ev)}>
              Resend Code
            </a>
          </Typography.Paragraph>
        </Form.Item>
      </Form>
    </FormWrapper>
  );
}
