import React, { ReactElement } from 'react';

import { Row, Space } from 'antd';

import IconLogo from '@webapp/components/icons/logo';
import CheckoutWrapper from '@webapp/components/checkout';
import PageBackground from '@webapp/components/layout/page-background';

export default function CheckoutPage(): ReactElement {
  return (
    <PageBackground>
      <Row className="subscription" justify="center" align="middle" style={{ minHeight: '100vh' }}>
        <Space direction="vertical" align="start" size="large">
          <IconLogo className="subscription__logo" />
          <CheckoutWrapper />
        </Space>
      </Row>
    </PageBackground>
  );
}
