export class ApiError extends Error {
  constructor(
    public message: string,
    public statusCode?: number,
    public description?: string
  ) {
    super(message);
    const actualProto = new.target.prototype;
    Object.setPrototypeOf(this, actualProto);
  }
}

export default ApiError;