import { Forecast } from '../../../../backend/services/forecast/types/entities/forecast';
import AppState from '../state';

export const selectForecasts = (state: AppState): Forecast[] => state.forecast.list.items;

export const groupForecastsByCampaign = (state: AppState): Record<string, Forecast[]> =>
  selectForecasts(state).reduce((memo: Record<string, Forecast[]>, forecast) => {
    if (!(forecast.campaignId in memo)) {
      memo[forecast.campaignId] = [];
    }
    memo[forecast.campaignId].push(forecast);

    return memo;
  }, {});
