import React, { useState } from 'react';

import './styles.less';

import { useParams } from 'react-router';
import { push } from 'connected-react-router';
import { useDispatch, useSelector } from 'react-redux';

import { Space, Typography, Skeleton } from 'antd';

import { LeftOutlined } from '@ant-design/icons';

import Page from '@webapp/components/layout/page';
import BarGraph from '@webapp/components/bar-graph';
import Statistics from '@webapp/components/statistics';
import ConversionRate from '@webapp/components/conversion-rate';
import ScenarioEditorModal from '@webapp/components/modal/scenario-editor';

import { selectFunnelTypes } from '@webapp/store/funnel-type/selectors';

import AppState from '@webapp/store/state';
import { GetScenario } from '@webapp/store/scenario/action-creators';
import { GET_SCENARIO } from '@webapp/store/scenario/actions';

import { Scenario } from '../../../../../backend/services/scenario/types/entities/scenario';

export default function ScenarioDetailsPage(): JSX.Element {
  const dispatch = useDispatch();

  const [isModalVisible, setIsModalVisible] = useState(false);

  const { id, clientId } = useParams<{ clientId: string; id: string }>();

  const funnelTypes = useSelector(selectFunnelTypes);

  const scenario: Scenario | undefined = useSelector((state: AppState) => state.scenario.active);

  const isLoading = !!useSelector((state: AppState) => !!state.ui.loading[GET_SCENARIO]);

  React.useEffect(() => {
    dispatch(new GetScenario(clientId, id));
  }, [id, clientId]);

  const handleEdit = () => {
    setIsModalVisible(true);
  };
  const handleBack = () => {
    dispatch(push('/scenarios'));
  };

  return (
    <Page className="scenario-details">
      <div className="scenario-details__back" onClick={handleBack}>
        <LeftOutlined className="scenario-details__back__icon" />
        <Typography.Link className="scenario-details__back__link" strong>
          Scenarios
        </Typography.Link>
      </div>
      {scenario ? (
        <>
          <Space
            align="start"
            style={{ justifyContent: 'space-between', width: '100%' }}
            className="scenario-details__header"
          >
            <Typography.Title level={3} className="scenario-details__header__title">
              {scenario.name}
            </Typography.Title>
            <Space align="end" size="large">
              <Typography.Link className="scenario-details__header__link" strong onClick={handleEdit}>
                Edit
              </Typography.Link>
            </Space>
          </Space>
          <Space
            className="scenario-details__wrapper"
            align="start"
            style={{ justifyContent: 'space-between', width: '100%' }}
            size="large"
          >
            <BarGraph
              stages={scenario.stages}
              costStage={scenario.costStage}
              valueStage={scenario.valueStage}
              budget={scenario.budget}
              revenue={scenario.revenue}
              itemClassName="scenario"
            />
            <ConversionRate rates={scenario.stages.slice(1).map((s) => s.conversionRate || 0)} />
          </Space>
          <Statistics
            className="scenario-details__statistics"
            budget={scenario.budget}
            revenue={scenario.revenue}
            ROI={scenario.ROI}
          />
        </>
      ) : null}
      {isLoading ? <Skeleton /> : null}
      {isModalVisible ? (
        <ScenarioEditorModal
          funnelTypes={funnelTypes}
          scenario={scenario}
          isModalVisible={isModalVisible}
          handleClose={() => setIsModalVisible(false)}
        />
      ) : null}
    </Page>
  );
}
