import { Observable } from 'rxjs';
import { ofType, StateObservable } from 'redux-observable';
import { mergeMap, withLatestFrom } from 'rxjs/operators';
import * as funnelTypes from '../../../sdk/funnel-types';
import { Actions } from '../../actions';
import AppState from '../../state';
import { DeleteFunnelType, DeleteFunnelTypeSuccess, DeleteFunnelTypeError } from '../action-creators';
import { DELETE_FUNNEL_TYPE } from '../actions';
import { NoOp } from '../../ui/action-creators';
import ApiError from '../../../../../backend/commons/utils/api/api-error';

export const deleteFunnelTypeEpic = (
  action$: Observable<Actions>,
  state$: StateObservable<AppState>
): Observable<Actions> =>
  action$.pipe(
    ofType<Actions, DeleteFunnelType>(DELETE_FUNNEL_TYPE),
    withLatestFrom(state$),
    mergeMap(async ([action, state]) => {
      if (!state.client.active?.id) {
        return new NoOp();
      }

      try {
        const result = await funnelTypes.remove(state.client.active.id, action.funnelTypeId);

        return new DeleteFunnelTypeSuccess(action.funnelTypeId, result);
      } catch (err) {
        return new DeleteFunnelTypeError((err as ApiError).message, (err as ApiError).description || '');
      }
    })
  );
