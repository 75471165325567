import { Observable } from 'rxjs';
import { Action } from 'redux';
import { Actions } from '../../actions';
import * as authActions from '../../auth/actions';
import { ofType } from 'redux-observable';
import { map } from 'rxjs/operators';
import { LoginUserSuccess } from '../../auth/action-creators';
import { NoOp } from '../../ui/action-creators';

export const trackUserLoggedInEpic = (action$: Observable<Actions>): Observable<Action> =>
  action$.pipe(
    ofType<Actions, LoginUserSuccess>(authActions.LOGIN_USER_SUCCESS),
    map((action) => {
      if ((window as any).customerly) {
        const params = {
          app_id: process.env.REACT_APP_CUSTOMERLY_APP_ID,
          user_id: action.user.attributes.sub,
          name: `${action.user.attributes.given_name} ${action.user.attributes.family_name}`,
          email: action.user.attributes.email,
          attributes: {},
          company: {
            company_id: action.user.attributes['custom:companyId'],
            name: action.user.attributes['custom:company'],
            company_type: action.user.attributes['custom:companyType'],
          },
        };
        (window as any).customerly.load(params);
      }
      return new NoOp();
    })
  );
