import React from 'react';

import { Row, Space } from 'antd';

import PageBackground from '@webapp/components/layout/page-background';
import ForgotPasswordForm from '@webapp/components/auth/forgot-password';

export default function ForgotPasswordPage(): JSX.Element {
  return (
    <PageBackground>
      <Row justify="center" align="middle" style={{ minHeight: '100vh' }}>
        <Space direction="vertical" align="center">
          <ForgotPasswordForm />
        </Space>
      </Row>
    </PageBackground>
  );
}
