import React from 'react';
import './styles.less';

import { Upload } from 'antd';
import { UploadRequestOption, UploadProgressEvent } from 'rc-upload/lib/interface';
import { InboxOutlined } from '@ant-design/icons';
import * as asset from '@webapp/sdk/assets';
import { UploadFile } from 'antd/lib/upload/interface';
import { FileType } from '../../../../backend/services/asset/functions/get-upload-url/interface';

import { Image } from '@webapp/components/image';

interface DragnDropProps {
  className?: string;
  current?: string;
  type: FileType;
  onUpload?: (url: string) => void;
  onUploadStart?: () => void;
}

export default function DragnDrop({ className, current, type, onUpload, onUploadStart }: DragnDropProps): JSX.Element {
  const handleUpload = async ({ file, onProgress, onSuccess }: UploadRequestOption) => {
    if (onUploadStart) {
      onUploadStart();
    }
    const response = await asset.upload(type, file as File);

    if (onProgress) {
      response.upload.progress.subscribe((p) => onProgress({ percent: p } as UploadProgressEvent));
    }

    await response.upload.request;

    if (onSuccess) {
      onSuccess(file, response.upload.xhr);
    }

    if (onUpload) {
      onUpload(response.url);
    }

    return false;
  };

  const initialList: UploadFile<unknown>[] = [];
  const _buffer = Buffer.from('');
  const _blob = new Blob([_buffer]);

  if (current) {
    initialList.push({
      uid: '1',
      name: 'logo',
      status: 'done',
      url: current,
      size: 1,
      type: '',
      originFileObj: {
        uid: '1',
        lastModified: Number(new Date()),
        lastModifiedDate: new Date(),
        name: 'logo',
        ..._blob,
      },
    } as UploadFile<unknown>);
  }

  return (
    <Upload.Dragger
      name="file"
      className={`${className || ''} dragndrop`}
      multiple={false}
      customRequest={handleUpload}
      accept="image/*"
      defaultFileList={initialList}
      onRemove={onUpload ? () => onUpload('') : undefined}
    >
      <div className="ant-upload-drag-icon">
        {current ? (
          <div className="dragndrop__wrapper">
            <Image className="dragndrop__wrapper__img" src={current} />
          </div>
        ) : (
          <InboxOutlined />
        )}
      </div>
      <p className="ant-upload-text">Click or drag file to this area to upload</p>
      <p className="ant-upload-hint">
        File types: JPG/PNG.
        <br />
        Min: 200x200px / Max: 1000x1000px
      </p>
    </Upload.Dragger>
  );
}
