import { Observable } from 'rxjs';
import { ofType } from 'redux-observable';
import { map } from 'rxjs/operators';
import { Actions } from '../../actions';
import { ListCampaign } from '../action-creators';
import { SetActiveClient } from '../../client/action-creators';
import { SET_ACTIVE_CLIENT } from '../../client/actions';

export const listCampaignAfterActiveEpic = (action$: Observable<Actions>): Observable<Actions> =>
  action$.pipe(
    ofType<Actions, SetActiveClient>(SET_ACTIVE_CLIENT),
    map((action) => new ListCampaign(action.client.id))
  );
