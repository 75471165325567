import React from 'react';
import AppState from '../../store/state';
import { useDispatch, useSelector } from 'react-redux';

import { Form, Input, Button, Typography } from 'antd';

import { ResendCode, VerifyAccount } from '../../store/auth/action-creators';

import FormWrapper from './form-wrapper';

import './styles.less';
import { VERIFY_ACCOUNT, VERIFY_ACCOUNT_ERROR } from '@webapp/store/auth/actions';

export default function VerificationForm(): JSX.Element {
  const dispatch = useDispatch();

  const urlParams = new URLSearchParams(window.location.search);

  const username = useSelector(
    (state: AppState) => state.auth.registrationData?.email || decodeURIComponent(urlParams.get('username') || '')
  );

  const loading = useSelector((state: AppState) => !!state.ui.loading[VERIFY_ACCOUNT]);

  const verificationFailed = useSelector((state: AppState) => state.ui.errors[VERIFY_ACCOUNT_ERROR]);

  const onFinish = ({ code }: { code: string }) => {
    if (!username) {
      return;
    }

    dispatch(
      new VerifyAccount({
        account: username,
        code,
      })
    );
  };

  const resendCode = (ev: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    ev.preventDefault();

    if (!username) {
      return;
    }

    dispatch(new ResendCode(username));
  };

  return (
    <FormWrapper title="Verify Code" subtitle="Check your email for the verification code">
      {verificationFailed ? (
        <Typography.Text className="error" strong type="danger">
          The verification code is invalid
        </Typography.Text>
      ) : null}
      <Form name="basic" layout="vertical" initialValues={{ remember: true }} onFinish={onFinish}>
        <Form.Item
          name="code"
          className="form__wrapper__item"
          initialValue={urlParams.get('code') || ''}
          rules={[{ required: true, message: 'Please input your Verification Code!' }]}
        >
          <Input placeholder="Verification Code" size="large" />
        </Form.Item>

        <Form.Item className="form__wrapper__button">
          <Button type="primary" htmlType="submit" size="large" loading={loading}>
            Verify Code
          </Button>
          <Typography.Paragraph className="form__wrapper__button__register">
            Code not received or expired?
            <br />
            <a href="#" onClick={(ev) => resendCode(ev)}>
              Resend Code
            </a>
          </Typography.Paragraph>
        </Form.Item>
      </Form>
    </FormWrapper>
  );
}
