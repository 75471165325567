import React from 'react';

export default function IconLogo({
  className,
  isWhite,
  onClick,
}: {
  className?: string;
  isWhite?: boolean;
  onClick?: () => void;
}): JSX.Element {
  return (
    <svg
      x="0"
      y="0"
      viewBox="0 0 320 64"
      onClick={() => (onClick ? onClick() : null)}
      className={className}
      style={{ cursor: `${onClick ? 'pointer' : 'auto'}` }}
    >
      <circle fill={isWhite ? '#ffffff' : '#0E2B62'} cx="23.5" cy="40.5" r="6.6" />
      <circle fill={isWhite ? '#ffffff' : '#0E2B62'} cx="40.5" cy="40.5" r="6.6" />
      <circle fill={isWhite ? '#ffffff' : '#0E2B62'} cx="32" cy="57.4" r="6.6" />
      <circle fill={isWhite ? '#ffffff' : '#0E2B62'} cx="15.1" cy="23.5" r="6.6" />
      <path
        fill={isWhite ? '#ffffff' : '#2296E1'}
        d="M48.9 30.1H32c-3.6 0-6.6-2.9-6.6-6.6v0c0-3.6 3-6.6 6.6-6.6h16.9c3.6 0 6.6 3 6.6 6.6v0C55.5 27.2 52.6 30.1 48.9 30.1z"
      />
      <circle fill={isWhite ? '#ffffff' : '#0E2B62'} cx="6.6" cy="6.6" r="6.6" />
      <path
        fill={isWhite ? '#ffffff' : '#2296E1'}
        d="M57.4 13.2H23.5c-3.6 0-6.6-2.9-6.6-6.6v0c0-3.6 3-6.6 6.6-6.6l33.9 0C61.1 0 64 3 64 6.6v0C64 10.2 61.1 13.2 57.4 13.2z"
      />
      <path
        fill={isWhite ? '#ffffff' : '#0E2B62'}
        d="M92.2 53h5.1V27h6.2v-4.1h-6.2v-3.3c0-3 1.3-4.4 4-4.4 0.8 0 1.7 0.1 2.3 0.2v-4.1c-0.7-0.1-1.9-0.2-3.1-0.2 -5.6 0-8.3 2.8-8.3 8.5v3.4H88v4.1h4.2V53zM133.4 22.9h-5v17.7c0 5.1-3 8.5-7.9 8.5 -4.7 0-7-2.6-7-7.5V22.9h-5v19.5c0 6.8 3.9 11.1 10.5 11.1 4.6 0 7.8-1.9 9.6-5.4h0.1v4.9h4.8V22.9zM140 53h5.1v-17.7c0-5.1 3.1-8.4 7.9-8.4 4.6 0 6.9 2.7 6.9 7.5v18.6h5.1V33.4c0-6.8-3.8-11.1-10.4-11.1 -4.7 0-7.9 2.1-9.6 5.3h-0.1v-4.8h-4.8V53zM171.3 53h5.1v-17.7c0-5.1 3.1-8.4 7.9-8.4 4.6 0 6.9 2.7 6.9 7.5v18.6h5.1V33.4c0-6.8-3.8-11.1-10.4-11.1 -4.7 0-7.9 2.1-9.6 5.3h-0.1v-4.8h-4.8V53zM222.7 44.4c-0.8 2.8-3.7 4.8-7.5 4.8 -5.4 0-8.8-3.8-8.8-9.5v-0.3h21.7v-1.9c0-9.1-5-15-13.2-15 -8.3 0-13.7 6.3-13.7 15.7 0 9.5 5.3 15.4 13.9 15.4 6.6 0 11.7-3.9 12.6-9.1H222.7zM214.8 26.7c4.8 0 7.9 3.6 8 8.6h-16.4C206.7 30.4 210 26.7 214.8 26.7zM233.4 53h5.1V11.2h-5V53zM246.9 15.6v7.3h-4.5v4.1h4.5v18.1c0 5.8 2.2 8 7.7 8 1.2 0 2.6-0.1 3.2-0.2v-4.2c-0.3 0.1-1.5 0.1-2.1 0.1 -2.6 0-3.7-1.2-3.7-4V27h5.9v-4.1h-5.9v-7.3H246.9zM271.4 53.5c4.1 0 7.9-2.3 9.7-5.7h0.1v5.2h4.8V32.2c0-6-4.5-9.8-11.5-9.8 -7.1 0-11.6 4-11.9 9.2h4.9c0.6-2.9 3.1-4.8 7-4.8 4.2 0 6.6 2.2 6.6 5.9v2.5l-8.6 0.5c-7 0.4-10.9 3.6-10.9 8.8C261.4 49.9 265.4 53.5 271.4 53.5zM272.6 49.2c-3.6 0-6-1.9-6-4.7 0-2.8 2.3-4.6 6.4-4.9l8.1-0.5v2.5C281 45.9 277.3 49.2 272.6 49.2zM307.1 22.4c-4.4 0-7.9 2.2-9.7 5.6h-0.1v-5.1h-4.8V63h5.1V48.1h0.1c1.8 3.3 5.3 5.4 9.6 5.4 7.6 0 12.8-6.1 12.8-15.6S314.8 22.4 307.1 22.4zM306.1 49.1c-5.2 0-8.6-4.4-8.6-11.1 0-6.7 3.5-11.1 8.7-11.1 5.4 0 8.7 4.3 8.7 11.1C314.8 44.8 311.5 49.1 306.1 49.1z"
      />
    </svg>
  );
}
