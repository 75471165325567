import * as React from 'react';

// import Home from '@webapp/pages/home';
import Clients from '@webapp/pages/clients';
import LoginPage from '@webapp/pages/auth/login';
import CampaignsPage from '@webapp/pages/campaigns';
import ForecastsPage from '@webapp/pages/forecasts';
import ScenariosPage from '@webapp/pages/scenarios';
import FunnelTypesPage from '@webapp/pages/funnel-types';
import PlansPage from '@webapp/pages/subscription/plans';
import CheckoutPage from '@webapp/pages/subscription/checkout';
import RegistrationPage from '@webapp/pages/auth/registration';
import VerificationPage from '@webapp/pages/auth/verification';
import AcceptInvitePage from '@webapp/pages/auth/accept-invite';
import CampaignDetailsPage from '@webapp/pages/campaigns/details';
import ScenarioDetailsPage from '@webapp/pages/scenarios/details';
import ForecastsDetailsPage from '@webapp/pages/forecasts/details';
import ForgotPasswordPage from '@webapp/pages/auth/forgot-password';
import ForgotPasswordVerifyPage from '@webapp/pages/auth/forgot-password-verify';

import SettingsPage from '@webapp/pages/settings';

import { SettingOutlined, NotificationOutlined, RiseOutlined } from '@ant-design/icons';
import FunnelIcon from '@webapp/components/icons/funnel';
import ScenarioIcon from '@webapp/components/icons/scenario';
import TeamPage from '@webapp/pages/team';
import BillingPage from '@webapp/pages/billing';
import ProfilePage from '@webapp/pages/profile';
import FunnelTemplatesPage from '@webapp/pages/funnel-templates';

export default [
  {
    path: '/registration',
    component: RegistrationPage,
    label: 'Registration',
    onMenu: false,
    exact: true,
  },
  {
    path: '/login',
    component: LoginPage,
    label: 'Login',
    onMenu: false,
    exact: true,
  },
  {
    path: '/verify-code',
    component: VerificationPage,
    label: 'verification',
    onMenu: false,
    exact: true,
  },
  {
    path: '/forgot-password',
    component: ForgotPasswordPage,
    label: 'forgot-password',
    onMenu: false,
    exact: true,
  },
  {
    path: '/forgot-password/verify',
    component: ForgotPasswordVerifyPage,
    label: 'forgot-password-verify',
    onMenu: false,
    exact: true,
  },
  {
    path: '/accept-invite/:id',
    component: AcceptInvitePage,
    label: 'Accept Invite',
    onMenu: false,
    exact: true,
  },
  {
    path: '/clients',
    component: Clients,
    label: 'Clients',
    onMenu: false,
    exact: true,
  },
  {
    path: '/plans',
    component: PlansPage,
    label: 'Plans',
    onMenu: false,
    exact: true,
  },
  {
    path: '/checkout',
    component: CheckoutPage,
    label: 'Checkout',
    onMenu: false,
    exact: true,
  },
  {
    path: '/funnels',
    component: FunnelTypesPage,
    label: 'Funnels',
    icon: <FunnelIcon />,
    onMenu: true,
    exact: true,
    requiresClient: true,
  },
  {
    path: '/campaigns',
    component: CampaignsPage,
    label: 'Campaigns',
    icon: <NotificationOutlined />,
    onMenu: true,
    exact: true,
    requiresClient: true,
  },
  {
    path: '/campaigns/:clientId/:id',
    component: CampaignDetailsPage,
    label: 'Campaign Details',
    onMenu: false,
    exact: true,
  },
  {
    path: '/forecasts',
    component: ForecastsPage,
    label: 'Forecasts',
    icon: <RiseOutlined />,
    onMenu: true,
    exact: true,
    requiresClient: true,
  },
  {
    path: '/forecasts/:clientId/:id',
    component: ForecastsDetailsPage,
    label: 'Forecast Details',
    onMenu: false,
    exact: true,
  },
  {
    path: '/scenarios',
    component: ScenariosPage,
    label: 'Scenarios',
    icon: <ScenarioIcon />,
    onMenu: true,
    exact: true,
    requiresClient: true,
  },
  {
    path: '/scenarios/:clientId/:id',
    component: ScenarioDetailsPage,
    label: 'Scenario Details',
    onMenu: false,
    exact: true,
  },
  {
    path: '/team',
    component: TeamPage,
    label: 'Team',
    onMenu: false,
    exact: true,
    requiresOwner: true,
  },
  {
    path: '/funnel-templates',
    component: FunnelTemplatesPage,
    label: 'Funnel Templates',
    onMenu: false,
    exact: true,
    requiresOwner: true,
  },
  {
    path: '/profile',
    component: ProfilePage,
    label: 'Profile',
    onMenu: false,
    exact: true,
  },
  {
    path: '/settings',
    component: SettingsPage,
    hasClass: 'settings',
    label: 'Settings',
    onMenu: true,
    icon: <SettingOutlined />,
    exact: true,
    requiresOwner: true,
  },
  {
    path: '/settings/subscription',
    component: BillingPage,
    label: 'Subscription',
    onMenu: false,
    exact: true,
    requiresOwner: true,
  },
];
