import * as actions from './actions';
import { LoadingAction } from '../common/loading-action';
import { ErrorAction } from '../common/error-action';
import { Forecast } from '../../../../backend/services/forecast/types/entities/forecast';
import { CreateForecastBodyRequest } from '../../../../backend/services/forecast/functions/create-forecast/interface';
import { ListForecastResponse } from '../../../../backend/services/forecast/functions/list-forecast/interface';
import { UpdateForecastBodyRequest } from '../../../../backend/services/forecast/functions/update-forecast/interface';
import { DeleteForecastResponse } from '../../../../backend/services/forecast/functions/delete-forecast/interface';

export class ListForecast implements LoadingAction {
  readonly type = actions.LIST_FORECAST;
  readonly isLoading = true;
  readonly mainLoader = true;

  constructor(public clientId: string) {}
}

export class ListForecastSuccess implements LoadingAction {
  readonly type = actions.LIST_FORECAST_SUCCESS;
  readonly isLoading = false;

  constructor(public results: ListForecastResponse) {}
}

export class ListForecastError implements ErrorAction {
  readonly type = actions.LIST_FORECAST_ERROR;
  readonly isError = true;

  constructor(public code: string, public description: string | string[]) {}
}

export class CreateForecast implements LoadingAction {
  readonly type = actions.CREATE_FORECAST;
  readonly isLoading = true;
  readonly mainLoader = true;

  constructor(public newForecast: CreateForecastBodyRequest) {}
}

export class CreateForecastSuccess implements LoadingAction {
  readonly type = actions.CREATE_FORECAST_SUCCESS;
  readonly isLoading = false;

  constructor(public forecast: Forecast) {}
}

export class CreateForecastError implements ErrorAction {
  readonly type = actions.CREATE_FORECAST_ERROR;
  readonly isError = true;

  constructor(public code: string, public description: string | string[]) {}
}

export class GetForecast implements LoadingAction {
  readonly type = actions.GET_FORECAST;
  readonly isLoading = true;
  readonly mainLoader = true;

  constructor(public clientId: string, public id: string) {}
}

export class GetForecastSuccess implements LoadingAction {
  readonly type = actions.GET_FORECAST_SUCCESS;
  readonly isLoading = false;

  constructor(public forecast: Forecast) {}
}

export class GetForecastError implements ErrorAction {
  readonly type = actions.GET_FORECAST_ERROR;
  readonly isError = true;

  constructor(public code: string, public description: string | string[]) {}
}

export class UpdateForecast implements LoadingAction {
  readonly type = actions.UPDATE_FORECAST;
  readonly isLoading = true;
  readonly mainLoader = true;

  constructor(public forecastId: string, public forecast: UpdateForecastBodyRequest) {}
}

export class UpdateForecastSuccess implements LoadingAction {
  readonly type = actions.UPDATE_FORECAST_SUCCESS;
  readonly isLoading = false;

  constructor(public forecast: Forecast) {}
}

export class UpdateForecastError implements ErrorAction {
  readonly type = actions.UPDATE_FORECAST_ERROR;
  readonly isError = true;

  constructor(public code: string, public description: string | string[]) {}
}

export class DeleteForecast implements LoadingAction {
  readonly type = actions.DELETE_FORECAST;
  readonly isLoading = true;
  readonly mainLoader = true;

  constructor(public forecastId: string) {}
}

export class DeleteForecastSuccess implements LoadingAction {
  readonly type = actions.DELETE_FORECAST_SUCCESS;
  readonly isLoading = false;

  constructor(public forecastId: string, public result: DeleteForecastResponse) {}
}

export class DeleteForecastError implements ErrorAction {
  readonly type = actions.DELETE_FORECAST_ERROR;
  readonly isError = true;

  constructor(public code: string, public description: string | string[]) {}
}

export type ForecastActions =
  | ListForecast
  | ListForecastSuccess
  | ListForecastError
  | CreateForecast
  | CreateForecastSuccess
  | CreateForecastError
  | GetForecast
  | GetForecastSuccess
  | GetForecastError
  | UpdateForecast
  | UpdateForecastSuccess
  | UpdateForecastError
  | DeleteForecast
  | DeleteForecastSuccess
  | DeleteForecastError;
