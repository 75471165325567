import { ScenarioState } from './state';
import { scenarioInitialState } from './initial-state';
import { ScenarioActions } from './action-creators';
import * as actions from './actions';

export const scenarioReducer = (
  state: ScenarioState = scenarioInitialState,
  action: ScenarioActions
): ScenarioState => {
  switch (action.type) {
    case actions.LIST_SCENARIO_SUCCESS: {
      return {
        ...state,
        list: {
          items: action.results.items || [],
          nextToken: action.results.nextToken,
        },
      };
    }

    case actions.CREATE_SCENARIO_SUCCESS: {
      return {
        ...state,
        list: {
          ...state.list,
          items: state.list.items.concat([action.scenario]),
        },
      };
    }

    case actions.UPDATE_SCENARIO_SUCCESS: {
      return {
        ...state,
        list: {
          ...state.list,
          items: state.list.items.map((c) => {
            if (c.id === action.scenario.id) {
              return action.scenario;
            }
            return { ...c };
          }),
        },
        active: state.active?.id === action.scenario.id ? { ...action.scenario } : state.active,
      };
    }

    case actions.DELETE_SCENARIO_SUCCESS: {
      return {
        ...state,
        list: {
          ...state.list,
          items: state.list.items.filter((c) => c.id !== action.scenarioId),
        },
      };
    }

    case actions.GET_SCENARIO: {
      return {
        ...state,
        active: undefined,
      };
    }

    case actions.GET_SCENARIO_SUCCESS: {
      return {
        ...state,
        active: action.scenario,
      };
    }

    default: {
      return { ...state };
    }
  }
};
