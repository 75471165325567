import React, { useState } from 'react';

import { Button, Empty, Space, Typography } from 'antd';

import Page from '@webapp/components/layout/page';
import FunnelTypeEditorModal from '@webapp/components/modal/funnel-type-editor';
import FunnelTypesTable from '@webapp/components/table/funnel-type-table';
import CustomTextModal from '@webapp/components/modal/custom-text';
import { useDispatch, useSelector } from 'react-redux';
import { selectFunnelTypes } from '../../store/funnel-type/selectors';
import { FunnelType } from '../../../../backend/services/funnel-type/types/entities/funnel-type';
import { CreateFunnelType, UpdateFunnelType, DeleteFunnelType } from '../../store/funnel-type/action-creators';
import { CreateFunnelTypeBodyRequest } from '../../../../backend/services/funnel-type/functions/create-funnel-type/interface';
import { UpdateFunnelTypeBodyRequest } from '../../../../backend/services/funnel-type/functions/update-funnel-type/interface';
import AppState from '../../store/state';
import { CREATE_FUNNEL_TYPE } from '../../store/funnel-type/actions';
import { CreateFunnelTemplate } from '@webapp/store/funnel-template/action-creators';
import { CreateFunnelTemplateBodyRequest } from '@backend/services/funnel-template/functions/create-funnel-template/interface';

export default function FunnelTypesPage(): JSX.Element {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false);

  const [selectedFunnelType, setSelectedFunnelType] = useState<Partial<FunnelType>>();

  const funnelTypes = useSelector(selectFunnelTypes);
  const isLoadingNew = useSelector((state: AppState) => !!state.ui.loading[CREATE_FUNNEL_TYPE]);

  const dispatch = useDispatch();

  const handleNew = () => {
    setSelectedFunnelType(undefined);
    setIsModalVisible(true);
  };

  const handleClose = () => {
    setIsModalVisible(false);
  };

  const handleCloseDelete = () => {
    setIsDeleteModalVisible(false);
  };

  const handleEdit = (funnelType: FunnelType) => {
    setSelectedFunnelType(funnelType);
    setIsModalVisible(true);
  };

  const handleClone = (funnelType: FunnelType) => {
    setSelectedFunnelType({
      ...funnelType,
      name: `${funnelType.name} Clone`,
      id: undefined,
    });
    setIsModalVisible(true);
  };

  const handleDelete = () => {
    if (selectedFunnelType?.id) {
      dispatch(new DeleteFunnelType(selectedFunnelType.id));
    }
    handleCloseDelete();
  };

  const openDeleteModal = (funnelType: FunnelType) => {
    setSelectedFunnelType(funnelType);
    setIsDeleteModalVisible(true);
  };

  const handleSave = (funnelType: Partial<FunnelType>) => {
    if (funnelType.id) {
      setSelectedFunnelType(funnelType as FunnelType);
      return dispatch(new UpdateFunnelType(funnelType.id, funnelType as UpdateFunnelTypeBodyRequest));
    }

    return dispatch(new CreateFunnelType(funnelType as CreateFunnelTypeBodyRequest));
  };

  const handleSaveFunnelTemplate = (funnelType: Partial<FunnelType>) => {
    return dispatch(new CreateFunnelTemplate(funnelType as CreateFunnelTemplateBodyRequest));
  };

  return (
    <Page>
      <Space align="start" style={{ justifyContent: 'space-between', width: '100%' }}>
        <Typography.Title level={3}>Funnels</Typography.Title>
        <Button type="primary" size="large" onClick={handleNew} loading={isLoadingNew}>
          New Funnel
        </Button>
      </Space>
      {funnelTypes.length ? (
        <FunnelTypesTable
          funnelTypes={funnelTypes}
          onEdit={handleEdit}
          onDelete={openDeleteModal}
          onClone={handleClone}
        />
      ) : (
        <Space
          className="funnel__empty"
          direction="vertical"
          align="center"
          style={{
            height: 'calc(100% - 80px - 88px)',
            width: '100%',
            marginTop: 80,
          }}
        >
          <Empty description={<Typography.Text>No Funnels yet</Typography.Text>}>
            <Button type="primary" size="large" onClick={handleNew} loading={isLoadingNew}>
              New Funnel
            </Button>
          </Empty>
        </Space>
      )}
      {isModalVisible ? (
        <FunnelTypeEditorModal
          funnelType={selectedFunnelType as FunnelType}
          isModalVisible={isModalVisible}
          onClose={handleClose}
          onSave={handleSave}
          onSaveFunnelTemplate={handleSaveFunnelTemplate}
        />
      ) : null}
      <CustomTextModal
        title="Are you sure you want to delete this Funnel?"
        text="You will not be able to undo this action"
        button={
          <Button danger onClick={handleDelete}>
            Delete
          </Button>
        }
        isModalVisible={isDeleteModalVisible}
        handleClose={handleCloseDelete}
      />
    </Page>
  );
}
