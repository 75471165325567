import React from 'react';
import './styles.less';

import { Typography } from 'antd';
import { TagOutlined, DollarOutlined } from '@ant-design/icons';
import { formatCurrency } from '@webapp/utils/format-currency';

export type StageType = 'cost' | 'value' | 'regular';

export interface BarGraphItemProps {
  className?: string;
  barClassName?: string;
  orderNumber: number;
  name: string;
  total?: number;
  price: number;
  number: number;
  barWidth: number;
  conversionRate: number;
  stageType: StageType;
}

export default function BarGraphItem({
  className,
  barClassName,
  orderNumber,
  name,
  total,
  price,
  number,
  barWidth,
  stageType,
}: BarGraphItemProps): JSX.Element {
  const [animationSpeed] = React.useState(1000 * (0.5 + Math.random()));
  const [animationWidth, setAnimationWidth] = React.useState(0);

  React.useEffect(() => {
    const timer = setTimeout(() => setAnimationWidth(barWidth), 100);

    return () => clearTimeout(timer);
  }, [barWidth]);

  const getIcon = (stageType: StageType) => {
    if (stageType === 'cost') {
      return <TagOutlined className="campaign-item__info-wrapper__left__budget__icon" />;
    } else if (stageType === 'value') {
      return <DollarOutlined className="campaign-item__info-wrapper__left__budget__icon" />;
    }
  };

  return (
    <div className={`${className || ''} campaign-item`}>
      <div className="campaign-item__info-wrapper__left">
        <Typography className="campaign-item__info-wrapper__left__order-number">{orderNumber}</Typography>
        <div>
          <Typography className="campaign-item__info-wrapper__left__name">{name}</Typography>
          <div className="campaign-item__info-wrapper__left__budget">
            {getIcon(stageType)}
            {total !== undefined ? (
              <Typography className="campaign-item__info-wrapper__left__budget__total">
                {formatCurrency(total)}
              </Typography>
            ) : null}
          </div>
        </div>
      </div>
      <div className="campaign-item__info-wrapper__right">
        <div className="campaign-item__info-wrapper__right__data">
          <Typography className="campaign-item__info-wrapper__right__data__number">
            {number.toLocaleString()}
          </Typography>
          <Typography className="campaign-item__info-wrapper__right__data__price">({formatCurrency(price)})</Typography>
        </div>
        <span
          className={`campaign-item__info-wrapper__right__bar ${barClassName || ''}`}
          style={{
            width: `${animationWidth}%`,
            transitionDuration: `${animationSpeed}ms`,
          }}
        />
      </div>
    </div>
  );
}
