import * as actions from './actions';
import { LoadingAction } from '../common/loading-action';
import { ErrorAction } from '../common/error-action';
import { Client } from '../../../../backend/services/client/types/entities/client';
import { Action } from 'redux';
import { PaginatedResponse } from '../../../../backend/commons/types/api-interface';
import { UpdateClientBodyRequest } from '../../../../backend/services/client/functions/update-client/interface';
import { CreateClientBodyRequest } from '../../../../backend/services/client/functions/create-client/interface';

export class ListClient implements LoadingAction {
  readonly type = actions.LIST_CLIENT;
  readonly isLoading = true;
  readonly mainLoader = true;
}

export class ListClientSuccess implements LoadingAction {
  readonly type = actions.LIST_CLIENT_SUCCESS;
  readonly isLoading = false;

  constructor(public clients: PaginatedResponse<Client>) {}
}

export class ListClientError implements ErrorAction {
  readonly type = actions.LIST_CLIENT_ERROR;
  readonly isError = true;

  constructor(public code: string, public description: string | string[]) {}
}

export class CreateClient implements LoadingAction {
  readonly type = actions.CREATE_CLIENT;
  readonly isLoading = true;
  readonly mainLoader = true;

  constructor(public newClient: CreateClientBodyRequest) {}
}

export class CreateClientSuccess implements LoadingAction {
  readonly type = actions.CREATE_CLIENT_SUCCESS;
  readonly isLoading = false;

  constructor(public client: Client) {}
}

export class CreateClientError implements ErrorAction {
  readonly type = actions.CREATE_CLIENT_ERROR;
  readonly isError = true;

  constructor(public code: string, public description: string | string[]) {}
}

export class DeleteClient implements LoadingAction {
  readonly type = actions.DELETE_CLIENT;
  readonly isLoading = true;
  readonly mainLoader = true;

  constructor(public clientId: string) {}
}

export class DeleteClientSuccess implements LoadingAction {
  readonly type = actions.DELETE_CLIENT_SUCCESS;
  readonly isLoading = false;

  constructor(public clientId: string) {}
}

export class DeleteClientError implements ErrorAction {
  readonly type = actions.DELETE_CLIENT_ERROR;
  readonly isError = true;

  constructor(public code: string, public description: string | string[]) {}
}

export class UpdateClient implements LoadingAction {
  readonly type = actions.UPDATE_CLIENT;
  readonly isLoading = true;
  readonly mainLoader = true;

  constructor(public clientId: string, public client: UpdateClientBodyRequest) {}
}

export class UpdateClientSuccess implements LoadingAction {
  readonly type = actions.UPDATE_CLIENT_SUCCESS;
  readonly isLoading = false;

  constructor(public client: Client) {}
}

export class UpdateClientError implements ErrorAction {
  readonly type = actions.UPDATE_CLIENT_ERROR;
  readonly isError = true;

  constructor(public code: string, public description: string | string[]) {}
}

export class SetActiveClient implements Action {
  readonly type = actions.SET_ACTIVE_CLIENT;

  constructor(public client: Client) {}
}

export type ClientActions =
  | ListClient
  | ListClientSuccess
  | ListClientError
  | CreateClient
  | CreateClientSuccess
  | CreateClientError
  | UpdateClient
  | UpdateClientSuccess
  | UpdateClientError
  | DeleteClient
  | DeleteClientSuccess
  | DeleteClientError
  | SetActiveClient;
