import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';
import { ErrorAction } from '../../common/error-action';
import { notification } from 'antd';
import { NoOp } from '../action-creators';

export const showErrorsEpic = (action$: Observable<ErrorAction>): Observable<NoOp> =>
  action$.pipe(
    filter((action) => action.isError && !action.code.includes('NOT_FOUND')),
    map((action) => {
      notification.error({
        message: 'Error',
        description: action.description,
      });

      return new NoOp();
    })
  );
