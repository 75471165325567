import { BehaviorSubject } from 'rxjs';
import { CallApi, handleJSONResponse } from './common';
import {
  FileType,
  GetUploadUrlRequestBody,
  GetUploadUrlResponse,
} from '../../../backend/services/asset/functions/get-upload-url/interface';

export interface UploadS3Response {
  progress: BehaviorSubject<number>;
  request: Promise<null>;
  cancel: () => void;
  xhr: XMLHttpRequest;
}

export interface UploadResponse {
  id: string;
  url: string;
  key: string;
  upload: UploadS3Response;
}

const callAPI = CallApi('asset');

const startUpload = async (data: GetUploadUrlRequestBody): Promise<GetUploadUrlResponse> => {
  const response = await callAPI('/upload', 'POST', data);

  return await handleJSONResponse(response);
};

const uploadToS3 = (file: File, form: GetUploadUrlResponse['formData']): UploadS3Response => {
  const data = new FormData();

  Object.keys(form.fields).forEach((k) => data.append(k, form.fields[k]));
  data.append('file', file);

  const progress = new BehaviorSubject(0);
  const xhr = new XMLHttpRequest();

  const cancel = () => {
    xhr.abort();
  };

  const request = new Promise<null>((resolve, reject) => {
    xhr.open('POST', form.url);

    xhr.upload.addEventListener('load', () => resolve(null));
    xhr.upload.addEventListener('abort', () => reject('ASSET.UPLOAD_CANCELED'));
    xhr.upload.addEventListener('error', () => reject());
    xhr.upload.addEventListener('progress', (p) => {
      if (p.total && p.loaded) {
        const percent = Math.floor((p.loaded / p.total) * 100);
        progress.next(percent);
      }
    });

    xhr.send(data);
  });

  return {
    progress,
    request,
    cancel,
    xhr,
  };
};

export const upload = async (type: FileType, file: File): Promise<UploadResponse> => {
  const form: GetUploadUrlRequestBody = {
    type,
    filename: file.name,
    contentType: file.type,
  };

  const { formData, ...data } = await startUpload(form);

  return {
    ...data,
    upload: uploadToS3(file, formData),
  };
};
