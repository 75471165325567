import React from 'react';

import { Card, Typography } from 'antd';

import { LeftCircleTwoTone } from '@ant-design/icons';

import IconLogo from '../icons/logo';
import { useHistory } from 'react-router';

const FormWrapper: React.FC<{
  children: React.ReactNode;
  className?: string;
  title: string;
  subtitle?: string;
  hasBack?: boolean;
}> = ({ children, className, title, subtitle, hasBack }) => {
  const history = useHistory();

  const goBack = () => {
    history.goBack();
  };

  return (
    <div className={`${className || ''} form`}>
      <IconLogo className="form__logo" />
      <Card className="form__wrapper">
        <div className="form__wrapper__head">
          {hasBack && (
            <LeftCircleTwoTone
              twoToneColor={'#1890ff'}
              className="form__wrapper__head__icon"
              onClick={() => goBack()}
            />
          )}
          <div className="form__wrapper__head__info">
            <Typography.Title level={4} className="form__wrapper__head__title">
              {title}
            </Typography.Title>
            {subtitle ? (
              <Typography.Paragraph className="form__wrapper__head__subtitle">{subtitle}</Typography.Paragraph>
            ) : null}
          </div>
        </div>
        {children}
      </Card>
    </div>
  );
};

export default FormWrapper;
