import React, { useEffect, useState } from 'react';
// import { useSelector } from 'react-redux';

import './styles.less';

import { Table, Input, Form } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import FormItem from 'antd/lib/form/FormItem';

import { FunnelType } from '../../../../backend/services/funnel-type/types/entities/funnel-type';
import { Campaign } from '../../../../backend/services/campaign/types/entities/campaign';
import { Scenario } from '../../../../backend/services/scenario/types/entities/scenario';
import { Forecast } from '../../../../backend/services/forecast/types/entities/forecast';
import { FormInstance } from 'rc-field-form';

interface CampaignsModalTableProps {
  className?: string;
  funnelType?: FunnelType;
  campaign?: Partial<Campaign | Forecast | Scenario>;
  valueTitle?: string;
  isRatio?: boolean;
  onChange: (values: Record<number, number>) => void;
}

interface TableData {
  number: number;
  name: string;
  value: number;
}

const CampaignsModalTable = ({
  className,
  funnelType,
  campaign,
  onChange,
  isRatio,
  valueTitle = 'Value',
}: CampaignsModalTableProps): JSX.Element => {
  const [stageValues, setStageValues] = useState<Record<number, number>>({
    0: 0,
  });

  const [hasFocus, setFocus] = useState(
    campaign?.stages?.findIndex((stage) => !stage[isRatio ? 'conversionRate' : 'value']) || -1
  );

  const [form, setForm] = useState<FormInstance<unknown> | null>(null);

  useEffect(() => {
    onChange(stageValues);
  }, [stageValues, onChange]);

  useEffect(() => {
    const result = funnelType?.stages.reduce((memo: Record<number, number>, _stage, i) => {
      if (i === 0 && isRatio) {
        memo[0] = 100;
        return memo;
      }
      if (campaign?.stages) {
        memo[i] = (isRatio ? (campaign.stages[i]?.conversionRate || 0) * 100 : campaign.stages[i]?.value) || 0;
      } else {
        memo[i] = 0;
      }
      return memo;
    }, {} as Record<number, number>);

    const values = result || (isRatio ? { 0: 100 } : ({} as Record<number, number>));

    setStageValues(values);

    form?.resetFields();
    form?.setFields(
      Object.keys(values)
        .slice(isRatio ? 1 : 0)
        .map((k, i) => ({
          name: `${i}-${funnelType?.stages ? funnelType?.stages[i] : ''}`,
          value: values[Number(k)] ? values[Number(k)].toFixed(1) : undefined,
        }))
    );
  }, [campaign, funnelType, form]);

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>, i: number): void => {
    if (e.key === 'Enter') {
      e.preventDefault();
      e.stopPropagation();
      setFocus(i + 1);
    }
  };

  const focusLast = (i: number, ref?: Input | null) => {
    if (i === hasFocus) {
      setFocus(-1);
      ref?.focus();
    }
  };

  const getMax = (i: number) => {
    if (isRatio) {
      return 100;
    }
    if (i === 0) {
      return undefined;
    }
    return stageValues[i - 1];
  };

  const columns = (): ColumnsType<TableData> => [
    {
      title: '',
      dataIndex: 'number',
      key: 'number',
    },
    {
      title: 'Stage',
      dataIndex: 'name',
      key: 'name',
      render: (v, _r, i) =>
        isRatio ? (funnelType?.stages ? `${funnelType?.stages[i]} > ${funnelType.stages[i + 1]}` : '') : v,
    },
    {
      title: valueTitle,
      dataIndex: 'value',
      key: 'value',
      width: 200,
      render: (value: number, _r, i) => {
        return (
          <FormItem
            key={`${i}-${funnelType?.stages ? funnelType?.stages[i] : ''}`}
            name={`${i}-${funnelType?.stages ? funnelType?.stages[i] : ''}`}
            label=""
            initialValue={value ? value.toFixed(1) : undefined}
            className="modal__wrapper__form__space-custom__item"
            rules={[{ required: true, message: 'This stage is required' }]}
            dependencies={Object.values(stageValues)}
          >
            <Input
              name={`${i}-${funnelType?.stages ? funnelType?.stages[i] : ''}`}
              type="number"
              value={stageValues[i] ? stageValues[i].toFixed(1) : undefined}
              placeholder="0"
              suffix={isRatio ? '%' : undefined}
              max={getMax(i)}
              min={isRatio ? 0.1 : 1}
              step={isRatio ? 0.1 : 1}
              ref={(ref) => focusLast(i, ref)}
              onKeyDown={(ev) => handleKeyDown(ev, i)}
              onChange={(ev) => {
                setStageValues({
                  ...stageValues,
                  [i + (isRatio ? 1 : 0)]: Number(ev.target.value),
                });
              }}
            />
          </FormItem>
        );
      },
    },
  ];

  const datasource = (): TableData[] => {
    return (
      funnelType?.stages.slice(isRatio ? 1 : 0).map((stage, i) => {
        return {
          number: i + 1,
          name: stage,
          value: stageValues[i + (isRatio ? 1 : 0)] || 0,
        };
      }) || []
    );
  };

  return (
    <div className={`${className || ''} table__campaigns__modal`}>
      {funnelType ? (
        <Form ref={(ref) => setForm(ref)}>
          <Table
            rowClassName={() => 'editable-row'}
            pagination={false}
            showHeader={true}
            dataSource={datasource()}
            columns={columns()}
            rowKey={(record) => record.number}
          />
        </Form>
      ) : null}
    </div>
  );
};

export default CampaignsModalTable;
