import React from 'react';

import { Row, Skeleton, Space } from 'antd';

import PageBackground from '@webapp/components/layout/page-background';
import AcceptInviteForm from '@webapp/components/auth/accept-invite';
import { useParams } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { GetInvite } from '../../store/invite/action-creators';
import AppState from '../../store/state';

export default function AcceptInvitePage(): JSX.Element {
  const dispatch = useDispatch();

  const { id } = useParams<{ id: string }>();

  const invite = useSelector((state: AppState) => state.invite.active);

  React.useEffect(() => {
    dispatch(new GetInvite(id));
  }, [id]);

  return (
    <PageBackground>
      <Row justify="center" align="middle" style={{ minHeight: '100vh' }}>
        <Space direction="vertical" align="center">
          {invite ? <AcceptInviteForm invite={invite} /> : <Skeleton />}
        </Space>
      </Row>
    </PageBackground>
  );
}
