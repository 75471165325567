import React, { useState } from 'react';

import './styles.less';

import { useDispatch, useSelector } from 'react-redux';
import { push } from 'connected-react-router';

import { Space, Typography, Skeleton } from 'antd';

import { LeftOutlined } from '@ant-design/icons';

import Page from '@webapp/components/layout/page';
import BarGraph from '@webapp/components/bar-graph';
import Statistics from '@webapp/components/statistics';
import { useParams } from 'react-router';
import ConversionRate from '@webapp/components/conversion-rate';
import CampaignEditorModal from '@webapp/components/modal/campaign-editor';
import ForecastEditorModal from '@webapp/components/modal/forecast-editor';

import AppState from '../../../store/state';
import { Campaign } from '../../../../../backend/services/campaign/types/entities/campaign';
import { GetCampaign } from '../../../store/campaign/action-creators';
import { GET_CAMPAIGN } from '../../../store/campaign/actions';
import { selectFunnelTypes } from '../../../store/funnel-type/selectors';
import { selectCampaigns } from '@webapp/store/campaign/selectors';

export default function CampaignDetailsPage(): JSX.Element {
  const dispatch = useDispatch();
  const campaigns = useSelector(selectCampaigns);

  const [isForecastModalVisible, setIsForecastModalVisible] = useState<string>();

  const { id, clientId } = useParams<{ clientId: string; id: string }>();

  const campaign: Campaign | undefined = useSelector((state: AppState) => state.campaign.active);

  const isLoading = !!useSelector((state: AppState) => !!state.ui.loading[GET_CAMPAIGN]);

  const [isEditorVisible, setIsEditorVisible] = useState(false);
  const funnelTypes = useSelector(selectFunnelTypes);

  React.useEffect(() => {
    dispatch(new GetCampaign(clientId, id));
  }, [id, clientId]);

  const handleEdit = () => {
    setIsEditorVisible(true);
  };

  const handleBack = () => {
    dispatch(push('/campaigns'));
  };

  const handleForecast = () => {
    setIsForecastModalVisible(id);
  };

  return (
    <Page className="campaign-details">
      <div className="campaign-details__back" onClick={handleBack}>
        <LeftOutlined className="campaign-details__back__icon" />
        <Typography.Link className="campaign-details__back__link" strong>
          Campaigns
        </Typography.Link>
      </div>
      {campaign ? (
        <>
          <Space
            align="start"
            style={{ justifyContent: 'space-between', width: '100%' }}
            className="campaign-details__header"
          >
            <Typography.Title level={3} className="campaign-details__header__title">
              {campaign.name}
            </Typography.Title>
            <Space align="end" size="large">
              <Typography.Link className="campaign-details__header__link" strong onClick={handleEdit}>
                Edit
              </Typography.Link>
              <Typography.Link className="campaign-details__header__link accent" strong onClick={handleForecast}>
                Forecast
              </Typography.Link>
            </Space>
          </Space>
          <Space
            className="campaign-details__wrapper"
            align="start"
            style={{ justifyContent: 'space-between', width: '100%' }}
            size="large"
          >
            <BarGraph
              stages={campaign.stages}
              costStage={campaign.costStage}
              valueStage={campaign.valueStage}
              budget={campaign.budget}
              revenue={campaign.revenue}
            />
            <ConversionRate rates={campaign.stages.slice(1).map((s) => s.conversionRate || 0)} />
          </Space>
          <Statistics
            className="campaign-details__statistics"
            budget={campaign.budget}
            revenue={campaign.revenue}
            ROI={campaign.ROI}
          />
        </>
      ) : null}
      {isLoading ? <Skeleton /> : null}
      {isForecastModalVisible ? (
        <ForecastEditorModal
          funnelTypes={funnelTypes}
          campaigns={campaigns}
          selectedCampaignId={isForecastModalVisible}
          isModalVisible={!!isForecastModalVisible}
          handleClose={() => setIsForecastModalVisible(undefined)}
        />
      ) : null}
      {isEditorVisible ? (
        <CampaignEditorModal
          funnelTypes={funnelTypes}
          campaign={campaign}
          isModalVisible={isEditorVisible}
          handleClose={() => setIsEditorVisible(false)}
        />
      ) : null}
    </Page>
  );
}
