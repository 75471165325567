import React, { useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { push } from 'connected-react-router';

import { Button, Empty, Skeleton, Space, Typography } from 'antd';

import Page from '@webapp/components/layout/page';
import ActionsTable from '@webapp/components/table/actions-table';
import CustomTextModal from '@webapp/components/modal/custom-text';
import CampaignEditorModal from '@webapp/components/modal/campaign-editor';

import { groupCampaignsByFunnelType, selectCampaigns } from '@webapp/store/campaign/selectors';
import { selectFunnelTypes } from '@webapp/store/funnel-type/selectors';
import { Campaign } from '../../../../backend/services/campaign/types/entities/campaign';
import { ColumnsType, ColumnType } from 'antd/lib/table';
import { DeleteCampaign } from '../../store/campaign/action-creators';
import AppState from '../../store/state';
import { CREATE_CAMPAIGN, LIST_CAMPAIGN } from '../../store/campaign/actions';
import { formatCurrency } from '../../utils/format-currency';
import FunnelIcon from '@webapp/components/icons/funnel';
import ForecastEditorModal from '../../components/modal/forecast-editor';

export default function CampaignsPage(): JSX.Element {
  const dispatch = useDispatch();

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false);
  const [isForecastModalVisible, setIsForecastModalVisible] = useState<string>();

  const [selectedCampaign, setSelectedCampaign] = useState<Partial<Campaign>>();

  const groupedCampaigns = useSelector(groupCampaignsByFunnelType);
  const campaigns = useSelector(selectCampaigns);
  const funnelTypes = useSelector(selectFunnelTypes);
  const clientId = useSelector((state: AppState) => state.client.active?.id);
  const isLoadingList = useSelector((state: AppState) => state.ui.loading[LIST_CAMPAIGN]);
  const isLoadingNew = useSelector((state: AppState) => !!state.ui.loading[CREATE_CAMPAIGN]);

  const handleNew = () => {
    setSelectedCampaign(undefined);
    setIsModalVisible(true);
  };

  const handleEdit = (campaign: Campaign) => {
    setSelectedCampaign(campaign);
    setIsModalVisible(true);
  };

  const handleClone = (campaign: Campaign) => {
    setSelectedCampaign({
      ...campaign,
      name: `${campaign.name} Clone`,
      id: undefined,
      stages: campaign.stages.map((stage) => ({ ...stage })),
      costStage: {
        ...campaign.costStage,
      },
      valueStage: {
        ...campaign.valueStage,
      },
    });
    setIsModalVisible(true);
  };

  const handleClose = () => {
    setIsModalVisible(false);
  };

  const handleView = (campaign: Campaign) => {
    dispatch(push(`/campaigns/${clientId}/${campaign.id}`));
  };

  // const handleForecast = (campaign: Campaign) => {
  //   dispatch(push(`/forecasts/new/${campaign.id}`));
  // };

  const openDeleteModal = (campaign: Campaign) => {
    setSelectedCampaign(campaign);
    setIsDeleteModalVisible(true);
  };

  const handleDelete = () => {
    if (selectedCampaign?.id) {
      dispatch(new DeleteCampaign(selectedCampaign.id));
    }
    handleCloseDelete();
  };

  const handleCloseDelete = () => {
    setIsDeleteModalVisible(false);
  };

  const getFunnelTypeName = (funnelTypeId: string): string => {
    const funnelType = funnelTypes.find((f) => f.id === funnelTypeId);

    return funnelType?.name || '?';
  };

  const getColumns = (funnelTypeId: string): ColumnsType<Campaign> => {
    const funnelType = funnelTypes.find((f) => f.id === funnelTypeId);

    if (funnelType) {
      return [
        {
          title: 'Name',
          dataIndex: 'name',
          key: `${funnelTypeId}-name`,
        } as ColumnType<Campaign>,
      ]
        .concat(
          funnelType.stages.slice(-10).map(
            (stage, i) =>
              ({
                title: stage,
                dataIndex: `stages.${i}.value`,
                render: (_, campaign) => {
                  if (campaign?.stages[i]?.value !== undefined) {
                    return `${campaign?.stages[i]?.value.toLocaleString()}`;
                  }
                  return '-';
                },
                key: `${funnelTypeId}-${i}`,
              } as ColumnType<Campaign>)
          )
        )
        .concat([
          {
            title: 'Budget',
            dataIndex: 'budget',
            key: `${funnelTypeId}-budget`,
            render: (_, campaign) => {
              return `${formatCurrency(campaign?.budget)}`;
            },
          } as ColumnType<Campaign>,
          {
            title: 'Revenue',
            dataIndex: 'revenue',
            key: `${funnelTypeId}-revenue`,
            render: (_, campaign) => {
              return `${formatCurrency(campaign?.revenue)}`;
            },
          } as ColumnType<Campaign>,
          {
            title: 'Forecast',
            dataIndex: 'forecast',
            key: `${funnelTypeId}-forecast`,
            render: (_, campaign) => {
              return (
                <Typography.Link onClick={() => setIsForecastModalVisible(campaign.id)}>
                  Create Forecast
                </Typography.Link>
              );
            },
          } as ColumnType<Campaign>,
        ]);
    }

    return [];
  };

  return (
    <Page>
      <Space align="start" style={{ justifyContent: 'space-between', width: '100%' }}>
        <Typography.Title level={3}>Campaigns</Typography.Title>
        <Button type="primary" size="large" onClick={handleNew} loading={isLoadingNew}>
          New Campaign
        </Button>
      </Space>
      {isLoadingList ? (
        <Skeleton />
      ) : Object.keys(groupedCampaigns).length ? (
        Object.keys(groupedCampaigns).map((key) => (
          <ActionsTable<Campaign>
            key={key}
            title={getFunnelTypeName(key)}
            data={groupedCampaigns[key]}
            keyMapper={(campaign) => campaign.id}
            dataColumns={getColumns(key)}
            handleView={handleView}
            handleEdit={handleEdit}
            handleDelete={openDeleteModal}
            handleClone={handleClone}
            icon={<FunnelIcon />}
          />
        ))
      ) : (
        <Space
          className="campaign__empty"
          direction="vertical"
          align="center"
          style={{
            height: 'calc(100% - 80px - 88px)',
            width: '100%',
            marginTop: 80,
          }}
        >
          <Empty description={<Typography.Text>No Campaigns yet</Typography.Text>}>
            <Button type="primary" size="large" onClick={handleNew} loading={isLoadingNew}>
              New Campaign
            </Button>
          </Empty>
        </Space>
      )}
      {isModalVisible ? (
        <CampaignEditorModal
          funnelTypes={funnelTypes}
          campaign={selectedCampaign as Campaign}
          isModalVisible={isModalVisible}
          handleClose={handleClose}
        />
      ) : null}
      {isForecastModalVisible ? (
        <ForecastEditorModal
          funnelTypes={funnelTypes}
          campaigns={campaigns}
          selectedCampaignId={isForecastModalVisible}
          isModalVisible={!!isForecastModalVisible}
          handleClose={() => setIsForecastModalVisible(undefined)}
        />
      ) : null}
      <CustomTextModal
        title="Are you sure you want to delete this Campaign?"
        text="You will not be able to undo this action"
        button={
          <Button danger onClick={handleDelete}>
            Delete
          </Button>
        }
        isModalVisible={isDeleteModalVisible}
        handleClose={handleCloseDelete}
      />
    </Page>
  );
}
