import { CallApi, handleJSONResponse } from './common';
import { ListProductResponse } from '../../../backend/services/product/functions/list-product/interface';

const callAPI = CallApi('product');

export const list = async (): Promise<ListProductResponse> => {
  const data = {
    limit: 500,
  };
  const response = await callAPI(`/`, 'GET', data);

  return await handleJSONResponse(response);
};
