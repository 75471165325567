import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import './styles.less';

import AppState from '@webapp/store/state';

import { Button, Space, Typography, Form, Input } from 'antd';
import { CHANGE_PASSWORD } from '../../../store/auth/actions';
import { ChangePassword } from '../../../store/auth/action-creators';

interface ProfileChangePasswordProps {
  className?: string;
}

export default function ProfileChangePassword({ className }: ProfileChangePasswordProps): JSX.Element {
  const dispatch = useDispatch();

  const loading = useSelector((state: AppState) => !!state.ui.loading[CHANGE_PASSWORD]);

  const onFinish = (values: { password: string; password2: string }) => {
    if (!(values.password && values.password2)) {
      return;
    }

    dispatch(
      new ChangePassword({
        oldPassword: values.password,
        newPassword: values.password2,
      })
    );

    return;
  };

  return (
    <div className={`${className} change-password`}>
      <Typography.Title level={4} className="change-password__title">
        Change Password
      </Typography.Title>
      <Space size="large" direction="vertical">
        <Form name="basic" layout="vertical" onFinish={onFinish}>
          <Form.Item
            className="form__wrapper__item"
            name="password"
            rules={[{ required: true, message: 'Please input your password!' }]}
          >
            <Input.Password size="large" placeholder="Old Password" />
          </Form.Item>

          <Form.Item
            className="form__wrapper__item"
            name="password2"
            rules={[{ required: true, message: 'Please input your password!' }]}
          >
            <Input.Password size="large" placeholder="New Password" />
          </Form.Item>
          <Button type="primary" htmlType="submit" size="large" loading={loading}>
            Update Password
          </Button>
        </Form>
      </Space>
    </div>
  );
}
